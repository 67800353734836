import React, {useState} from 'react';
import RestaurantTransactions from '../../Transactions';
import {useLocation, useParams} from 'react-router-dom';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';

function SingleUserTransactions() {
  const [userName, setUserName] = useState('');
  const location = useLocation().pathname.split('/')[1];
  const userId = useParams().userId;
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Transactions',
      path: `${location}/${userId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: userName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <RestaurantTransactions
        showUserFilter={false}
        userId={userId}
        setUserName={setUserName}
        breadcrumb={<PageTitle breadcrumbs={usersBreadcrumbs}>{userName}</PageTitle>}
      />
    </>
  );
}

export default SingleUserTransactions;
