import React from 'react';
import { USER_STATUS } from '../../../Helpers';

const StatusSelectBox = ({ value, onChange, disabled = false, className = '' }) => {
  return (
    <select
      className={`form-select form-select-solid form-select-sm fw-bold ${className}`}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {USER_STATUS.map((ele, i) => (
        <option className='fs-4 text-gray-700' value={ele.value} key={i}>
          {ele.name}
        </option>
      ))}
    </select>
  );
};

export default StatusSelectBox;
