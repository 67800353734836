import { useFormik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API_ERROR_MESSAGE, TOAST_GENERAL } from '../../Helpers';
import { useAuth } from '../../modules/auth';
export interface IProfileDetails {
  restaurantName: string | undefined;
  email: string | undefined;
  query: string;
}

const profileDetailsSchema = Yup.object().shape({
  query: Yup.string().required('Query is required').max(3000, ''),
});
function ContactForm({children, onSubmit, isInActive = false}) {
  const [loading, setLoading] = useState(false);
  const {currentUser, contactModule, logout} = useAuth();
  const initialValues: IProfileDetails = {
    restaurantName: currentUser?.firstName,
    email: currentUser?.email,
    query: '',
  };
  const errorValue = localStorage.getItem('gotErr');

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await contactModule?.addContact({message: values?.query});
      setLoading(false);
      if (result?.success) {
        if (errorValue) {
          logout();
        }
        toast.success('Your query sent Succesfully', {
          toastId: TOAST_GENERAL,
        });
        onSubmit();
        formik.setValues(initialValues);
        formik.setFieldTouched('query', false, true);
      } else {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    },
  });
  return (
    <div className='card mb-5 mb-xl-10'>
      {children}
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                {currentUser?.role === 'RESTAURANT' ? 'Restaurant Name' : 'Name'}
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Restaurant Name'
                  {...formik.getFieldProps('restaurantName')}
                  disabled
                />
                {formik.touched.restaurantName && formik.errors.restaurantName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.restaurantName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  disabled
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Query</label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Query'
                  rows={5}
                  maxLength={3000}
                  {...formik.getFieldProps('query')}
                />
                {formik.touched.query && formik.errors.query && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.query}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Send'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
