import { useEffect, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { KTIcon } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useThemeMode } from '../../../../_metronic/partials';
import DataTableWrapper from '../../../DataTable';
import { convertUtcToLocal } from '../../../Helpers';
import { CustomModal } from '../../../components/Modal';
import { CommonRowInTable, CommonViewInHeader } from '../../../components/common/DataTableViews';
import { useAuth } from '../../../modules/auth';
import reducerFunc from './action';
import { TInitialState } from './index.d';

const initialState: TInitialState = {
  loading: true,
  data: [],
  isModalOpen: false,
  totalData: -1,
  currentPage: 1,
  pageSize: 10,
  selectedRow: null,
  totalPages: 1,
  error: null,
  search: '',
  restaurantFilter: '',
  userFilter: '',
  startDate: '',
  endDate: '',
};
const ReportDataTable = () => {
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  const {currentUser, reportModule} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const {mode} = useThemeMode();
  const {state: locationState}: {state: any} = useLocation();
  const restaurantLocationFilter = useRef({
    restautant: locationState?.restaurant,
  });
  const getTReport = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await reportModule?.getAllReports({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      restaurantIdFilter: restaurantLocationFilter.current.restautant || state.restaurantFilter,
      userIdFilter: state.userFilter,
      startDate: state.startDate,
      endDate: state.endDate,
    });
    restaurantLocationFilter.current = {
      restautant: '',
    };
    dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
    dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
  };
  useEffect(() => {
    getTReport();
  }, [
    state.restaurantFilter,
    state.currentPage,
    state.pageSize,
    state.search,
    state.userFilter,
    state.startDate,
    state.endDate,
  ]);
  let columns = [
    {
      name: <CommonViewInHeader name='User Name' />,
      selector: (row, i) => row?.firstName,
      sortable: true,
      width: '300px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.firstName + ' ' + row?.lastName}
            classes='text-dark text-capitalize cursor-pointer'
            onClick={() => {
              dispatch({type: 'OPEN_MODAL', payload: 'REPORT'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row?.created_at,
      width: '310px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row?.created_at)}
            classes='text-theme-color text-uppercase cursor-pointer'
            onClick={() => {
              dispatch({type: 'OPEN_MODAL', payload: 'REPORT'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.price,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='View issue'
              className='linkIcon cursor-pointer'
              onClick={() => {
                dispatch({type: 'OPEN_MODAL', payload: 'REPORT'});
                dispatch({type: 'SELECT_ROW', payload: row});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];
  columns =
    currentUser?.role === 'ADMIN'
      ? [
          {
            name: <CommonViewInHeader name='Restaurant' classes='ms-8' />,
            selector: (row, i) => row.name,
            width: '310px',
            sortable: true,
            format: (row, i) => {
              return (
                <CommonRowInTable
                  text={row?.name}
                  avatar={row?.images ?? 'media/avatars/restaurant-blank.png'}
                  classes='text-dark text-capitalize cursor-pointer'
                  onClick={() => {
                    dispatch({type: 'OPEN_MODAL', payload: 'REPORT'});
                    dispatch({type: 'SELECT_ROW', payload: row});
                  }}
                />
              );
            },
          },
          ...columns,
        ]
      : columns;

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Restaurant Reports</PageTitle>
      <DataTableWrapper
        loading={state.loading}
        totalData={state.totalData}
        columns={columns}
        pageSize={state.pageSize}
        data={state.data}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'CHANGE_PAGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          userFilter: true,
          restaurantFilter: isAdmin,
          date: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
          dispatch({type: 'SET_USER', payload: value.userFilter?.value});
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: value.startDate, endDate: value.endDate},
          });
        }}
      />
      {state?.isModalOpen && (
        <CustomModal
          title={'Issue'}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={() => {}}
          type='REPORT'
        />
      )}
    </div>
  );
};

export default ReportDataTable;
