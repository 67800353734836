import React from 'react';
import {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import {toast} from 'react-toastify';
import {TOAST_GENERAL} from '../../../Helpers';
import {KTIcon} from '../../../../_metronic/helpers';
const initialValues = {
  password: '',
  confirmPassword: '',
};
const resetPassSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .max(16, 'Maximum 16 symbols')
    .required('Confirm Password is required'),
});
function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser, authModule} = useAuth();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPasword: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get('id');
  const resetPasswordCode = searchParams.get('code');
  const formik = useFormik({
    initialValues,
    validationSchema: resetPassSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true);
      try {
        const result = await authModule?.resetPassword(userId, resetPasswordCode, values.password);
        if (result?.success) {
          toast.success(`Password Reset Succesfully, Please login to continue`, {
            toastId: TOAST_GENERAL,
          });
          navigate('/auth/login');
        } else {
          toast(result?.message);
          setLoading(false);
        }
      } catch (error: any) {
        toast.error('Some Problem occured, Please try again');
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
        <input
          type={showPassword?.password ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          placeholder='Enter New Password'
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />{' '}
        {showPassword?.password ? (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type={showPassword?.confirmPasword ? 'text' : 'password'}
          autoComplete='off'
          placeholder='Confirm Password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />{' '}
        {showPassword?.confirmPasword ? (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPasword: !showPassword.confirmPasword})
            }
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPasword: !showPassword.confirmPasword})
            }
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  );
}

export default ResetPassword;
