import React from 'react';
import {KTIcon} from '../../../_metronic/helpers';
import {useNavigate} from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div className='container py-4 mt-10'>
      {/* Back Button */}
      {/* <button type='submit' className='btn btn-primary' onClick={() => navigate('/')}>
        <KTIcon iconName='arrow-left' className='fs-4 text-white ms-1' />
        Back
      </button> */}
      <h1 className='fw-bold fs-1 mb-6 text-center'>Terms of Use</h1>
      <p className='mb-6 fs-4'>
        <strong>Effective date: September 23, 2024</strong>
      </p>
      <p className='mb-6 fs-4'>
        Welcome to Diibs. Please read on to learn the rules and restrictions that govern your use of
        our website(s), products, services and applications (the “Services”).
      </p>{' '}
      <p className='mb-6 fs-4'>
        Diibs facilitates a reservation auction process by partnering with restaurants
        (“Restaurants”). These Restaurants are permitted to list their reservations for auction.
        Individuals who wish to secure reservations at such Restaurants (“Users”) are able to
        participate in the bidding process. Additional terms applicable to Restaurants are{' '}
        <a href='/restaurant-terms'>Restaurant Terms</a> and additional terms regarding reservations applicable to
        Users are <a href='/reservation-terms'>Reservation Terms</a>.
      </p>{' '}
      <p className='mb-6 fs-4'>
        If you have any questions, comments, or concerns regarding these terms or the Services,
        please contact us at:
      </p>{' '}
      <p className='mb-6 fs-4'>
        Email: <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>
      </p>
      <p className='mb-6 fs-4'>Address: 30 Park Ave, New York City, New York, 10016</p>
      <p className='mb-6 fs-4'>
        These Terms of Use (the “Terms”) are a binding contract between you and <b>DIIBS, INC.</b>
        (“Diibs,” “we” and “us”). Your use of the Services in any way means that you agree to all of
        these Terms, and these Terms will remain in effect while you use the Services. These Terms
        include the provisions in this document as well as those in the{' '}
        <a href='/privacy-policy'>Privacy Policy</a>,<a href='/restaurant-terms'>Restaurant Terms</a>,
        <a href="/reservation-terms">Reservation Terms</a>, Copyright Dispute Policy in the “What if I see something
        on the Services that infringes my copyright?” section of these Terms, and any other relevant
        policies.
        <b>
          Your use of or participation in certain Services may also be subject to additional
          policies, rules, and/or conditions (“Additional Terms”), which are incorporated herein by
          reference, and you understand and agree that by using or participating in any such
          Services, you agree to also comply with these Additional Terms.
        </b>
      </p>
      <p className='mb-6 fs-4'>
        <b>Please read these Terms carefully.</b> They cover important information about Services
        provided to you and any charges, taxes, and fees we bill you.
        <b>
          These Terms include information about{' '}
          <a href='#' className='link-style'>
            future changes to these Terms, limitations of liability, a class action waiver, and
            resolution of disputes by arbitration instead of in court.
          </a>
          PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING
          TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES
          IN ANY MANNER.
        </b>
      </p>
      <p className='mb-6 fs-4'>
        <b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR CERTAIN TYPES OF DISPUTES
        DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU
        AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU WAIVE YOUR RIGHT TO
        PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>Will these Terms ever change?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        We are constantly trying to improve our Services, so these Terms may need to change along
        with our Services. We reserve the right to change the Terms at any time, but if we do, we
        will place a notice on our site located at{' '}
        <a href='https://www.gotdiibs.com'>www.gotdiibs.com</a>, send you an email, and/or notify
        you by some other means.
      </p>
      <p className='mb-6 fs-4'>
        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that
        means you will no longer be able to use the Services. If you use the Services in any way
        after a change to the Terms is effective, that means you agree to all of the changes.
      </p>
      <p className='mb-6 fs-4'>
        Except for changes by us as described here, no other amendment or modification of these
        Terms will be effective unless in writing and signed by both you and us.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>What about my privacy?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        Diibs takes your privacy very seriously. For our current Privacy Policy, please click{' '}
        <a href='/privacy-policy'>here</a>.
      </p>
      <p className='mb-6 fs-4' id='categories-of-personal-data'>
        <u>Children’s Online Privacy Protection Act</u>
      </p>
      <p className='mb-6 fs-4'>
        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service
        providers obtain parental consent before they knowingly collect personally identifiable
        information online from children who are under 13 years of age. We do not knowingly collect
        or solicit personally identifiable information from children under 13 years of age; if you
        are a child under 13 years of age, please do not attempt to register for or otherwise use
        the Services or send us any personal information. If we learn we have collected personal
        information from a child under 13 years of age, we will delete that information as quickly
        as possible. If you believe that a child under 13 years of age may have provided us personal
        information, please contact us at <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>What are the basics of using Diibs?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        You may be required to sign up for an account, select a password and user name (“Diibs User
        ID”), and provide us with certain information or data, such as your contact information. You
        promise to provide us with accurate, complete, and updated registration information about
        yourself. You may not select as your Diibs User ID a name that you do not have the right to
        use, or another person’s name with the intent to impersonate that person. You may not
        transfer your account to anyone else without our prior written permission.
      </p>
      <p className='mb-6 fs-4'>
        Additionally, you may be able to access certain parts or features of the Services by using
        your account credentials from other services (each, a “Third Party Account”), such as those
        offered by Google and Apple. By using the Services through a Third Party Account, you permit
        us to access certain information from such account for use by the Services. You are
        ultimately in control of how much information is accessible to us and may exercise such
        control by adjusting your privacy settings on your Third Party Account.
      </p>
      <p className='mb-6 fs-4'>
        You represent and warrant that you are an individual of legal age to form a binding contract
        (or if not, you’ve received your parent’s or guardian’s permission to use the Services and
        have gotten your parent or guardian to agree to these Terms on your behalf). If you’re
        agreeing to these Terms on behalf of an organization or entity, you represent and warrant
        that you are authorized to agree to these Terms on that organization’s or entity’s behalf
        and bind them to these Terms (in which case, the references to “you” and “your” in these
        Terms, except for in this sentence, refer to that organization or entity).
      </p>
      <p className='mb-6 fs-4'>
        You will only use the Services for your own internal, personal use, and not on behalf of or
        for the benefit of any third party. You will comply with all laws that apply to you, your
        use of the Services, and your actions and omissions that relate to the Services. If your use
        of the Services is prohibited by applicable laws, then you aren’t authorized to use the
        Services. We can’t and won’t be responsible for your using the Services in a way that breaks
        the law.
      </p>
      <p className='mb-6 fs-4'>
        You will not share your Diibs User ID, account or password with anyone, and you must protect
        the security of your Diibs User ID, account, password and any other access tools or
        credentials. You’re responsible for any activity associated with your Diibs User ID and
        account.
      </p>
      <div className='terms-of-service'>
        <p className='mb-6 fw-bold fs-2'>
          <strong>
            <u>What about messaging?</u>
          </strong>
        </p>
        <p className='mb-6 fs-4'>
          As part of the Services, you may receive communications through the Services, including
          messages that Diibs sends you (for example, via email or SMS). When signing up for the
          Services, you will receive a welcome message and instructions on how to stop receiving
          messages.
          <b>
            {' '}
            By signing up for the Services and providing us with your wireless number, you confirm
            that you want Diibs to send you information regarding your account or transactions with
            us, which may include Diibs using automated dialing technology to text you at the
            wireless number you provided, and you agree to receive communications from Diibs, and
            you represent and warrant that each person you register for the Services or for whom you
            provide a wireless phone number has consented to receive communications from Diibs.{' '}
          </b>
          You agree to indemnify and hold Diibs harmless from and against any and all claims,
          liabilities, damages (actual and consequential), losses and expenses (including attorneys’
          fees) arising from or in any way related to your breach of the foregoing.
        </p>

        <p className='mb-6 fw-bold fs-2'>
          <strong>
            <u>Are there restrictions in how I can use the Services?</u>
          </strong>
        </p>
        <p className='mb-6 fs-4'>
          You represent, warrant, and agree that you will not provide or contribute anything,
          including any Content or User Submission (as those terms are defined below), to the
          Services, or otherwise use or interact with the Services, in a manner that:
        </p>
        <ul
          className='mb-6 fs-4'
          style={{
            listStyleType: 'none',
            paddingLeft: '20px',
          }}
        >
          <li>
            (a) infringes or violates the intellectual property rights or any other rights of anyone
            else (including Diibs);
          </li>
          <li>
            (b) violates any law or regulation, including, without limitation, any applicable export
            control laws, privacy laws or any other purpose not reasonably intended by Diibs;
          </li>
          <li>
            (c) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory,
            obscene, or otherwise objectionable;
          </li>
          <li>
            (d) jeopardizes the security of your Diibs User ID, account or anyone else’s (such as
            allowing someone else to log in to the Services as you);
          </li>
          <li>
            (e) attempts, in any manner, to obtain the password, account, or other security
            information from any other user;
          </li>
          <li>
            (f) violates the security of any computer network, or cracks any passwords or security
            encryption codes;
          </li>
          <li>
            (g) runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or
            any processes that run or are activated while you are not logged into the Services, or
            that otherwise interfere with the proper working of the Services (including by placing
            an unreasonable load on the Services’ infrastructure);
          </li>
          <li>
            (h) “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the
            Services or Content (through use of manual or automated means);
          </li>
          <li>(i) copies or stores any significant portion of the Content; or</li>
          <li>
            (j) decompiles, reverse engineers, or otherwise attempts to obtain the source code or
            underlying ideas or information of or relating to the Services.
          </li>
        </ul>
        <p className='mb-6 fs-4'>
          A violation of any of the foregoing is grounds for termination of your right to use or
          access the Services.
        </p>

        <p className='mb-6 fw-bold fs-2'>
          <strong>
            <u>What are my rights in the Services?</u>
          </strong>
        </p>
        <p className='mb-6 fs-4'>
          The materials displayed or performed or available on or through the Services, including,
          but not limited to, text, graphics, data, articles, photos, images, illustrations, User
          Submissions (as defined below) and so forth (all of the foregoing, the “Content”) are
          protected by copyright and/or other intellectual property laws. You promise to abide by
          all copyright notices, trademark rules, information, and restrictions contained in any
          Content you access through the Services, and you won’t use, copy, reproduce, modify,
          translate, publish, broadcast, transmit, distribute, perform, upload, display, license,
          sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i)
          without the prior consent of the owner of that Content or (ii) in a way that violates
          someone else’s (including Diibs') rights.
        </p>
        <p className='mb-6 fs-4'>
          Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive,
          non-sublicensable and non-transferable license to use (i.e., to download and display
          locally) Content solely for purposes of using the Services. Use, reproduction,
          modification, distribution or storage of any Content for any purpose other than using the
          Services is expressly prohibited without prior written permission from us. You understand
          that Diibs owns the Services. You won’t modify, publish, transmit, participate in the
          transfer or sale of, reproduce (except as expressly provided in this Section), create
          derivative works based on, or otherwise exploit any of the Services. The Services may
          allow you to copy or download certain Content, but please remember that even where these
          functionalities exist, all the restrictions in this section still apply.
        </p>
      </div>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>
            What about anything I contribute to the Services – do I have to grant any licenses to
            Diibs or to other users?
          </u>
        </strong>
      </p>
      <p className='mb-6 fs-4' id='categories-of-personal-data'>
        <u>User Submissions</u>
      </p>
      <p className='mb-6 fs-4'>
        Anything you post, upload, share, store, or otherwise provide through the Services is your
        “User Submission”. Some User Submissions may be viewable by other users. You are solely
        responsible for all User Submissions you contribute to the Services. You represent that all
        User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with
        all applicable laws, rules and regulations.
      </p>
      <p className='mb-6 fs-4'>
        You agree that you will not post, upload, share, store, or otherwise provide through the
        Services any User Submissions that: (i) infringe any third party's copyrights or other
        rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or
        pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred
        against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme
        violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote
        fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online
        gaming and gambling, cash gifting, work from home businesses, or any other dubious
        money-making ventures; or (viii) that violate any law.
      </p>
      <p className='mb-6 fs-4' id='categories-of-personal-data'>
        <u>Licenses</u>
      </p>
      <p className='mb-6 fs-4'>
        In order to display your User Submissions on the Services, and to allow other users to enjoy
        them (where applicable), you grant us certain rights in those User Submissions (see below
        for more information). Please note that all of the following licenses are subject to our{' '}
        <a href='/privacy-policy'>Privacy Policy</a> to the extent they relate to User Submissions
        that are also your personally-identifiable information.
      </p>
      <p className='mb-6 fs-4'>
        By submitting User Submissions through the Services, you hereby do and shall grant Diibs a
        worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and
        transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute,
        prepare derivative works of, display, perform, and otherwise fully exploit the User
        Submissions in connection with this site, the Services and our (and our successors’ and
        assigns’) businesses, including without limitation for promoting and redistributing part or
        all of this site or the Services (and derivative works thereof) in any media formats and
        through any media channels (including, without limitation, third party websites and feeds),
        and including after your termination of your account or the Services. You also hereby do and
        shall grant each user of this site and/or the Services a non-exclusive, perpetual license to
        access your User Submissions through this site and/or the Services, and to use, edit,
        modify, reproduce, distribute, prepare derivative works of, display and perform such User
        Submissions, including after your termination of your account or the Services. For clarity,
        the foregoing license grants to us and our users do not affect your other ownership or
        license rights in your User Submissions, including the right to grant additional licenses to
        your User Submissions, unless otherwise agreed in writing. You represent and warrant that
        you have all rights to grant such licenses to us without infringement or violation of any
        third party rights, including without limitation, any privacy rights, publicity rights,
        copyrights, trademarks, contract rights, or any other intellectual property or proprietary
        rights.
      </p>
      <p className='mb-6 fs-4'>
        Certain features of the Services allow you to share information with others, including
        through your social networks or other Third Party Accounts. When Content is authorized for
        sharing, we will clearly identify the Content you are authorized to redistribute and the
        ways you may redistribute it, usually by providing a “share” button on or near the Content.
        If you share information from the Services with others through your Third Party Accounts,
        such as your social networks, you authorize Diibs to share that information with the
        applicable Third Party Account provider. Please review the policies of any Third Party
        Account providers you share information with or through for additional information about how
        they may use your information. If you redistribute Content, you must be able to edit or
        delete any Content you redistribute, and you must edit or delete it promptly upon our
        request.
      </p>
      <p className='mb-6 fs-4'>
        Certain features of the Services may allow you to share information with others, including
        via social networks or Third Party Accounts. When Content is authorized for sharing, we
        clearly indicate the Content you may redistribute and the methods available, typically
        through a “share” button near the Content. If you share information from the Services with
        others via Third Party Accounts, such as social media, you authorize Diibs to share that
        information with the applicable provider. Please check each provider's policies for
        additional information on their use of your information. If you redistribute Content, you
        must be able to edit or delete any redistributed Content and do so promptly upon our
        request.
      </p>
      <p className='mb-6 fs-4'>
        Finally, you understand and agree that Diibs, in performing the required technical steps to
        provide the Services to our users (including you), may need to make changes to your User
        Submissions to conform and adapt those User Submissions to the technical requirements of
        connection networks, devices, services, or media, and the foregoing licenses include the
        rights to do so.
      </p>
      <p className='mb-6 fw-bold fs-4' id='categories-of-personal-data'>
        <u>What if I see something on the Services that infringes my copyright?</u>
      </p>
      <p className='mb-6 fs-4'>
        In accordance with the DMCA, we’ve adopted the following policy toward copyright
        infringement. We reserve the right to (1) block access to or remove material that we believe
        in good faith to be copyrighted material that has been illegally copied and distributed by
        any of our advertisers, affiliates, content providers, members or users and (2) remove and
        discontinue service to repeat offenders.
      </p>
      <p className='mb-6 fs-4'>
        (1) <u>Procedure for Reporting Copyright Infringements:</u>If you believe that material or
        content residing on or accessible through the Services infringes your copyright (or the
        copyright of someone whom you are authorized to act on behalf of), please send a notice of
        copyright infringement containing the following information to Diibs' Designated Agent to
        Receive Notification of Claimed Infringement (our “Designated Agent,” whose contact details
        are listed below):
      </p>
      <ul className='mb-6 fs-4' style={{listStyleType: 'lower-alpha'}}>
        <li>
          A physical or electronic signature of a person authorized to act on behalf of the owner of
          the copyright that has been allegedly infringed;
        </li>
        <li>Identification of works or materials being infringed;</li>
        <li>
          Identification of the material that is claimed to be infringing including information
          regarding the location of the infringing materials that the copyright owner seeks to have
          removed, with sufficient detail so that Company is capable of finding and verifying its
          existence;
        </li>
        <li>
          Contact information about the notifier including address, telephone number and, if
          available, email address;
        </li>
        <li>
          A statement that the notifier has a good faith belief that the material identified in
          (1)(c) is not authorized by the copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement made under penalty of perjury that the information provided is accurate and
          the notifying party is authorized to make the complaint on behalf of the copyright owner.
        </li>
      </ul>
      <p className='mb-6 fs-4'>
        (2){' '}
        <u>Once Proper Bona Fide Infringement Notification is Received by the Designated Agent</u>{' '}
        Upon receipt of a proper notice of copyright infringement, we reserve the right to:
      </p>
      <ul className='mb-6 fs-4' style={{listStyleType: 'lower-alpha'}}>
        <li>remove or disable access to the infringing material;</li>
        <li>
          notify the content provider who is accused of infringement that we have removed or
          disabled access to the applicable material; and
        </li>
        <li>
          terminate such content provider's access to the Services if he or she is a repeat
          offender.
        </li>
      </ul>
      <p className='mb-6 fs-4'>
        (3) <u>Procedure to Supply a Counter-Notice to the Designated Agent:</u>If the content
        provider believes that the material that was removed (or to which access was disabled) is
        not infringing, or the content provider believes that it has the right to post and use such
        material from the copyright owner, the copyright owner's agent, or, pursuant to the law, the
        content provider may send us a counter-notice containing the following information to the
        Designated Agent:
      </p>
      <ul className='mb-6 fs-4' style={{listStyleType: 'lower-alpha'}}>
        <li>A physical or electronic signature of the content provider;</li>
        <li>
          Identification of the material that has been removed or to which access has been disabled
          and the location at which the material appeared before it was removed or disabled;
        </li>
        <li>
          A statement that the content provider has a good faith belief that the material was
          removed or disabled as a result of mistake or misidentification of the material; and
        </li>
        <li>
          Content provider's name, address, telephone number, and, if available, email address, and
          a statement that such person or entity consents to the jurisdiction of the Federal Court
          for the judicial district in which the content provider’s address is located, or, if the
          content provider's address is located outside the United States, for any judicial district
          in which Company is located, and that such person or entity will accept service of process
          from the person who provided notification of the alleged infringement.
        </li>
      </ul>
      <p className='mb-6 fs-4'>
        If a counter-notice is received by the Designated Agent, Company may, in its discretion,
        send a copy of the counter-notice to the original complaining party informing that person
        that Company may replace the removed material or cease disabling it in 10 business days.
        Unless the copyright owner files an action seeking a court order against the content
        provider accused of committing infringement, the removed material may be replaced or access
        to it restored in 10 to 14 business days or more after receipt of the counter-notice, at
        Company's discretion.
      </p>
      <p className='mb-6 fs-4'>Please contact Diibs' Designated Agent at the following address:</p>
      <p className='mb-6 fs-4'>
        Diibs, Inc.
        <br />
        Attn: DMCA Designated Agent
        <br />
        30 Park Ave
        <br />
        New York City, NY 10016
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>Who is responsible for what I see and do on the Services?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        Any information or Content publicly posted or privately transmitted through the Services is
        the sole responsibility of the person from whom such Content originated, and you access all
        such information and Content at your own risk, and we aren’t liable for any errors or
        omissions in that information or Content or for any damages or loss you might suffer in
        connection with it. We cannot control and have no duty to take any action regarding how you
        may interpret and use the Content or what actions you may take as a result of having been
        exposed to the Content, and you hereby release us from all liability for you having acquired
        or not acquired Content through the Services. We can’t guarantee the identity of any users
        with whom you interact in using the Services and are not responsible for which users gain
        access to the Services.
      </p>
      <p className='mb-6 fs-4'>
        You are responsible for all Content you contribute, in any manner, to the Services, and you
        represent and warrant you have all rights necessary to do so, in the manner in which you
        contribute it.
      </p>
      <p className='mb-6 fs-4'>
        The Services may contain links or connections to third-party websites or services that are
        not owned or controlled by Diibs. When you access third-party websites or use third-party
        services, you accept that there are risks in doing so, and that Diibs is not responsible for
        such risks.
      </p>
      <p className='mb-6 fs-4'>
        Diibs has no control over, and assumes no responsibility for, the content, accuracy, privacy
        policies, or practices of or opinions expressed in any third-party websites or by any third
        party that you interact with through the Services. In addition, Diibs will not and cannot
        monitor, verify, censor or edit the content of any third-party site or service. We encourage
        you to be aware when you leave the Services and to read the terms and conditions and privacy
        policy of each third-party website or service that you visit or utilize. By using the
        Services, you release and hold us harmless from any and all liability arising from your use
        of any third-party website or service.
      </p>
      <p className='mb-6 fs-4'>
        If there is a dispute between participants on this site or Services, or between users and
        any third party, you agree that Diibs is under no obligation to become involved. In the
        event that you have a dispute with one or more other users, you release Diibs, its
        directors, officers, employees, agents, and successors from claims, demands, and damages of
        every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed,
        arising out of or in any way related to such disputes and/or our Services. You shall and
        hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction,
        which says in substance: “A general release does not extend to claims that the creditor or
        releasing party does not know or suspect to exist in his or her favor at the time of
        executing the release and that, if known by him or her, would have materially affected his
        or her settlement with the debtor or released party.”
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>Will Diibs ever change the Services?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        We’re always trying to improve our Services, so they may change over time. We may suspend or
        discontinue any part of the Services, or we may introduce new features or impose limits on
        certain features or restrict access to parts or all of the Services. We’ll try to give you
        notice when we make a material change to the Services that would adversely affect you, but
        this isn’t always practical. We reserve the right to remove any Content from the Services at
        any time, for any reason (including, but not limited to, if someone alleges you contributed
        that Content in violation of these Terms), in our sole discretion, and without notice.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>Do the Services cost anything?</u>
        </strong>
      </p>
      <ul className='mb-6 fs-4' style={{listStyleType: 'lower-alpha'}}>
        <li>
          <strong>
            <u>Paid Services.</u>
          </strong>{' '}
          Certain of our Services may be subject to payments now or in the future (the “Paid
          Services”). Please see our Paid Services page for a description of the current Paid
          Services. Please note that any payment terms presented to you in the process of using or
          signing up for a Paid Service are deemed part of these Terms. For example, some Paid
          Services will charge a fee for downloading or installing the Services through the App
          Store (as defined below) linked to your specific device. You agree to comply with, and
          your license to use our mobile application is conditioned upon your compliance with, such
          App Store terms and conditions. Any refunds relating to the applications or inquiries
          regarding refunds relating to the applications shall be handled solely by the applicable
          App Store in accordance with such App Store’s terms and conditions.
        </li>
        <li>
          <strong>
            <u>Billing.</u>
          </strong>{' '}
          We use a third-party payment processor (the “Payment Processor”) to bill you through a
          payment account linked to your account on the Services (your “Billing Account”) for use of
          the Paid Services. The processing of payments will be subject to the terms, conditions,
          and privacy policies of the Payment Processor in addition to these Terms. Currently, we
          use Stripe, Inc. as our Payment Processor. You can access Stripe’s Terms of Service at{' '}
          <a href='https://stripe.com/us/checkout/legal'>https://stripe.com/us/checkout/legal</a>{' '}
          and their Privacy Policy at{' '}
          <a href='https://stripe.com/us/privacy'>https://stripe.com/us/privacy</a>. We are not
          responsible for any error by, or other acts or omissions of, the Payment Processor. By
          choosing to use Paid Services, you agree to pay us, through the Payment Processor, all
          charges at the prices then in effect for any use of such Paid Services in accordance with
          the applicable payment terms, and you authorize us, through the Payment Processor, to
          charge your chosen payment provider (your “Payment Method”). You agree to make payment
          using that selected Payment Method. We reserve the right to correct any errors or mistakes
          that the Payment Processor makes even if it has already requested or received payment.
        </li>
        <li>
          <strong>
            <u>Payment Method.</u>
          </strong>{' '}
          The terms of your payment will be based on your Payment Method and may be determined by
          agreements between you and the financial institution, credit card issuer or other provider
          of your chosen Payment Method. If we, through the Payment Processor, do not receive
          payment from you, you agree to pay all amounts due on your Billing Account upon demand.
        </li>
        <li>
          <strong>
            <u>Current Information Required.</u>
          </strong>{' '}
          YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU
          MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND
          ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD
          EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT
          METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH
          OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD.
          CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS[LINK]. IF YOU FAIL TO PROVIDE
          ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE
          OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES
          AS SET FORTH ABOVE.
        </li>
        <li>
          <strong>
            <u>Change in Amount Authorized.</u>
          </strong>{' '}
          If the amount to be charged to your Billing Account varies from the amount you
          preauthorized (other than due to the imposition or change in the amount of state sales
          taxes), you have the right to receive, and we shall provide, notice of the amount to be
          charged and the date of the charge before the scheduled date of the transaction. Any
          agreement you have with your payment provider will govern your use of your Payment Method.
          You agree that we may accumulate charges incurred and submit them as one or more aggregate
          charges during or at the end of each billing cycle.
        </li>
        <li>
          <strong>
            <u>Free Trials and Other Promotions.</u>
          </strong>{' '}
          Any free trial or other promotion that provides access to a Paid Service must be used
          within the specified time of the trial. You must stop using a Paid Service before the end
          of the trial period in order to avoid being charged for that Paid Service. If you cancel
          prior to the end of the trial period and are inadvertently charged for a Paid Service,
          please contact us at <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>.
        </li>
      </ul>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>What if I want to stop using the Services?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        You’re free to do that at any time by contacting us at{' '}
        <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>; please refer to our{' '}
        <a href='/privacy-policy'>Privacy Policy</a>, as well as the licenses above, to understand
        how we treat information you provide to us after you have stopped using our Services.
      </p>
      <p className='mb-6 fs-4'>
        Diibs is also free to terminate (or suspend access to) your use of the Services or your
        account for any reason in our discretion, including your breach of these Terms. Diibs has
        the sole right to decide whether you are in violation of any of the restrictions set forth
        in these Terms.
      </p>
      <p className='mb-6 fs-4'>
        Account termination may result in destruction of any Content associated with your account,
        so keep that in mind before you decide to terminate your account
      </p>
      <p className='mb-6 fs-4'>
        If you have deleted your account by mistake, contact us immediately at{' '}
        <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a> – we will try to help, but
        unfortunately, we can’t promise that we can recover or restore anything.
      </p>
      <p className='mb-6 fs-4'>
        Provisions that, by their nature, should survive termination of these Terms shall survive
        termination. By way of example, all of the following will survive termination: any
        obligation you have to pay us or indemnify us, any limitations on our liability, any terms
        regarding ownership or intellectual property rights, and terms regarding disputes between
        us, including without limitation the arbitration agreement.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>What about Mobile Applications and In-App Purchases?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        You acknowledge and agree that the availability of our mobile application is dependent on
        the third party stores from which you download the application, e.g., the App Store from
        Apple or the Android app market from Google (each an “App Store”). Each App Store may have
        its own terms and conditions to which you must agree before downloading mobile applications
        from such store, including the specific terms relating to Apple App Store set forth below.
        You agree to comply with, and your license to use our application is conditioned upon your
        compliance with, such App Store terms and conditions. To the extent such other terms and
        conditions from such App Store are less restrictive than, or otherwise conflict with, the
        terms and conditions of these Terms of Use, the more restrictive or conflicting terms and
        conditions in these Terms of Use apply.
      </p>
      <p className='mb-6 fs-4'>
        Through our mobile applications, you may purchase (“In-App Purchase”) certain goods or
        features designed to enhance the performance of the Services. When you make an In-App
        Purchase, you are doing so through either the Apple iTunes service or the Google Play
        service and you are agreeing to their respective Terms and Conditions, available at
        <a
          href='http://www.apple.com/legal/internet-services/itunes/us/terms.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          Apple iTunes service
        </a>
        or the{' '}
        <a
          href='http://play.google.com/intl/en_us/about/play-terms.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          Google Play service.
        </a>
        Diibs is not a party to any In-App Purchase.
      </p>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>
            I use the Diibs App available via the Apple App Store – should I know anything about
            that?
          </u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        These Terms apply to your use of all the Services, including our iOS applications (the
        “Application”) available via the Apple, Inc. (“Apple”) App Store, but the following
        additional terms also apply to the Application:
      </p>
      <ol className='mb-6 fs-4' style={{listStyleType: 'lower-alpha'}}>
        <li>
          Both you and Diibs acknowledge that the Terms are concluded between you and Diibs only,
          and not with Apple, and that Apple is not responsible for the Application or the Content;
        </li>
        <li>
          The Application is licensed to you on a limited, non-exclusive, non-transferrable,
          non-sublicensable basis, solely to be used in connection with the Services for your
          private, personal, non-commercial use, subject to all the terms and conditions of these
          Terms as they are applicable to the Services;
        </li>
        <li>
          You will only use the Application in connection with an Apple device that you own or
          control;
        </li>
        <li>
          You acknowledge and agree that Apple has no obligation whatsoever to furnish any
          maintenance and support services with respect to the Application;
        </li>
        <li>
          In the event of any failure of the Application to conform to any applicable warranty,
          including those implied by law, you may notify Apple of such failure; upon notification,
          Apple’s sole warranty obligation to you will be to refund to you the purchase price, if
          any, of the Application;
        </li>
        <li>
          You acknowledge and agree that Diibs, and not Apple, is responsible for addressing any
          claims you or any third party may have in relation to the Application;
        </li>
        <li>
          You acknowledge and agree that, in the event of any third-party claim that the Application
          or your possession and use of the Application infringes that third party’s intellectual
          property rights, Diibs, and not Apple, will be responsible for the investigation, defense,
          settlement, and discharge of any such infringement claim;
        </li>
        <li>
          You represent and warrant that you are not located in a country subject to a U.S.
          Government embargo, or that has been designated by the U.S. Government as a “terrorist
          supporting” country, and that you are not listed on any U.S. Government list of prohibited
          or restricted parties;
        </li>
        <li>
          Both you and Diibs acknowledge and agree that, in your use of the Application, you will
          comply with any applicable third-party terms of agreement which may affect or be affected
          by such use;
        </li>
        <li>
          Both you and Diibs acknowledge and agree that Apple and Apple’s subsidiaries are
          third-party beneficiaries of these Terms, and that upon your acceptance of these Terms,
          Apple will have the right (and will be deemed to have accepted the right) to enforce these
          Terms against you as the third-party beneficiary hereof;
        </li>
        <li>
          In the event you use the Application to provide you with real-time route guidance,{' '}
          <b>
            YOUR USE OF THIS REAL-TIME ROUTE GUIDANCE APPLICATION IS AT YOUR SOLE RISK. LOCATION
            DATA MAY NOT BE ACCURATE.
          </b>
        </li>
      </ol>
      <p className='mb-6 fw-bold fs-2'>
        <strong>
          <u>What else do I need to know?</u>
        </strong>
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Warranty Disclaimer:</u>
        </b>{' '}
        Diibs and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities,
        and each of their respective officers, directors, members, employees, consultants, contract
        employees, representatives and agents, and each of their respective successors and assigns
        (Diibs and all such parties together, the “Diibs Parties”) make no representations or
        warranties concerning the Services, including without limitation regarding any Content
        contained in or accessed through the Services, and the Diibs Parties will not be responsible
        or liable for the accuracy, copyright compliance, legality, or decency of material contained
        in or accessed through the Services or any claims, actions, suits procedures, costs,
        expenses, damages or liabilities arising out of use of, or in any way related to your
        participation in, the Services. The Diibs Parties make no representations or warranties
        regarding suggestions or recommendations of services or products offered or purchased
        through or in connection with the Services. THE SERVICES AND CONTENT ARE PROVIDED BY DIIBS
        (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND,
        EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE
        SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW
        LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Limitation of Liability:</u>
        </b>{' '}
        TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
        THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL
        ANY OF THE DIIBS PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT,
        SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR
        LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY
        OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR
        TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED
        ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO DIIBS IN CONNECTION WITH
        THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY
        MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION
        OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND
        EXCLUSIONS MAY NOT APPLY TO YOU.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Indemnity:</u>
        </b>{' '}
        You agree to indemnify and hold the Diibs Parties harmless from and against any and all
        claims, liabilities, damages (actual and consequential), losses and expenses (including
        attorneys’ fees) arising from or in any way related to any claims relating to (a) your use
        of the Services (including any actions taken by a third party using your account), and (b)
        your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we
        will attempt to provide notice of the Claim to the contact information we have for your
        account (provided that failure to deliver such notice shall not eliminate or reduce your
        indemnification obligations hereunder).
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Assignment:</u>
        </b>{' '}
        You may not assign, delegate or transfer these Terms or your rights or obligations
        hereunder, or your Services account, in any way (by operation of law or otherwise) without
        Diibs' prior written consent. We may transfer, assign, or delegate these Terms and our
        rights and obligations without consent.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Choice of Law:</u>
        </b>{' '}
        These Terms are governed by and will be construed under the Federal Arbitration Act,
        applicable federal law, and the laws of the State of New York, without regard to the
        conflicts of laws provisions thereof.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Arbitration Agreement:</u>
        </b>{' '}
        Please read the following ARBITRATION AGREEMENT carefully because it requires you to
        arbitrate certain disputes and claims with Diibs and limits the manner in which you can seek
        relief from Diibs. Both you and Diibs acknowledge and agree that for the purposes of any
        dispute arising out of or relating to the subject matter of these Terms, Diibs' officers,
        directors, employees and independent contractors (“Personnel”) are third-party beneficiaries
        of these Terms, and that upon your acceptance of these Terms, Personnel will have the right
        (and will be deemed to have accepted the right) to enforce these Terms against you as the
        third-party beneficiary hereof.
      </p>
      <p className='mb-6 fs-4'>
        <b>(a) Arbitration Rules; Applicability of Arbitration Agreement:</b> The parties shall use
        their best efforts to settle any dispute, claim, question, or disagreement arising out of or
        relating to the subject matter of these Terms directly through good-faith negotiations,
        which shall be a precondition to either party initiating arbitration. If such negotiations
        do not resolve the dispute, it shall be finally settled by binding arbitration in New York,
        New York. The arbitration will proceed in the English language, in accordance with the JAMS
        Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial
        arbitrator with substantial experience in resolving intellectual property and commercial
        contract disputes. The arbitrator shall be selected from the appropriate list of JAMS
        arbitrators in accordance with such Rules. Judgment upon the award rendered by such
        arbitrator may be entered in any court of competent jurisdiction.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>(b) Costs of Arbitration:</u>
        </b>{' '}
        The Rules will govern payment of all arbitration fees. Diibs will pay all arbitration fees
        for claims less than seventy-five thousand ($75,000) dollars. Diibs will not seek its
        attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is
        frivolous.
      </p>
      <p className='mb-6 fs-4'>
        <b>(c) Small Claims Court; Infringement:</b> Either you or Diibs may assert claims, if they
        qualify, in small claims court in New York, New York or any United States county where you
        live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes,
        each party shall have the right to pursue injunctive or other equitable relief at any time,
        from any court of competent jurisdiction, to prevent the actual or threatened infringement,
        misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or
        other intellectual property rights.
      </p>
      <p className='mb-6 fs-4'>
        <b>(d) Waiver of Jury Trial:</b> YOU AND DIIBS WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
        TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Diibs are instead
        choosing to have claims and disputes resolved by arbitration. Arbitration procedures are
        typically more limited, more efficient, and less costly than rules applicable in court and
        are subject to very limited review by a court. In any litigation between you and Diibs over
        whether to vacate or enforce an arbitration award, YOU AND DIIBS WAIVE ALL RIGHTS TO A JURY
        TRIAL, and elect instead to have the dispute be resolved by a judge.
      </p>
      <p className='mb-6 fs-4'>
        <b>(e) Waiver of Class or Consolidated Actions:</b> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE
        OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT
        ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED
        JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of
        class or consolidated actions is deemed invalid or unenforceable, neither you nor Diibs is
        entitled to arbitration; instead all claims and disputes will be resolved in a court as set
        forth in (g) below.
      </p>
      <p className='mb-6 fs-4'>
        <b>(f) Opt-out:</b> You have the right to opt out of the provisions of this Section by
        sending written notice of your decision to opt out to the following address: 30 Park Ave,
        New York City, New York 10016 postmarked within thirty (30) days of first accepting these
        Terms. You must include (i) your name and residence address, (ii) the email address and/or
        telephone number associated with your account, and (iii) a clear statement that you want to
        opt out of these Terms’ arbitration agreement.
      </p>
      <p className='mb-6 fs-4'>
        <b>(g) Exclusive Venue:</b> If you send the opt-out notice in (f), and/or in any
        circumstances where the foregoing arbitration agreement permits either you or Diibs to
        litigate any dispute arising out of or relating to the subject matter of these Terms in
        court, then the foregoing arbitration agreement will not apply to either party, and both you
        and Diibs agree that any judicial proceeding (other than small claims actions) will be
        brought in the state or federal courts located in, respectively, New York, New York, or the
        federal district in which that county falls.
      </p>
      <p className='mb-6 fs-4'>
        <b>(h) Severability:</b> If the prohibition against class actions and other claims brought
        on behalf of third parties contained above is found to be unenforceable, then all of the
        preceding language in this Arbitration Agreement section will be null and void. This
        arbitration agreement will survive the termination of your relationship with Diibs.
      </p>
      <p className='mb-6 fs-4'>
        <b>
          <u>Miscellaneous:</u>
        </b>{' '}
        You will be responsible for paying, withholding, filing, and reporting all taxes, duties,
        and other governmental assessments associated with your activity in connection with the
        Services, provided that the Diibs may, in its sole discretion, do any of the foregoing on
        your behalf or for itself as it sees fit. The failure of either you or us to exercise, in
        any way, any right herein shall not be deemed a waiver of any further rights hereunder. If
        any provision of these Terms are found to be unenforceable or invalid, that provision will
        be limited or eliminated, to the minimum extent necessary, so that these Terms shall
        otherwise remain in full force and effect and enforceable. You and Diibs agree that these
        Terms are the complete and exclusive statement of the mutual understanding between you and
        Diibs, and that these Terms supersede and cancel all previous written and oral agreements,
        communications and other understandings relating to the subject matter of these Terms. You
        hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture
        of Diibs, and you do not have any authority of any kind to bind Diibs in any respect
        whatsoever.
        <br />
        <br /> Except as expressly set forth in the sections above regarding the Apple Application
        and the arbitration agreement, you and Diibs agree there are no third-party beneficiaries
        intended under these Terms.
      </p>
    </div>
  );
};

export default TermsAndConditions;
