import React from 'react';
import {convertUtcToLocal} from '../../../Helpers';
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers';
import {CommonStatusInTable} from '../../common/DataTableViews';

function TransactionDetailsBody({handleCancelCallback, transactionDetails}) {
  const hasFailedTransaction = transactionDetails?.transactionHistory?.some(
    (item) => item.status === 'failed'
  );

  return (
    <>
      <div className='d-flex flex-stack mb-5'>
        <div className='d-flex align-items-center me-2 w-100'>
          <div className='symbol symbol-50px me-6'>
            <span className='symbol-label bg-light-primary'>
              <img
                src={
                  transactionDetails?.restaurant?.images
                    ? transactionDetails?.restaurant?.images
                    : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
                }
                className=' rounded-circle  symbol-label align-self-end w-[40px] h-[40px]'
                alt=''
              />
            </span>
          </div>
          <div className='d-flex flex-column w-100 '>
            <div className='d-flex justify-content-between '>
              <h2 className='fw-bold'>{transactionDetails?.restaurant?.name}</h2>
            </div>
            <span className='fs-7 text-muted'>
              <span className='text-muted fw-semibold d-flex align-items-center'>
                <i className='ki-duotone ki-geolocation fs-5'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <span className='mx-2 fs-5 fw-bold'>{transactionDetails?.restaurant?.city}</span>
                <div className='d-flex align-items-center '>
                  <i className='ki-duotone ki-time fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <span className='mx-2 fs-5 fw-bold text-uppercase'>
                    {' '}
                    {convertUtcToLocal(transactionDetails?.updated_at)}
                  </span>
                </div>
              </span>
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
        <div className='flex-grow-1 me-2 d-flex align-items-center'>
          <div className='symbol symbol-40px me-5'>
            <span className='symbol-label bg-light-info'>
              <KTIcon iconName='badge' className='fs-2x text-danger' />
            </span>
          </div>
          <span className='text-gray-800 fs-6 fw-bold'>Name</span>
        </div>
        <div className='d-flex align-items-center'>
          <div className='my-2 fs-6'>
            {' '}
            {transactionDetails?.user?.firstName + ' ' + transactionDetails?.user?.lastName}
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
        <div className='flex-grow-1 me-2 d-flex align-items-center'>
          <div className='symbol symbol-40px me-5'>
            <span className='symbol-label bg-light-info'>
              <KTIcon iconName='dollar' className='fs-2x text-danger' />
            </span>
          </div>
          <span className='text-gray-800 fs-6 fw-bold'>Amount</span>
        </div>
        <div className='d-flex align-items-center'>
          <div className='my-2 fs-6'> {'$ ' + transactionDetails.amount} </div>
        </div>
      </div>
      <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
        <div className='flex-grow-1 me-2 d-flex align-items-center'>
          <div className='symbol symbol-40px me-5'>
            <span className='symbol-label bg-light-info'>
              <KTIcon iconName='verify' className='fs-2x text-danger' />
            </span>
          </div>
          <span className='text-gray-800 fs-6 fw-bold'> Total Attempts </span>
        </div>
        <div className='d-flex align-items-center'>
          <div className='text-left my-2 fs-6'> {transactionDetails.attempt} </div>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col' className='fs-4 fw-bold'>
              Date & Time
            </th>
            {!hasFailedTransaction && (
              <th scope='col' className='fs-4 fw-bold'>
                Transaction Id
              </th>
            )}
            <th scope='col' className='fs-4 fw-bold'>
              Status
            </th>
            {hasFailedTransaction && (
              <th scope='col' className='fs-4 fw-bold'>
                Reason
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {transactionDetails?.transactionHistory?.map((item: any, i) => {
            return (
              <tr>
                <td className='fs-7 fw-bold text-gray-500' style={{minWidth: '130px'}}>
                  {convertUtcToLocal(item.created_at).toUpperCase()}
                </td>
                {item.status !== 'failed' && (
                  <td className='fs-7 fw-bold text-gray-500'>
                    {item.paymentIntentId ? item.paymentIntentId : ' - '}
                  </td>
                )}
                <td className='fs-10 fw-bold text-gray-500'>
                  <CommonStatusInTable text={item.status} />
                </td>
                {item.status === 'failed' && (
                  <td className='fs-6 fw-bold text-gray-500 bg-white'>
                    <div style={{maxHeight: '50px', overflowY: 'auto'}}>
                      {item?.cancellationReason}
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default TransactionDetailsBody;
