import axios from 'axios';
import {IParamsInfo} from './index.d';
import {
  BASE_URL,
  CREATE_CONTACT,
  DATE_FILTER,
  GET_CONTACT,
  PAGINATION,
  RESTAURANT_ID_FILTER,
  SEARCH,
  STATUS_FILTER,
  TYPE_FILTER,
  UPDATE_CONTACT,
  USER_ID_FILTER,
} from './Urls';

export class ContactApiModule {
  constructor() {}

  async addContact(contactData) {
    try {
      const response = await axios.post(BASE_URL + CREATE_CONTACT, contactData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async UpdateContact(id) {
    try {
      const response = await axios.put(BASE_URL + UPDATE_CONTACT + id);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getContact(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(GET_CONTACT, paramInfo.pageNo, paramInfo.pageSize) +
          SEARCH(paramInfo.searchKeyword) +
          STATUS_FILTER(paramInfo.statusFilter) +
          TYPE_FILTER(paramInfo.type) +
          DATE_FILTER(paramInfo.startDate, paramInfo.endDate) +
          USER_ID_FILTER(paramInfo.userIdFilter) +
          RESTAURANT_ID_FILTER(paramInfo.restaurantIdFilter)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }
}
