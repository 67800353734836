import { PageTitle } from '../../../../../_metronic/layout/core';

const ContactDetails = () => {
  return (
    <div>
      <PageTitle breadcrumbs={[]}>Contact</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Contact Form</h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Restaurant Name</label>

              <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
                Restaurant
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
                restaurant@gmail.com
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Query</label>
              <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
                Call the restaurant ASAP and tell them you're sorry but need to cancel you
                reservations. They can contact someone on their waiting list if it's an exclusive
                restaurant.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
