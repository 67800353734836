import React from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import BiddingDataTable from '../../../components/common/DataTableViews/Bidding';

function RestaurantBidding() {
  return (
    <>
      {' '}
      <PageTitle breadcrumbs={[]}>Bidding List</PageTitle>
      <BiddingDataTable />
    </>
  );
}

export default RestaurantBidding;
