import {useFormik} from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

type CouponFormValues = {
  requiredAmount: number;
  discount: number;
  limit: number;
  expiry: any;
  code: string;
};

const initialCouponValues: CouponFormValues = {
  requiredAmount: 0,
  discount: 0,
  limit: 0,
  expiry: new Date(),
  code: '',
};

const couponSchema = Yup.object().shape({
  requiredAmount: Yup.number()
    .min(1, 'Required amount must be at least 0')
    .required('Required amount is required'),
  discount: Yup.number().min(1, 'Discount must be at least 0').required('Discount is required'),
  limit: Yup.number().min(1, 'Limit must be at least 0').required('Limit is required'),
  expiry: Yup.date().required('Expiry date is required').typeError('Invalid date format'),
  code: Yup.string()
    .min(3, 'Code should have at least 3 characters')
    .max(16, 'Code should have at most 16 characters')
    .matches(/^\S*$/, 'Code should not contain spaces')
    .required('Code is required'),
});

const CouponBody = ({
  userDetails,
  handleSaveCallback,
  handleCancelCallback,
  isView = 'view',
}: {
  userDetails: CouponFormValues;
  handleSaveCallback: (value: CouponFormValues) => void;
  handleCancelCallback: () => void;
  isView: 'view' | 'edit' | any;
}) => {
  const formik = useFormik({
    initialValues: userDetails || initialCouponValues,
    validationSchema: couponSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      await handleSaveCallback(values);
      formik.setSubmitting(false);
    },
  });

  const isDisabled = isView === 'view';

  return (
    <>
      <form
        id='kt_modal_add_coupon_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_coupon_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_coupon_header'
          data-kt-scroll-wrappers='#kt_modal_add_coupon_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* Required Amount */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Required Amount</label>
            <input
              placeholder='Required Amount'
              {...formik.getFieldProps('requiredAmount')}
              type='number'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              disabled={isDisabled || formik.isSubmitting}
            />
            {formik.touched.requiredAmount && formik.errors.requiredAmount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.requiredAmount}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row row mb-7 date-width'>
            {/* Discount */}
            <div className='col-md-6 mb-7'>
              {' '}
              <label className='required fw-bold fs-6 mb-2'>Discount</label>
              <input
                placeholder='Discount'
                {...formik.getFieldProps('discount')}
                type='number'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={isDisabled || formik.isSubmitting}
              />
              {formik.touched.discount && formik.errors.discount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.discount}</span>
                  </div>
                </div>
              )}
            </div>

            {/* Limit */}
            <div className='col-md-6 mb-7'>
              {' '}
              <label className='required fw-bold fs-6 mb-2'>Limit</label>
              <input
                placeholder='Limit'
                {...formik.getFieldProps('limit')}
                type='number'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={isDisabled || formik.isSubmitting}
              />
              {formik.touched.limit && formik.errors.limit && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.limit}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row row mb-7 date-width'>
            {/* Expiry Date */}
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Expiry Date</label>
              <br />
              <DatePicker
                autoComplete='off'
                dateFormat='MM/dd/yyyy'
                selected={formik.values.expiry}
                placeholder='Coupon Expiry'
                onBlur={() => formik.setFieldTouched('expiry', true)}
                className='form-control form-control-solid mb-3 mb-lg-0'
                shouldCloseOnSelect={false}
                name='expiry'
                onChange={(expiry) => {
                  formik.setFieldValue('expiry', expiry);
                }}
                disabled={isDisabled || formik.isSubmitting}
                minDate={new Date()}
              />
              {formik.touched.expiry && formik.errors.expiry && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.expiry as string}</span>
                  </div>
                </div>
              )}
            </div>
            {/* Code */}
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Code</label>
              <input
                placeholder='Code'
                {...formik.getFieldProps('code')}
                value={formik.values.code.toUpperCase()}
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                maxLength={20}
                autoComplete='off'
                onInput={(e: any) => {
                  e.target.value = e.target.value.toUpperCase();
                  formik.setFieldValue('code', e.target.value);
                }}
                disabled={isDisabled || formik.isSubmitting}
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.code}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Actions */}
        {!isDisabled && (
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={handleCancelCallback}
              className='btn btn-light me-3'
              disabled={formik.isSubmitting}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              {!formik.isSubmitting && 'Submit'}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default CouponBody;
