import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {convertUtcToLocal} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';
import {CalculateTimeDifference} from '../../common/CalculateTimeDifference';
const AuctionDetailsBody = ({
  auctionDetails,
  handleCancelCallback,
}: {
  auctionDetails: any;
  handleCancelCallback: () => void;
}) => {
  const {currentUser} = useAuth();

  return (
    <>
      {currentUser?.role === 'ADMIN' && (
        <div className='d-flex flex-stack mb-5'>
          <div className='d-flex align-items-center me-2 w-100'>
            <div className='symbol symbol-50px me-6'>
              <span className='symbol-label bg-light-primary'>
                <img
                  src={
                    auctionDetails?.image
                      ? auctionDetails?.image
                      : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
                  }
                  className=' rounded-circle  symbol-label align-self-end w-[40px] h-[40px]'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex flex-column w-100 '>
              <div className='d-flex justify-content-between '>
                <h2 className='fw-bold'>{auctionDetails?.restaurantName}</h2>
                <div className='d-flex align-items-center'>
                  <i className='ki-duotone ki-user fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <span className='mx-2 fs-4 fw-bold'>{auctionDetails?.capacity}</span>
                </div>
              </div>
              <span className='fs-7 text-muted'>
                <span className='text-muted fw-semibold d-flex align-items-center'>
                  <i className='ki-duotone ki-geolocation fs-5'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <span className='mx-2 fs-5 fw-bold'>{auctionDetails?.restaurantLocation}</span>
                  <div className='d-flex align-items-center '>
                    <i className='ki-duotone ki-time fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <span className='mx-2 fs-5 fw-bold text-uppercase'>
                      {' '}
                      {convertUtcToLocal(auctionDetails?.dineDate)}
                    </span>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      )}

      <div className='d-flex flex-wrap mt-6 justify-content-center'>
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
          <div className='d-flex align-items-center justify-content-center'>
            <div
              className='fs-2 fw-bold counted'
              data-kt-countup='true'
              data-kt-countup-value='4500'
              data-kt-countup-prefix='$'
              data-kt-initialized='1'
            >
              {auctionDetails?.totalBid}
            </div>
          </div>
          <div className='fw-semibold fs-6 text-gray-500 fw-bold text-center'>Total Bids</div>
        </div>
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
          <div className='d-flex align-items-center flex-column'>
            <div
              className='fs-2 fw-bold counted text-center'
              data-kt-countup='true'
              data-kt-countup-value='80'
              data-kt-initialized='1'
            >
              {auctionDetails?.highestBid ? '$' + auctionDetails?.highestBid : 'No bids'}
            </div>
            <div className='fw-semibold fs-6 text-gray-500 fw-bold text-center'>Highest Bid</div>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center'>
          <div
            className='border border-gray-300 border-dashed rounded d-flex flex-column align-items-center justify-content-center py-3 px-4 me-6 mb-3'
            style={{width: 'fit-content'}}
          >
            <div
              className='fs-2 fw-bold counted'
              data-kt-countup='true'
              data-kt-countup-value='60'
              data-kt-countup-prefix='%'
              data-kt-initialized='1'
            >
              <CalculateTimeDifference time={auctionDetails?.deadline} />
            </div>
            <div className='fw-semibold fs-6 text-gray-500 fw-bold text-center'>
              Auction ends in
            </div>
          </div>
        </div>
      </div>
      {auctionDetails?.winnerFirstName && (
        <div className='winner-section'>
          <h2 className='m-4 text-center'>
            Auction Winner :{' '}
            {auctionDetails?.winnerFirstName + ' ' + auctionDetails?.winnerLastName}
          </h2>
        </div>
      )}
      {auctionDetails?.biddings?.length ? (
        <>
          <div className='text-center fw-bold fs-2 my-5'>Top 5 Bids</div>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col' className='fs-4 fw-bold'>
                  User
                </th>
                <th scope='col' className='fs-4 fw-bold'>
                  Date & Time
                </th>
                <th scope='col' className='fs-4 fw-bold'>
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {auctionDetails?.biddings.map((item: any, i) => {
                return (
                  <tr>
                    <td className='fs-6 fw-bold text-gray-500'>
                      {item.user?.firstName + ' ' + item.user?.lastName}
                    </td>
                    <td className='fs-6 fw-bold text-gray-500'>
                      {convertUtcToLocal(item.updated_at)}
                    </td>
                    <td className='fs-6 fw-bold text-gray-500'>$ {item.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div className='text-center fw-bold fs-2 my-5'>No Bids Yet</div>
      )}
    </>
  );
};

export default AuctionDetailsBody;
