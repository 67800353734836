import {useEffect} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {RestaurantParamsInfo, TInitialState} from '..';
import {CommonRowInTable, CommonViewInHeader} from '../..';
import {KTIcon} from '../../../../../../_metronic/helpers';
import {useThemeMode} from '../../../../../../_metronic/partials';
import DataTableWrapper from '../../../../../DataTable';
import {
  API_ERROR_MESSAGE,
  AUCTION_ROUTE,
  TOAST_GENERAL,
  convertUtcToLocal,
} from '../../../../../Helpers';
import {useSessionReducer} from '../../../../../hook/useSessionReducer';
import {useAuth} from '../../../../../modules/auth';
import {CustomModal} from '../../../../Modal';
import reducerFunc from '../action';
import {Row} from 'react-bootstrap';
import dayjs from 'dayjs';

const initialState: TInitialState = {
  loading: true,
  data: [],
  capacity: '',
  isModalOpen: false,
  selectedRow: null,
  totalData: -1,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  error: null,
  search: '',
  statusFilter: '',
  restaurantFilter: '',
  dateFilter: [
    {startDate: '', endDate: ''},
    {startDate: '', endDate: ''},
    {startDate: '', endDate: ''},
  ],
  modalType: 'AUCTION',
};

function formatTimeToLocal(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${formattedMinutes} ${ampm}`;
}

const AuctionDataTable = ({restaurantId = '', setRestaurantName}: RestaurantParamsInfo) => {
  const [state, dispatch] = useSessionReducer<any, any, string>(
    reducerFunc,
    {...initialState, restaurantFilter: restaurantId || ''},
    'AUCTION'
  );
  // const [state, dispatch] = useReducer(reducerFunc, initialState);
  const {mode} = useThemeMode();
  const {auctionModule} = useAuth();
  const {currentUser} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const params = useParams();
  const location: any = useLocation();

  let columns = [
    {
      name: <CommonViewInHeader name='Table' />,
      selector: (row, i) => row.table.capacity,
      sortable: true,
      width: '140px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.table?.capacity + ' Persons'}
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            classes={'text-dark'}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Auction Type' />,
      selector: (row, i) => row?.auctionType?.type,
      sortable: true,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auctionType?.type ? row?.auctionType?.type : '-'}
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            classes={'text-dark'}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='status' />,
      selector: (row, i) => row.status,
      width: '150px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.status}
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            classes='text-dark text-capitalize'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Starting Bid Price' />,
      selector: (row, i) => row?.price,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={'$' + row?.price}
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            classes='text-dark'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Highest Bid Price' />,
      selector: (row, i) => row?.highestBid,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.highestBid ? '$' + row?.highestBid : 'No bids'}
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            classes='text-dark'
          />
        );
      },
      sortable: true,
    },
    // {
    //   name: <CommonViewInHeader name='Auction Starts' />,
    //   selector: (row, i) => row?.startDate,
    //   width: '230px',
    //   format: (row, i) => {
    //     return (
    //       <CommonRowInTable
    //         navigateURL={`${AUCTION_ROUTE}/${row.id}`}
    //         text={convertUtcToLocal(row?.startDate)}
    //         classes='text-uppercase text-dark'
    //       />
    //     );
    //   },
    //   sortable: true,
    // },
    // {
    //   name: <CommonViewInHeader name='Auction Ends' />,
    //   selector: (row, i) => row.deadline,
    //   width: '230px',
    //   format: (row, i) => {
    //     return (
    //       <CommonRowInTable
    //         navigateURL={`${AUCTION_ROUTE}/${row.id}`}
    //         text={convertUtcToLocal(row?.deadline)}
    //         classes='text-dark text-uppercase'
    //       />
    //     );
    //   },
    //   sortable: true,
    // },
    {
      name: <CommonViewInHeader name='Dine In Date' />,
      selector: (row, i) => row.dineDate,
      width: '230px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${AUCTION_ROUTE}/${row.id}`}
            text={convertUtcToLocal(row?.dineDate)}
            classes='text-dark text-uppercase'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.price,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <Link
              title='View auction details'
              className='linkIcon my-4'
              to={`${AUCTION_ROUTE}/${row.id}`}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </Link>
            {!isAdmin && (
              <span
                title='Edit auction'
                className='menu-link px-3'
                onClick={() => {
                  if (row.highestBid === null && row.status !== 'COMPLETED') {
                    dispatch({
                      type: 'SELECT_ROW',
                      payload: {
                        id: row.id,
                        isView: true,
                        tableId: row.tableId,
                        tablePrice: row.price,
                        auctionStartDate: new Date(row.startDate),
                        deadlineDate: new Date(row.deadline),
                        dineDate: new Date(row.dineDate),
                        dineTime: formatTimeToLocal(new Date(row.dineDate)),
                        auctionType: row?.auctionType?.id,
                        bidsEndAt: formatTimeToLocal(new Date(row.deadline)),
                      },
                    });
                    dispatch({type: 'OPEN_MODAL', payload: 'AUCTION'});
                  } else {
                    if (row.highestBid !== null && row.status !== 'COMPLETED') {
                      toast.error('Can’t edit, auction has bidding.', {
                        toastId: TOAST_GENERAL,
                      });
                    } else {
                      toast.error('Can’t edit, auction is completed.', {
                        toastId: TOAST_GENERAL,
                      });
                    }
                  }
                }}
              >
                <KTIcon iconName='pencil' className='fs-1' />
              </span>
            )}

            <span
              title='Delete auction'
              className='linkIcon disabled'
              onClick={() => {
                if (row?.highestBid !== null && row.winnerId === null && row.status === 'ACTIVE') {
                  toast.error(`Can't delete auction as it has biddings.`);
                } else if (
                  row.winnerId !== null &&
                  row.dineDate > new Date().toISOString() &&
                  row?.highestBid !== null
                ) {
                  toast.error(`Can't delete auction as it has upcoming reservations.`);
                } else if (
                  row.winnerId !== null &&
                  row.dineDate > new Date().toISOString() &&
                  row?.highestBid === null &&
                  row.status === 'COMPLETED'
                ) {
                  toast.error(`Can't delete auction as it has upcoming reservations.`);
                } else {
                  dispatch({type: 'SELECT_ROW', payload: row});
                  dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
                }
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  columns = isAdmin
    ? [
        {
          name: <CommonViewInHeader name='Restaurant' classes='ms-8' />,
          selector: (row, i) => row.restaurants.name,
          sortable: true,
          width: '240px',
          format: (row, i) => {
            return (
              <CommonRowInTable
                navigateURL={`${AUCTION_ROUTE}/${row.id}`}
                text={row.restaurants?.name}
                avatar={row.restaurants?.images || 'media/avatars/restaurant-blank.png'}
                classes='text-dark'
              />
            );
          },
        },
        ...columns,
      ]
    : columns;

  const getTAuctions = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await auctionModule?.getAuctions({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state?.search,
      statusFilter: state?.statusFilter || state?.statusFilter || location?.state?.value,
      // restaurantIdFilter: state.restaurantFilter,
      restaurantIdFilter: state.restaurantFilter ? state.restaurantFilter : params.restaurantId,
      capacity: state.capacity,
      dineDateRangeStart: state.dateFilter[0]?.startDate,
      dineDateRangeEnd: state.dateFilter[0]?.endDate,
    });
    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const tablevalue = localStorage.getItem('auctionTabValue');
  useEffect(() => {
    if (tablevalue === 'normalAuction') {
      getTAuctions();
    }
  }, [tablevalue]);

  const createTAuctions = async (userData) => {
    const result = await auctionModule?.createAuction(userData);
    if (result?.success) {
      toast.success(result?.message || 'Auction Created succesfully');
      getTAuctions();
      return true;
    } else {
      toast.error(result?.message || `Problem occured, While creating Auction`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const updateTAuctions = async (auctionId, auctionData) => {
    const result = await auctionModule?.updateAuction(auctionId, auctionData);
    if (result?.success) {
      toast.success(`Auction Updated succesfully`, {
        toastId: 'auction updated',
      });
      getTAuctions();
      return true;
    } else {
      toast.error(`Problem occured, While Updating Auction`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  useEffect(() => {
    getTAuctions();
  }, [
    state.currentPage,
    state.pageSize,
    state.search,
    state.restaurantFilter,
    state.statusFilter,
    state.capacity,
    state.dateFilter[0].startDate,
    state.dateFilter[0].endDate,
    location?.state,
  ]);

  const subHeader = !isAdmin ? (
    <button
      type='button'
      className='btn btn-primary'
      onClick={() => {
        dispatch({type: 'OPEN_MODAL', payload: 'AUCTION'});
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Auction
    </button>
  ) : null;

  const deleteTAuction = async (auctionIds) => {
    const result = await auctionModule?.deleteAuctions(auctionIds);
    if (result?.success) {
      toast.success(`Auction Deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      getTAuctions();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    dispatch({type: 'CLOSE_MODAL'});
  };

  return (
    <>
      <DataTableWrapper
        subHeader={subHeader}
        totalData={state.totalData}
        selectableRows={true}
        loading={state.loading}
        pageSize={state.pageSize}
        columns={columns}
        handleSelected={(selected) => {
          dispatch({type: 'SELECT_ROW', payload: selected});
          dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
        }}
        data={state.data}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [
            {
              label: 'Status',
              options: ['Active', 'Inactive', 'Completed'].map((t) => ({
                value: t.toUpperCase(),
                label: t,
              })),
              selected: location?.state,
            },
          ],
          date: {
            occurance: 1,
            labels: ['Dine In'],
          },
          restaurantFilter: isAdmin && !restaurantId,
          capacity: true,
          preserveType: 'AUCTION',
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_STATUS', payload: value.Status?.value});
          dispatch({type: 'SET_RESTAURANT', payload: value?.restaurantFilter?.value});
          dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
          dispatch({type: 'SET_DATE', payload: value?.dateConfig});
        }}
        isFilterApplied={Boolean(
          state.restaurantFilter ||
            state.statusFilter ||
            state.search ||
            state.capacity ||
            state.dateFilter[0].startDate ||
            state.dateFilter[0].endDate
        )}
      />
      {state.isModalOpen && (
        <CustomModal
          title={''}
          // title={state.selectedRow ? 'Edit Auction' : 'Add Auction'}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (value) => {
            let result = false;
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state?.selectedRow?.id];
              await deleteTAuction({ids});
            } else if (state.selectedRow) {
              result = await updateTAuctions(state.selectedRow.id, value);
            } else {
              result = await createTAuctions({
                ...value,
              });
            }
            if (result) dispatch({type: 'CLOSE_MODAL'});
          }}
          type={state.modalType}
        />
      )}
    </>
  );
};

export default AuctionDataTable;
