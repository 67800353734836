import {PageTitle} from '../../../../_metronic/layout/core';
import AuctionWrapper from '../../../components/common/AuctionWrapper';

const AuctionTable = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Auctions List</PageTitle>
      <AuctionWrapper />
    </>
  );
};

export default AuctionTable;
