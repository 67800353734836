import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useSessionState = function (initialState, key) {
  const {pathname} = useLocation();
  const storedRoutesString = sessionStorage.getItem('storedRoutes');
  let storedRoutes = storedRoutesString ? JSON.parse(storedRoutesString) : [];
  const initialValueJSON = sessionStorage.getItem(key);
  if (
    (storedRoutes[1] === pathname &&
      storedRoutes[2]?.includes(storedRoutes[1]) &&
      storedRoutes[2] !== storedRoutes[1] &&
      initialValueJSON) ||
    (pathname?.includes('bidding') &&
      storedRoutes[1] === pathname &&
      storedRoutes[2]?.includes('auction') &&
      storedRoutes[2] !== storedRoutes[1] &&
      initialValueJSON)
  ) {
    const state = JSON.parse(initialValueJSON);
    initialState = {
      ...initialState,
      capacity: state.capacity,
      minPrice: state.minPrice,
      maxPrice: state.maxPrice,
      startDate: state.startDate ? new Date(state.startDate) : '',
      endDate: state.endDate ? new Date(state.endDate) : '',
      Status: state.statusFilter ?? '',
      ['Email verified']: state.verifiedFilter ?? '',
      dateConfig: state.dateFilter
        ? state.dateFilter.map((dates) => ({
            startDate: dates.startDate ? new Date(dates.startDate) : '',
            endDate: dates.endDate ? new Date(dates.endDate) : '',
          }))
        : [],
      userFilter: '',
      restaurantFilter: '',
      setUserFilter: state.userFilter,
      setRestaurantFilter: state.restaurantFilter,
    };
  }
  const [value, setValue] = useState(initialState);
  return [value, setValue];
};
