export interface TInitialState {
  loading: boolean;
  data: any[];
  currentPage: number;
  pageSize: number;
  totalPages: number;
  error: null | any;
  search: string;
  changeStatusId?: null | string;
  restaurantFilter: string;
  capacity: string;
  isModalOpen: boolean;
  startDate: string;
  endDate: string;
  totalData: number;
  userFilter: string;
}

interface BiddingType {
  id: number;
  name: string;
  restaurantName: string;
  table: string;
  status: string;
  price: number;
  time: string;
  attended: string;
}

export default function reducerFunc(
  state: TInitialState,
  action: {type: any; payload: any}
): TInitialState {
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      currentPage: action.payload,
    };
  }

  if (action.type === 'SEARCH_CHANGE') {
    return {...state, search: action.payload};
  }

  if (action.type === 'GET_DATA') {
    return {...state, data: action.payload, loading: false};
  }

  if (action.type === 'LOADING') {
    return {...state, loading: action.payload};
  }

  if (action.type === 'SET_RESTAURANT') {
    return {...state, restaurantFilter: action.payload, currentPage: 1};
  }

  if (action.type === 'SET_TOTAL_PAGES') {
    return {
      ...state,
      totalPages: Math.ceil(action.payload / state.pageSize),
      totalData: action.payload,
    };
  }

  if (action.type === 'SET_CAPACITY') {
    return {...state, capacity: action.payload, currentPage: 1};
  }

  if (action.type === 'DATE_FILTER') {
    return {
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      currentPage: 1,
    };
  }

  if (action.type === 'SET_USER') {
    return {...state, userFilter: action.payload, currentPage: 1};
  }

  if (action.type === 'SET_PAGE_SIZE') {
    return {
      ...state,
      pageSize: action.payload,
    };
  }
  return state;
}
