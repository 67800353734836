import {FC} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  gotActiveMenu?: any;
  disabled?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  gotActiveMenu,
  disabled = false,
}) => {
  const {pathname} = useLocation();
  let isActive = checkIsActive(pathname, to);

  const checkPath = (path: string) => {
    if (path === '/bidding' || path === '/auction') {
      localStorage.setItem('path', path);
    }
  };

  return (
    <div className='menu-item' onClick={() => checkPath(to)}>
      <Link
        className={clsx('menu-link without-sub', {active: isActive, disabled})}
        to={disabled ? '#' : to}
        style={{pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1}}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export {AsideMenuItem};
