import * as Yup from 'yup';
import {useFormik} from 'formik';
import clsx from 'clsx';
import {useAuth} from '../../../modules/auth';

type TTable = {
  capacity: number | null;
  id?: string;
  restaurantId?: string;
};
export const initialTable: TTable = {
  capacity: null,
};

const TableBody = ({
  tableDetails,
  handleSaveCallback,
  handleCancelCallback,
}: {
  tableDetails: TTable;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
}) => {
  const {currentUser} = useAuth();
  const adminSchema = Yup.object().shape({
    capacity: Yup.number()
      .required('Capacity is Required')
      .typeError('Capacity must have number')
      .min(1)
      .max(10)
      .positive('Capacity Must be a positive value'),
  });
  const restaurantSchema = Yup.object().shape({
    capacity: Yup.number()
      .required('Capacity is Required')
      .typeError('Capacity must have number')
      .min(1)
      .max(6)
      .positive('Capacity Must be a positive value'),
  });
  const schema = currentUser?.role === 'ADMIN' ? adminSchema : restaurantSchema;
  const formik = useFormik({
    initialValues:
      {
        capacity: tableDetails?.capacity,
        id: tableDetails?.id,
        restaurantId: tableDetails?.restaurantId,
      } || initialTable,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      formik.setSubmitting(true);
      await handleSaveCallback(values);
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='col-md-6 fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Capacity</label>

            <input
              placeholder='Capacity'
              {...formik.getFieldProps('capacity')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.capacity && formik.errors.capacity},
                {
                  'is-valid': formik.touched.capacity && !formik.errors.capacity,
                }
              )}
              type='number'
              name='capacity'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.capacity && formik.errors.capacity && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.capacity}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={handleCancelCallback}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            {!formik.isSubmitting && 'Submit'}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export {TableBody};
