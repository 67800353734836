import React from 'react';
import {convertUtcToLocal} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';

const ContactDetalsBody = ({contactDetails}: {contactDetails: any}) => {

  const {currentUser} = useAuth();

  return (
    <div className='card-body p-5 py-0'>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Name</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {currentUser?.role === 'SUPER_ADMIN'
            ? contactDetails?.user?.firstName + ' ' + contactDetails?.user?.lastName
            : contactDetails?.user?.restaurant?.name
            ? contactDetails?.user?.restaurant?.name
            : contactDetails?.user?.firstName + ' ' + contactDetails?.user?.lastName}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Email</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {contactDetails?.user?.email}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Date</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {convertUtcToLocal(contactDetails?.created_at)}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Query</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted query-form-pop-up bg-light rounded'>
          {contactDetails?.message}
        </div>
      </div>
    </div>
  );
};

export default ContactDetalsBody;
