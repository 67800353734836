import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';

const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalData,
}) => {
  const renderPageNumber = () => {
    const pages: any = [];
    const maxPagesToShow = 3;
    if (totalPages <= maxPagesToShow) {
      for (let page = 1; page <= totalPages; page++) {
        pages.push(
          <li key={page} className={clsx('page-item', {active: currentPage === page})}>
            <button key={page} onClick={() => onPageChange(page)} className='page-link'>
              {page}
            </button>
          </li>
        );
      }
    } else {
      if (currentPage <= maxPagesToShow - 2) {
        for (let page = 1; page <= maxPagesToShow; page++) {
          pages.push(
            <li key={page} className={clsx('page-item', {active: currentPage === page})}>
              <button key={page} onClick={() => onPageChange(page)} className='page-link'>
                {page}
              </button>
            </li>
          );
        }
      } else if (currentPage >= totalPages - maxPagesToShow + 3) {
        for (let page = totalPages - maxPagesToShow + 1; page <= totalPages; page++) {
          pages.push(
            <li key={page} className={clsx('page-item', {active: currentPage === page})}>
              <button key={page} onClick={() => onPageChange(page)} className='page-link'>
                {page}
              </button>
            </li>
          );
        }
      } else {
        const startPage = +currentPage - 2;
        const endPage = +currentPage + 2;
        for (let page = startPage; page <= endPage; page++) {
          if (page !== 0 && page <= totalPages) {
            pages.push(
              <li key={page} className={clsx('page-item', {active: currentPage === page})}>
                <button key={page} onClick={() => onPageChange(page)} className='page-link'>
                  {page}
                </button>
              </li>
            );
          }
        }
      }
    }

    return pages;
  };

  const handlePageSizeChange = (e) => {
    const newSize = +e.target.value;

    onPageSizeChange(newSize);
    onPageChange(1);
  };

  const debounceHandler = debounce((value) => {
    if (+value.target.value <= 0 || !value.target.value) {
      return toast.warn('Page number cannot be less than 1 or empty!');
    }
    if (+value.target.value <= totalPages) onPageChange(+value.target.value);
    else toast(`You can only enter up to ${totalPages} page number`);
  }, 1000);

  return (
    <>
      <div className='row mt-4'>
        <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-between justify-content-md-end  flex-column flex-md-row'>
          {totalData !== -1 && (
            <span className='mb-4 ms-0 me-auto text-gray-800 fs-6 mt-2'>
              Total Records {totalData}
            </span>
          )}
          <div className='d-flex align-items-center mb-4'>
            <p className='text-gray-800 fs-6 m-2'>Showing</p>
            <input
              placeholder={currentPage}
              className={`h-25px ${currentPage > 99 ? 'w-50px' : 'w-40px'}`}
              type='number'
              min={1}
              onChange={debounceHandler}
              max={totalPages}
            />
            <p className='text-gray-800 fs-6 m-2'>{` of ${totalPages} Pages`}</p>
          </div>
          <div className='d-flex align-items-center mb-4'>
            <label htmlFor='pageSizeSelect' className='text-gray-800 fs-6 m-2'>
              Rows per page:
            </label>
            <select
              id='pageSizeSelect'
              className='form-select form-select-sm fw-bold w-80px cursor-pointer'
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              {[10, 25, 50, 75, 100].map((size) => (
                <option key={size} value={size} className='fs-6 text-gray-700'>
                  {`${size}`}
                </option>
              ))}
            </select>
          </div>
          <div id='kt_table_users_paginate mb-4'>
            <ul className='pagination'>
              <li
                className={clsx('page-item', {
                  disabled: currentPage === 1,
                })}
              >
                <button
                  className='page-link'
                  onClick={() => onPageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {renderPageNumber()}
              <li
                className={clsx('page-item', {
                  disabled: currentPage === totalPages,
                })}
              >
                <button
                  className='page-link'
                  onClick={() => onPageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPagination;
