import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';
import NotFound from '../../../DataTable/NotFound';
import { API_ERROR_MESSAGE, TOAST_GENERAL, TOAST_USER_DETAILS } from '../../../Helpers';
import { useAuth } from '../../../modules/auth';
import { CustomModal } from '../../Modal';
import CardDefaultBtn from '../../UI/CardDefaultBtn';

function CardDetails({userId}) {
  const [cardData, setCardData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const {userModule} = useAuth();
  const [open, setOpen] = useState(false);
  const getCardDetails = async () => {
    const result = await userModule?.getUserCard({
      customerId: userId,
      type: 'card',
    });
    if (result?.success) {
      setCardData(result?.data.data);
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_USER_DETAILS,
      });
    }
    setLoading(false);
  };

  const removeCard = async (cardId) => {
    setOpen(false);
    const result = await userModule?.removeUserCard({
      customerId: userId,
      cardId,
    });
    if (result?.success) {
      toast.success(`Card removed succesfully`);
      getCardDetails();
      getCardDetails();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  const changeDefaultCard = async (cardId) => {
    const result = await userModule?.changeDefaultUserCard({
      customerId: userId,
      cardId,
    });

    if (result?.success) {
      toast.success(`Default card updated succesfully`);
      getCardDetails();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  useEffect(() => {
    getCardDetails();
  }, []);

  if (loading) {
    return (
      <ContentLoader
        speed={2}
        width={720}
        height={200}
        viewBox='0 0 720 200'
        backgroundColor='#c4c4c4'
        foregroundColor='#e3e3e3'
      >
        <rect x='5' y='23' rx='5' ry='5' width='148' height='110' />
        <rect x='166' y='27' rx='5' ry='5' width='235' height='28' />
        <rect x='171' y='75' rx='5' ry='5' width='151' height='20' />

        <rect x='460' y='55' rx='5' ry='5' width='100' height='40' />
        <rect x='570' y='55' rx='5' ry='5' width='100' height='40' />
        <rect x='680' y='55' rx='5' ry='5' width='40' height='40' />
      </ContentLoader>
    );
  }
  return (
    <>
      {cardData?.length ? (
        cardData.map((card: any) => {
          return (
            <div key={card.id} className='d-flex align-items-sm-center mb-7'>
              <div className='symbol symbol-70px symbol-2by3 me-4'>
                <img src='/media/credit-card.png' alt='bank png' />
              </div>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                <div className='flex-grow-1 me-2'>
                  <span className='text-gray-800 fw-bold fs-6'>
                    <span className='text-capitalize'>{card.card?.brand}</span> credit card
                  </span>
                  <span className='text-muted fw-semibold d-block pt-1'>
                    {card.card?.last4?.padStart(12, '*')}
                  </span>
                </div>
                {card.isDefault ? (
                  <button className='btn btn-primary btn-sm cursor-not-allowed'>Default</button>
                ) : (
                  <CardDefaultBtn
                    card={card}
                    changeDefaultCard={changeDefaultCard}
                    setOpen={setOpen}
                  />
                )}
              </div>
              {open && (
                <CustomModal
                  data={null}
                  title='Delete'
                  handleCancelCallback={() => setOpen(false)}
                  handleSaveCallback={removeCard.bind(null, card.id)}
                  type={'DELETE'}
                />
              )}
            </div>
          );
        })
      ) : (
        <div className='text-center'>
          <NotFound />
        </div>
      )}
    </>
  );
}

export default CardDetails;
