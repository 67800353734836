interface StripeOnBoardProps {
  handleCancelCallback: () => void;
  stripeTitle?: string;
}

export default function StripeOnBoardBody({handleCancelCallback, stripeTitle}: StripeOnBoardProps) {
  return (
    <div className='text-center'>
      <div className='card-body'>
        <h4 className='card-title fw-bold text-black fs-4'>{stripeTitle}</h4>
        <div className='d-flex my-4 flex-center'>
          <button
            className='btn btn-sm btn-danger me-3'
            onClick={() => {
              handleCancelCallback();
            }}
          >
            OK{' '}
          </button>
        </div>
      </div>
    </div>
  );
}
