import axios from 'axios';
import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { ThemeModeProvider } from '../_metronic/partials';
import './assets/css/main.css';
import { CustomModal } from './components/Modal';
import { setupAxiosErr, useAuth } from './modules/auth';
let setUpAxios;
const App = () => {
  const navigate = useNavigate();

  const [showErrModal, setShowErrModal] = useState(false);
  const {logout, currentUser} = useAuth();
  const errorValue = localStorage.getItem('gotErr');

  useLayoutEffect(() => {
    setupAxiosErr(axios, navigate, toast, logout, setShowErrModal);
  }, [axios, navigate, toast, logout]);
  const {pathname} = useLocation();
  const handleCallback = function () {
    setShowErrModal(false);
    logout();
  };

  useEffect(() => {
    // Retrieve existing routes from sessionStorage or initialize an empty array
    const storedRoutesString = sessionStorage.getItem('storedRoutes');
    let storedRoutes = storedRoutesString ? JSON.parse(storedRoutesString) : [];
    if (storedRoutes.length >= 3) {
      storedRoutes = storedRoutes.slice(-2);
    }
    // Get the current route
    const currentRoute = pathname;

    // Update the storedRoutes array with the current route
    const updatedRoutes = [...storedRoutes, currentRoute];
    // Store the updatedRoutes array in sessionStorage
    sessionStorage.setItem('storedRoutes', JSON.stringify(updatedRoutes));
  }, [pathname]);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <Outlet />
              <MasterInit />
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>

        {showErrModal && !pathname.includes('auth') && !pathname.includes('contact') && errorValue && (
          <CustomModal
            title=''
            data=''
            handleCancelCallback={() => {
              handleCallback();
            }}
            handleSaveCallback={() => {
              if (currentUser?.role === 'ADMIN') {
                navigate('/contacts');
              } else {
                navigate('/contact');
              }
              setShowErrModal(false);
            }}
            inActiveTitle={''}
            type='INACTIVE_MESSAGE'
          />
        )}
      </Suspense>
      <ToastContainer />
    </>
  );
};

export { App };
