import { useState } from 'react';

function CardDefaultBtn({card, changeDefaultCard, setOpen}) {
  const [loading, setLoading] = useState(false);
  return (
    <div className='bg-lightrounded gap-5 d-flex flex-wrap fixed-width-childs-100px'>
      <button
        disabled={loading}
        className='btn btn-primary btn-sm'
        onClick={() => {
          changeDefaultCard(card.id);
          setLoading(true);
        }}
      >
        {loading ? 'Loading...' : 'Set Default'}
      </button>
      <button className='btn btn-danger btn-sm' onClick={() => setOpen(true)}>
        Delete Card
      </button>
    </div>
  );
}

export default CardDefaultBtn;
