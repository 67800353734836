import React from 'react';
import {KTIcon} from '../../../_metronic/helpers';
import {useNavigate} from 'react-router-dom';

const ReservationTerms = () => {
  const navigate = useNavigate();

  const handleCancelCallback = () => {
    navigate('/');
  };

  return (
    <div className='container py-4 mt-10'>
      {/* Back Button */}
      {/* <button type='button' className='btn btn-primary mb-5' onClick={handleCancelCallback}>
        <KTIcon iconName='arrow-left' className='fs-4 text-white ms-1' />
        Back
      </button> */}

      <div className='d-flex flex-column me-n7 pe-7'>
        <h1 className='fw-bold fs-1 mb-6 text-center'>Reservation Terms</h1>

        {/* Terms for Restaurant */}
        <h2 className='fw-bold fs-2 mt-6'>1. Agreement to Terms</h2>
        <p className='fs-4'>
          By making reservations (“Reservations”) available through the Services, you, the
          Restaurant, agree to be bound by these Restaurant Terms. These terms govern the
          relationship between the Restaurant and Diibs regarding reservation auctions and
          supplement additional agreements such as the{' '}
          <a href='/terms-and-conditions'>Terms of Use</a> and any order forms.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>2. Restaurant Responsibilities</h2>
        <p className='fs-4'>
          <strong>Reservation Listing:</strong> Restaurant must accurately list reservation details,
          including time, date, number of seats, and any specific terms. Listings cannot be modified
          after they go live.
        </p>
        <p className='fs-4'>
          <strong>Fulfillment of Reservation:</strong> Restaurants must honor winning reservations,
          as specified in the listing. Repeated failure to honor reservations may result in
          termination of Services.
        </p>
        <p className='fs-4'>
          <strong>No Additional Charges Beyond Minimum Spend:</strong> Restaurants agree not to
          impose extra charges beyond the agreed Minimum Spend, except for taxes, gratuities, or
          other disclosed fees.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>3. Payment Terms</h2>
        <p className='fs-4'>
          <strong>Commission:</strong> Diibs holds the Minimum Spend plus a 5% auction fee. Diibs
          pays the Restaurant 92% of this amount, minus a $1.99 operations fee, within 1-5 business
          days.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>4. Rescheduling Policy</h2>
        <p className='fs-4'>
          Users may request rescheduling; if granted, the Minimum Spend will be credited toward the
          User's next Reservation.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>5. License to Trademarks</h2>
        <p className='fs-4'>
          Restaurant grants Diibs a nonexclusive, royalty-free, irrevocable license to use
          Restaurant’s trademarks and content for Services promotion.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>6. Limitation of Liability</h2>
        <p className='fs-4'>
          Diibs is not liable for User behavior or disputes between Restaurant and User, and
          Restaurant agrees to indemnify Diibs for any claims arising from use of the Services.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>7. End User Data</h2>
        <p className='fs-4'>
          Restaurant will comply with Diibs’s privacy policy and applicable privacy laws related to
          any personal information provided through the Services.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>8. Modification of Terms</h2>
        <p className='fs-4'>
          Diibs may update these Restaurant Terms at any time. Continued use of the Services
          indicates acceptance of the revised terms.
        </p>

        {/* Terms for Users */}
        <h2 className='fw-bold fs-2 mt-6'>User Terms</h2>
        <h2 className='fw-bold fs-2 mt-6'>1. Agreement to Terms</h2>
        <p className='fs-4'>
          By placing bids for restaurant reservations, you, the User, agree to be bound by these
          Reservation Terms. These terms govern your rights and responsibilities in relation to
          reservations won through the Services.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>2. Bidding and Reservation Process</h2>
        <p className='fs-4'>
          <strong>Bidding:</strong> Users may bid for reservations, agreeing to pay the winning bid
          amount as the Minimum Spend if successful. Taxes, gratuities, and disclosed additional
          charges are payable on top of the Minimum Spend.
        </p>
        <p className='fs-4'>
          <strong>Reservation Confirmation:</strong> Winning bidders receive confirmation with
          reservation details, including specific terms from the Restaurant.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>3. Payment and Charges</h2>
        <p className='fs-4'>
          Upon winning, a payment hold covers the Minimum Spend and a 5% Auction Fee. At the start
          of the reservation, the hold is released, and the full amount is charged.
        </p>
        <p className='fs-4'>
          <strong>Payment Obligation:</strong> Users must spend at least the Minimum Spend amount,
          regardless of the actual bill. Additional applicable taxes and charges are payable at the
          Restaurant.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>4. Cancellation Policy</h2>
        <p className='fs-4'>
          Reservations are non-refundable. However, Users may transfer reservations or contact Diibs
          for possible rescheduling. If the Restaurant cancels, Diibs will refund the Reservation
          Payment.
        </p>
        <p className='fs-4'>
          <strong>No-Shows:</strong> Users failing to attend will be charged the full Reservation
          Payment, which is non-refundable.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>5. Conduct at the Restaurant</h2>
        <p className='fs-4'>
          Users must conduct themselves respectfully. Misconduct may result in service refusal,
          without exemption from Minimum Spend obligations.
        </p>
        <p className='fs-4'>
          <strong>Transfer of Reservations:</strong> Users may transfer reservations to others via
          the transfer feature on the Diibs app.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>6. Sharing of Information with Restaurants</h2>
        <p className='fs-4'>
          By placing a bid, Users consent to share necessary information with Restaurants for
          reservation management. Diibs is not responsible for Restaurant data practices.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>7. Limitation of Liability</h2>
        <p className='fs-4'>
          Diibs acts solely as a facilitator and is not responsible for restaurant service quality
          or dining experience. Users agree to use Restaurant services at their own risk.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>8. Termination of Access</h2>
        <p className='fs-4'>
          Diibs reserves the right to terminate access to the Services for violation of terms or
          disruptive behavior.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>9. Modification of Terms</h2>
        <p className='fs-4'>
          Diibs may modify these Reservation Terms at any time, with changes communicated via email
          or through the Services.
        </p>
      </div>
    </div>
  );
};

export default ReservationTerms;
