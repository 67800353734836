import { PageTitle } from '../../../../_metronic/layout/core';
import BiddingDataTable from '../../../components/common/DataTableViews/Bidding';

function AdminBidding() {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Bidding List</PageTitle>
      <BiddingDataTable />
    </>
  );
}

export default AdminBidding;
