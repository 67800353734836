import {useParams} from 'react-router-dom';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';
import TableDataTable from '../../../../components/common/DataTableViews/Tables';

const restaurantTableBreadcrumbs: Array<PageLink> = [
  {
    title: 'Restaurants',
    path: '/restaurants',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const RestaurantTables = () => {
  const param = useParams();

  return (
    <>
      <PageTitle breadcrumbs={restaurantTableBreadcrumbs}>
        {param.restaurantId ? param.restaurantId : 'Tables List'}
      </PageTitle>
      <TableDataTable />
    </>
  );
};

export default RestaurantTables;
