/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers';
import {convertUtcToLocal} from '../../Helpers';
import {CalculateTimeDifference} from '../common/CalculateTimeDifference';
import {TopAuction} from '../../pages/Admin';
import {useAuth} from '../../modules/auth';

type Props = {
  className: string;
  title: string;
  description: string;
  handleOnClick: () => void;
  data: TopAuction | null | undefined;
};

const AuctionCard: React.FC<Props> = ({className, title, description, handleOnClick, data}) => {
  const {currentUser} = useAuth();
  return (
    <div
      onClick={handleOnClick}
      className={`card ${className}`}
      style={{
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${toAbsoluteUrl(
          data?.image ? data?.image : '/media/auction-bg.jpg'
        )})`,
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <div className='card-body p-0 rounded' style={{background: 'rgba(0, 0, 0, .3)'}}>
        {currentUser?.role === 'ADMIN' && (
          <>
            <div className={`text-white fw-bold fs-2 mt-7 px-7`}>{data?.restaurantName}</div>
            <div className={`text-white fw-bold fs-6 mb-7 px-7 `}>{data?.restaurantLocation}</div>
          </>
        )}
        <div
          className='d-flex text-white justify-content-between  align-items-center px-4 py-3 rounded'
          style={{
            backdropFilter: 'blur(2px)',
          }}
        >
          <div className='d-flex align-items-end gap-1 text-uppercase'>
            <KTIcon iconName='time' className='fs-3 text-white pt-2' />
            <span>{convertUtcToLocal(data?.dineDate)}</span>
          </div>
          <div className='d-flex align-items-center gap-1' style={{minWidth: '155px'}}>
            <KTIcon iconName='watch' className='fs-3 text-white' />
            <span>
              {data?.auctionStatus === 'ongoing' ? (
                <CalculateTimeDifference time={data?.deadline} />
              ) : (
                '-'
              )}
            </span>
          </div>
          <div className='d-flex align-items-center gap-1' style={{minWidth: '50px'}}>
            <KTIcon iconName='user' className='fs-3 text-white' />
            <span>{data?.capacity}</span>
          </div>

          <div className='d-inline-block' style={{minWidth: '80px', textAlign: 'center'}}>
            <span
              className='badge badge-primary py-2 px-5 fs-6'
              style={{minWidth: '85px', justifyContent: 'center'}}
            >
              {data?.highestBid ? '$ ' + data?.highestBid : 'No bids'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export {AuctionCard};
