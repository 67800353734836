/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {KTIcon} from '../../../_metronic/helpers';
import {useThemeMode} from '../../../_metronic/partials';
import TableLoader from '../../DataTable/TableLoader';
import '../../DataTable/dataTable.css';
import {convertUtcToLocal} from '../../Helpers';
import {TopBiddings} from '../../pages/Admin';

type Props = {
  className: string;
  title: string;
  subTitle?: string;
  isBiddingTable?: boolean;
  onEyeIconClick: (row) => void;
  dashboardData: TopBiddings | null | undefined;
};

const BiddingDashboard: React.FC<Props> = ({
  className,
  title,
  subTitle = '',
  isBiddingTable = false,
  onEyeIconClick,
  dashboardData,
}) => {
  const {mode} = useThemeMode();
  const navigate = useNavigate();
  return (
    <div className={`card ${className} relative`}>
      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-5 sticky ${mode === 'dark' ? 'bg-light' : 'bg-white'}`}
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}

              <table className=' table  align-middle gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-5opx'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {Array.isArray(dashboardData) ? (
                    dashboardData.length ? (
                      dashboardData?.map((e, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>
                              <div className='d-flex align-items-center m-2 py-3'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <Link to={`/users/${e?.userId}`}>
                                    <p
                                      className='text-dark text-capitalize fw-bold text-hover-primary mb-1 fs-6'
                                      style={{
                                        width: '120px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {e?.firstName + ' ' + e?.lastName}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className='text-end text-muted fw-semibold '>
                              <div
                                className='d-flex align-items-center'
                                style={{
                                  width: '200px',
                                }}
                              >
                                <KTIcon iconName='time' className='fs-3' />
                                <span className='mx-2 text-uppercase'>
                                  {' '}
                                  {convertUtcToLocal(e?.created_at)}
                                </span>
                              </div>
                            </td>
                            <td className='text-end text-muted fw-semibold '>
                              <div
                                className='d-flex align-items-center'
                                style={{
                                  width: '60px',
                                }}
                              >
                                <KTIcon iconName='user' className='fs-3' />
                                <span className='mx-2'>{e?.capacity}</span>
                              </div>
                            </td>
                            <td className='text-end'>
                              <span className='badge badge-light-primary py-2 px-4 fs-6'>
                                ${e?.amount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <div className='border border-bottom-2 border-blue-300' />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      ''
                    )
                  ) : (
                    <tr>
                      <td colSpan={4} className='text-center'>
                        <TableLoader />
                      </td>
                    </tr>
                    // <TableLoader />
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {Array.isArray(dashboardData) && dashboardData.length >= 10 && (
            <div className='text-center mt-5 mb-5'>
              <span
                className='cursor-pointer view-more-btn'
                onClick={() => {
                  navigate(`${isBiddingTable ? '/bidding' : '/auction'}`);
                }}
              >
                View More
              </span>
            </div>
          )}
          {(!Array.isArray(dashboardData) || dashboardData?.length < 1) && (
            <p className='fs-2 fw-bold text-gray-600 m-0 text-center'>No Recent bidding found</p>
          )}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export {BiddingDashboard};
