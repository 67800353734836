import {useFormik} from 'formik';
import moment from 'moment';
import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-time-picker-input/dist/components/TimeInput.css';
import * as Yup from 'yup';
import {convertLocalToUtc} from '../../../Helpers';
import TableSelection from '../../common/DataTableViews/Auction/TableSelection';
import dayjs from 'dayjs';
import type {TimePickerProps} from 'antd';
import {TimePicker} from 'antd';
import AuctionTypeSelection from '../../common/DataTableViews/Auction/AuctionTypeSelection';

function createDateWithTime(date: any, time: any) {
  console.log('date', date, 'time', time);

  const [timePart, modifier] = time?.split(' ');
  const [hours, minutes] = timePart?.split(':').map(Number);

  const adjustedHours =
    modifier === 'PM' && hours < 12 ? hours + 12 : modifier === 'AM' && hours === 12 ? 0 : hours;

  const newDate = new Date(date);
  newDate.setHours(adjustedHours, minutes, 0, 0);

  return newDate;
}

interface TAuction {
  tableId?: string;
  auctionType?: string;
  tablePrice: string;
  dineTime?: any;
  dineDate?: any;
  bidsEndAt?: any;
  isView?: boolean;
}

const editAuctionSchema = Yup.object().shape({
  tableId: Yup.string().required('Table is required'),
  tablePrice: Yup.number()
    .min(10, 'Price must be greater than $10')
    .required('Price is required')
    .typeError('Price must be a number')
    .max(20000, 'Value must be less than or equal to 20,000'),
  dineDate: Yup.date().required('Dine In date is required'),
  auctionType: Yup.string().required('Auction Type is required'),
  dineTime: Yup.string().required('Dine In Time is required'),
  bidsEndAt: Yup.string().required('Bids End At is required'),
  // dineDate: Yup.date()
  //   .required('Dine In date is required')
  //   .test(
  //     'auctionStartTimeValidation',
  //     'Dine-In time should be greater than 4 hours from the current time.',
  //     function (value: any) {
  //       const currentTimePlus4Hours = moment().add(4, 'hours');
  //       const selectedTime = moment(value?._d);
  //       return selectedTime.isAfter(currentTimePlus4Hours);
  //     }
  //   ),
});
const AuctionBody = ({
  auctionData,
  handleSaveCallback,
  handleCancelCallback,
}: {
  auctionData: TAuction | any;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
}) => {
  // const [currentTime, setCurrentTime] = useState<any>(new Date());

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const initialAuctionData = {
    tableId: '',
    dineDate: moment().startOf('day').toDate(),
    tablePrice: '',
    dineTime: '',
    bidsEndAt: '',
    auctionType: '',
    isView: false,
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleDineTimeChange = (value: string) => {
    const selectedTime = dayjs(value, 'h:mm A');
    const currentTime = dayjs();
    const currentTimePlus4Hours = currentTime.add(4, 'hours');
    const currentDate = dayjs();
    const auctionDate = formik.values.dineDate;
    formik.setFieldValue('dineTime', value);
    const bidsEndAtTime = selectedTime.subtract(4, 'hours').format('h:mm A');
    formik.setFieldValue('bidsEndAt', bidsEndAtTime);

    // if (selectedTime.isBefore(currentTime)) {
    //   setErrorMessage(`You cannot select a previous time for current day`);
    // } else if (
    //   selectedTime.isBefore(currentTimePlus4Hours) &&
    //   currentDate.isSame(auctionDate, 'day')
    // ) {
    //   setErrorMessage(`Dine-In time should be greater than 4 hours from the current time.`);
    // } else {
    //   setErrorMessage('');
    // }

    if (currentDate.isSame(auctionDate, 'day')) {
      if (selectedTime.isBefore(currentTime)) {
        setErrorMessage(`You cannot select a previous time for the current day`);
      } else if (selectedTime.isBefore(currentTimePlus4Hours)) {
        setErrorMessage(`Dine-In time should be greater than 4 hours from the current time.`);
      } else {
        setErrorMessage('');
      }
    } else {
      setErrorMessage('');
    }
  };

  const onChange: TimePickerProps['onChange'] = (time: any, timeString: any) => {
    handleDineTimeChange(timeString);
  };

  const formik = useFormik({
    initialValues: (auctionData && auctionData) || initialAuctionData,
    validationSchema: editAuctionSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const dateTime1 = createDateWithTime(values.dineDate, values.dineTime);
      const dateTime2 = createDateWithTime(values.dineDate, values.bidsEndAt);
      const auctionData = {
        tableId: values.tableId,
        price: values.tablePrice,
        dineDate: convertLocalToUtc(dateTime1),
        auctionTypeId: values?.auctionType,
        endDate: convertLocalToUtc(dateTime2),
      };
      await handleSaveCallback(auctionData);
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row row mb-7'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Table</label>
              <TableSelection
                onblur={() => {
                  formik.setFieldTouched('tableId', true);
                }}
                as='select'
                {...formik.getFieldProps('tableId')}
                onChange={(value) => {
                  formik.setFieldValue('tableId', value.value);
                }}
                name='tableId'
                value={formik.getFieldProps('tableId').value}
                isDisabled={auctionData?.isView === true}
              />
              {formik.touched.tableId && formik.errors.tableId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tableId as string}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Starting Bid</label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text input-prepand-text '>$</span>
                </div>
                <input
                  placeholder='Starting Bid'
                  {...formik.getFieldProps('tablePrice')}
                  type='number'
                  name='tablePrice'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-') && evt.preventDefault()}
                />
              </div>
              {formik.touched.tablePrice && formik.errors.tablePrice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tablePrice as string}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row row mb-7 date-width'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>DineIn Date</label>
              <DatePicker
                autoComplete='off'
                selected={formik.values.dineDate}
                dateFormat='MM/dd/yy'
                placeholder='Dining Date & Time'
                onBlur={() => formik.setFieldTouched('dineDate', true)}
                className='form-control form-control-lg form-control-solid'
                onChange={(date) => {
                  formik.setFieldValue('dineDate', date);
                  const dineTime = formik?.values?.dineTime;
                  const currentDate = dayjs();
                  const selectedDate = dayjs(date);

                  if (selectedDate.isSame(currentDate, 'day') && dineTime) {
                    const selectedTime = dayjs(dineTime, 'h:mm A');
                    const currentTime = dayjs();
                    const currentTimePlus4Hours = currentTime.add(4, 'hours');
                    if (selectedTime.isBefore(currentTime)) {
                      setErrorMessage(`You cannot select a previous time for the current day`);
                    } else if (
                      selectedTime.isBefore(currentTimePlus4Hours) &&
                      currentDate.isSame(selectedDate, 'day')
                    ) {
                      setErrorMessage(
                        `You cannot select a time within 4 hours from the current time`
                      );
                    } else {
                      setErrorMessage(``);
                    }
                  } else {
                    setErrorMessage('');
                  }
                }}
                popperPlacement='bottom'
                shouldCloseOnSelect={false}
                name='deadline'
                minDate={new Date()}
              />
              {/* <div className='fv-plugins-message-container min-h-20px'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {formik.touched.dineDate && formik.errors.dineDate && formik.errors.dineDate}
                  </span>
                </div>
              </div> */}
              {formik.touched.dineDate && formik.errors.dineDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.dineDate as string}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>DineIn Time</label>
              <TimePicker
                use12Hours
                value={formik?.values?.dineTime ? dayjs(formik?.values?.dineTime, 'h:mm A') : null}
                format='h:mm A'
                allowClear={false}
                needConfirm={false}
                onChange={onChange}
                className='form-control form-control-lg form-control-solid'
              />
              {errorMessage && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorMessage}</span>
                  </div>
                </div>
              )}

              {formik.touched.dineTime && formik.errors.dineTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.dineTime as string}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row row mb-7 date-width'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Bids End At</label>
              <TimePicker
                use12Hours
                value={
                  formik?.values?.bidsEndAt ? dayjs(formik?.values?.bidsEndAt, 'h:mm A') : null
                }
                format='h:mm A'
                allowClear={false}
                needConfirm={false}
                onChange={(time, timeString: any) => {
                  if (time) {
                    formik.setFieldValue('bidsEndAt', timeString);
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />

              {formik.touched.bidsEndAt && formik.errors.bidsEndAt && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bidsEndAt as string}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Auction Type</label>
              <AuctionTypeSelection
                onblur={() => {
                  formik.setFieldTouched('auctionType', true);
                }}
                as='select'
                {...formik.getFieldProps('auctionType')}
                onChange={(value) => {
                  formik.setFieldValue('auctionType', value.value);
                }}
                name='auctionType'
                value={formik.getFieldProps('auctionType').value}
              />
              {formik.touched.auctionType && formik.errors.auctionType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.auctionType as string}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={handleCancelCallback}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting}
              // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              {!formik.isSubmitting && 'Submit'}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* end::Actions */}
      </form>
    </>
  );
};

export default AuctionBody;
