import React, {useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {useAuth} from '../../modules/auth';

const StripeConnect = () => {
  const [loading, setLoading] = useState(false);
  const {currentUser, logout} = useAuth();

  const stripeConnectHandler = async () => {
    const payload = {
      userId: currentUser?.id,
      email: currentUser?.email,
      platform: 'web_app',
    };
    const headers = {
      Authorization: `Bearer ${currentUser?.token}`,
    };
    setLoading(true);
    try {
      const BASE_URL = process.env.REACT_APP_API_URL;
      const result = await axios.post(`${BASE_URL}/payment/stripeConnect`, payload, {headers});
      if (result.status === 201) {
        window.location.href = result.data.data.url;
        // logout();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!');
    } finally {
      // setLoading(false);
    }
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body d-flex flex-column align-items-center justify-content-center text-center'>
          <h3>Please complete the following steps to set up your Stripe account.</h3>
          {loading ? (
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <div className='mt-6 d-flex flex-column align-items-center justify-content-center'>
              <button
                className='btn btn-primary mb-2'
                onClick={stripeConnectHandler}
                disabled={loading}
              >
                {loading ? 'Connecting...' : 'Connect Stripe'}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StripeConnect;
