import {useFormik} from 'formik';
import * as Yup from 'yup';

type AuctionTypeFormValues = {
  type: string;
};

const initialAuctionTypeValues: AuctionTypeFormValues = {
  type: '',
};

const auctionTypeSchema = Yup.object().shape({
  type: Yup.string()
    .min(3, 'Auction type should have at least 3 characters')
    .max(16, 'Auction type should have at most 16 characters')
    .required('Auction type is required'),
});

const AuctionTypeBody = ({
  auctionData, // Added to receive auction data as props
  handleSaveCallback,
  handleCancelCallback,
}: {
  auctionData?: AuctionTypeFormValues;
  handleSaveCallback: (value: AuctionTypeFormValues) => void;
  handleCancelCallback: () => void;
}) => {
  const formik = useFormik({
    initialValues: auctionData || initialAuctionTypeValues,
    validationSchema: auctionTypeSchema,
    enableReinitialize: true, // To allow reinitializing the form when auctionData changes
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      await handleSaveCallback(values);
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form
        id='kt_modal_add_auction_type_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_auction_type_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_auction_type_header'
          data-kt-scroll-wrappers='#kt_modal_add_auction_type_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* Auction Type */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Auction Type</label>
            <input
              placeholder='Auction Type'
              {...formik.getFieldProps('type')}
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              maxLength={20}
              onInput={(e: any) => {
                e.target.value = e.target.value.toUpperCase();
                formik.setFieldValue('type', e.target.value);
              }}
              disabled={formik.isSubmitting}
            />
            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.type}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={handleCancelCallback}
            className='btn btn-light me-3'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!formik.isSubmitting && 'Submit'}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AuctionTypeBody;
