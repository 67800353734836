import {FC, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import {useThemeMode} from '../../_metronic/partials';
import {
  AUCTION_DETAILS_ROUTE,
  AUCTION_ROUTE,
  AUCTION_TYPE_ROUTE,
  BIDDING_ROUTE,
  BOOKING_ROUTE,
  CONTACT_ROUTE,
  DASHBOARD_ROUTE,
  MANAGE_HOST,
  PROFILE_ROUTE,
  REVIEWS_ROUTE,
  STRIPE_CONNECT,
  TABLES_ROUTE,
} from '../Helpers';
import AuctionDetails from '../components/common/DataTableViews/Auction/AuctionDetails';
import AuctionForRestaurant from '../pages/Restaurant/Auction';
import RestaurantBidding from '../pages/Restaurant/Bidding';
import {RestaurantBooking} from '../pages/Restaurant/Booking';
import Contact from '../pages/Restaurant/Contact';
import {DashboardForRestaurant} from '../pages/Restaurant/Dashboard';
import {ProfileForRestaurant} from '../pages/Restaurant/Profile';
import Reviews from '../pages/Restaurant/Reviews';
import {Tables} from '../pages/Restaurant/Tables';
import {AuctionType} from '../pages/Restaurant/AuctionType';
import StripeConnect from '../pages/Restaurant/StripeConnect';
import ManageHost from '../pages/Restaurant/ManageHost';

const RestaurantRoutes = () => {
  const {mode} = useThemeMode();
  return (
    <div className={`${mode === 'dark' ? 'dark-mode-theme' : 'light-mode-theme'}`}>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to={DASHBOARD_ROUTE} />} />
          <Route
            path={DASHBOARD_ROUTE}
            element={
              <SuspendedView>
                <DashboardForRestaurant />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_ROUTE}
            element={
              <SuspendedView>
                <AuctionForRestaurant />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_TYPE_ROUTE}
            element={
              <SuspendedView>
                <AuctionType />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <AuctionDetails />
              </SuspendedView>
            }
          />
          <Route
            path={PROFILE_ROUTE}
            element={
              <SuspendedView>
                <ProfileForRestaurant />
              </SuspendedView>
            }
          />
          <Route
            path={TABLES_ROUTE}
            element={
              <SuspendedView>
                <Tables />
              </SuspendedView>
            }
          />
          <Route
            path={REVIEWS_ROUTE}
            element={
              <SuspendedView>
                <Reviews />
              </SuspendedView>
            }
          />
          <Route
            path={BOOKING_ROUTE}
            element={
              <SuspendedView>
                <RestaurantBooking />
              </SuspendedView>
            }
          />
          <Route
            path={BIDDING_ROUTE}
            element={
              <SuspendedView>
                <RestaurantBidding />
              </SuspendedView>
            }
          />
          <Route
            path={CONTACT_ROUTE}
            element={
              <SuspendedView>
                <Contact />
              </SuspendedView>
            }
          />
          <Route
            path={STRIPE_CONNECT}
            element={
              <SuspendedView>
                <StripeConnect />
              </SuspendedView>
            }
          />

          <Route
            path={MANAGE_HOST}
            element={
              <SuspendedView>
                <ManageHost />
              </SuspendedView>
            }
          />

          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </div>
  );
};

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {RestaurantRoutes};
