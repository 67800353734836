import React, {useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {PageTitle} from '../../../../_metronic/layout/core';
import {useAuth} from '../core/Auth';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../Helpers';

const AdminContactUsSchema = Yup.object().shape({
  name: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email Address is required'),
  message: Yup.string().required('Your Message is required'),
});

export default function AdminContactUs() {
  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const [loading, setLoading] = useState(false);
  const {contactModule} = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: AdminContactUsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await contactModule?.addContact(values);
      setLoading(false);
      if (result?.success) {
        toast.success('Your query sent Succesfully', {
          toastId: TOAST_GENERAL,
        });
        formik.setFieldTouched('query', false, true);
        formik.resetForm();
      } else {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    },
  });

  return (
    <div className='bg-white p-4 rounded'>
      <PageTitle>Contact</PageTitle>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-2'>Contact Us</h1>
      </div>
      <form className='form w-100 w-lg-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
          <div>
            <input
              type='text'
              placeholder='Enter your full name'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('name')}
              maxLength={50}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </div>
        </div>

        <div>
          <label className='col-lg-4 col-form-label  required fw-bold fs-6'>Email Address</label>
          <div>
            <input
              type='email'
              placeholder='Enter your Email'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Your Message</label>
          <div>
            <textarea
              placeholder='Enter Your Message...'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('message')}
              maxLength={3000}
            />
            {formik.touched.message && formik.errors.message && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.message}</div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-center py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting ...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
