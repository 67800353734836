import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core';
import { BookingDataTable } from '../../../../components/common/DataTableViews/Booking';

function SingleRestaurantBookings() {
  const restaurantId = useParams().restaurantId;
  const [userName, setUserName] = useState('');
  const location = useLocation().pathname.split('/')[1];
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Bookings',
      path: `${location}/${restaurantId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: userName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{userName}</PageTitle>
      <BookingDataTable
        restaurantId={restaurantId}
        showRestaurantFilter={false}
        setUserName={setUserName}
      />
      ;
    </>
  );
}

export default SingleRestaurantBookings;
