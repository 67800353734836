import {useReducer, Reducer, Dispatch, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type UseSessionReducerHook<S, A, B> = [S, Dispatch<A>];

export const useSessionReducer = function <S, A, B>(
  reducer: Reducer<S, A>,
  initialValue: S,
  key: string
): UseSessionReducerHook<S, A, B> {
  const {pathname} = useLocation();
  const storedRoutesString = sessionStorage.getItem('storedRoutes');
  let storedRoutes = storedRoutesString ? JSON.parse(storedRoutesString) : [];
  const initialValueJSON = sessionStorage.getItem(key);
  if (
    (storedRoutes[1] === pathname &&
      storedRoutes[2]?.includes(storedRoutes[1]) &&
      storedRoutes[2] !== storedRoutes[1] &&
      initialValueJSON) ||
    (pathname.includes('bidding') &&
      storedRoutes[1] === pathname &&
      storedRoutes[2]?.includes('auction') &&
      storedRoutes[2] !== storedRoutes[1] &&
      initialValueJSON)
  ) {
    initialValue = JSON.parse(initialValueJSON);
  }
  const [value, dispatch] = useReducer(reducer, initialValue);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify({...value, data: []}));
  }, [value]);

  return [value, dispatch];
};
