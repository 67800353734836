import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core';
import AuctionWrapper from '../../../../components/common/AuctionWrapper';

function SingleRestaurantAuctions() {
  const restaurantId = useParams().restaurantId;
  const [restaurantName, setRestaurantName] = useState('');
  const location = useLocation().pathname.split('/')[1];
  const auctionDetailsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Auctions',
      path: `${location}/${restaurantId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: restaurantName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={auctionDetailsBreadcrumbs}>{restaurantName}</PageTitle>
      <AuctionWrapper restaurantId={restaurantId} setRestaurantName={setRestaurantName} />
    </>
  );
}

export default SingleRestaurantAuctions;
