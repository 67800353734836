import {useEffect, useMemo} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../../_metronic/layout/core';
import {useThemeMode} from '../../../../_metronic/partials';
import DataTableWrapper from '../../../DataTable';
import {
  API_ERROR_MESSAGE,
  RESTAURANTS_ROUTE,
  TABLES_ROUTE,
  TOAST_GENERAL,
  convertUtcToLocal,
  debounce,
} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import StatusSelectBox from '../../../components/Sections/Admin/StatusSelectBox';
import {CommonRowInTable, CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import {useAuth} from '../../../modules/auth';
import reducerFunction from './actions';
import {TInitialState} from './index.d';

const initialState: TInitialState = {
  loading: true,
  data: [],
  currentPage: 1,
  totalData: -1,
  pageSize: 10,
  selectedRow: null,
  totalPages: 1,
  error: null,
  search: '',
  isModalOpen: false,
  modalType: 'RESTAURANT',
  statusFilter: '',
  verifiedFilter: '',
  startDate: '',
  endDate: '',
};

const restaurantBreadcrumbs: Array<PageLink> = [
  {
    title: 'Restaurants',
    path: '/restaurants',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];
export default function Restaurant() {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [state, dispatch] = useSessionReducer<any, any, string>(
    reducerFunction,
    initialState,
    'RESTAURANT'
  );
  const {mode} = useThemeMode();
  const {userModule, restaurantModule} = useAuth();

  const columns = [
    {
      name: <CommonViewInHeader name='Name' classes='ms-8' />,
      selector: (row, i) => row.name,
      sortable: true,
      width: '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.name}
            navigateURL={`${RESTAURANTS_ROUTE}/${row.id}`}
            avatar={row.images || 'media/avatars/restaurant-blank.png'}
            classes='text-dark text-capitalize'
            onClick={() => {
              localStorage.setItem('path', `${RESTAURANTS_ROUTE}/${row.id}`);
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Email' />,
      selector: (row, i) => row.user.email,
      width: '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${RESTAURANTS_ROUTE}/${row.id}`}
            text={row?.user?.email}
            classes='text-dark'
            onClick={() => {
              localStorage.setItem('path', `${RESTAURANTS_ROUTE}/${row.id}`);
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='City' />,
      selector: (row, i) => row.city,
      width: '150px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.city}
            navigateURL={`${RESTAURANTS_ROUTE}/${row.id}`}
            classes='text-dark'
            onClick={() => {
              localStorage.setItem('path', `${RESTAURANTS_ROUTE}/${row.id}`);
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Terms Accepted At' />,
      selector: (row, i) => row.termsAcceptedAt,
      width: '220px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.termsAcceptedAt === null ? '-' : convertUtcToLocal(row.termsAcceptedAt)}
            navigateURL={`${RESTAURANTS_ROUTE}/${row.id}`}
            classes='text-dark'
            onClick={() => {
              localStorage.setItem('path', `${RESTAURANTS_ROUTE}/${row.id}`);
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      format: (row, i) => {
        return row?.deletedAt ? (
          <span className='badge capitalize bg-gray-100 px-6 py-3 text-danger'>Deleted</span>
        ) : row?.user?.isVerified ? (
          <StatusSelectBox
            value={row.status}
            onChange={(e) => {
              updateTRestaurant(row.id, {status: e.target.value}, row);
              // e.target.disabled = true;
            }}
          />
        ) : (
          <span className='form-select-type'>Email not verified</span>
        );
      },
      sortable: false,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.email,
      format: (row, i) => {
        return (
          <>
            <Link
              title='View restaurant details'
              className='linkIcon'
              to={`${RESTAURANTS_ROUTE}/${row.id}`}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </Link>
            <Link
              title='Edit restaurant details'
              className={`linkIcon px-3 ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              to={row?.deletedAt ? '' : `${RESTAURANTS_ROUTE}/${row.id}?edit=true`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Restaurant is deleted.');
                }
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </Link>
            <span
              title='Delete restaurant'
              className={`linkIcon ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                localStorage.setItem('path', `${RESTAURANTS_ROUTE}/${row.id}`);
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Restaurant is deleted.');
                  return;
                }
                if (row?.deletable === 'false') {
                  toast('Can’t delete this restaurant as it has ongoing auctions or reservation.');
                } else {
                  dispatch({type: 'SELECT_ROW', payload: row});
                  dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
                }
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
            <Link
              title='Manage tables'
              className='linkIcon px-3'
              to={row?.deletedAt ? '' : `${TABLES_ROUTE}?restaurant=${row.id}`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Restaurant is deleted.');
                }
              }}
            >
              <KTIcon iconName='share' className='fs-1' />
            </Link>
          </>
        );
      },
    },
  ];

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary'
      onClick={() => dispatch({type: 'OPEN_MODAL', payload: 'RESTAURANT'})}
      // onClick={() => navigate('/add-restaurant')}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Restaurant
    </button>
  );

  const getTRestaurants = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await restaurantModule?.getRestaurant({
      pageNo: state.currentPage ? state.currentPage : 1,
      pageSize: state.pageSize,
      statusFilter:
        state.statusFilter === 'EMAIL NOT VERIFIED'
          ? ''
          : state.statusFilter || location?.state?.value,
      searchKeyword: state.search,
      verifiedFilter:
        state.statusFilter === 'EMAIL NOT VERIFIED'
          ? '0'
          : state.statusFilter || location?.state?.value
          ? '1'
          : '',
      startDate: state.startDate,
      endDate: state.endDate,
    });
    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
    }
  };

  useEffect(() => {
    getTRestaurants();
  }, [
    state.currentPage,
    state.pageSize,
    state.search,
    state.statusFilter,
    state.verifiedFilter,
    state.startDate,
    state.endDate,
    location?.state?.value,
  ]);

  const debouncedDispatch = useMemo(() => debounce(dispatch, 300), [dispatch]);

  const handleSearch = (searchVal) => {
    debouncedDispatch({type: 'SEARCH_CHANGE', payload: searchVal});
  };

  const updateTRestaurant = async (restaurantId, restaurantData, row) => {
    if (row?.deletable === 'false') {
      return toast.warn(
        'This action cannot be performed because there are existing bookings or bids.'
      );
    } else {
      const result = await restaurantModule?.updateRestaurant(restaurantId, restaurantData);
      if (result?.success) {
        toast.success(`Restaurant status Updated succesfully`, {
          toastId: TOAST_GENERAL,
        });
        getTRestaurants();
      } else {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };
  const creataTRestaurant = async function (restaurantData) {
    const restaurantCreateData = new FormData();
    restaurantCreateData.append('name', restaurantData.restaurantName);
    restaurantCreateData.append('email', restaurantData.email);
    restaurantCreateData.append('latitude', restaurantData?.lat);
    restaurantCreateData.append('longitude', restaurantData?.long);
    restaurantCreateData.append('street', restaurantData?.street ? restaurantData?.street : null);
    restaurantCreateData.append('city', restaurantData?.city);
    restaurantCreateData.append('state', restaurantData?.state);
    restaurantCreateData.append('country', restaurantData?.country);
    // if (restaurantData?.phoneNumber) {
    //   restaurantCreateData.append('phoneNumber', restaurantData.phoneNumber);
    // }
    if (restaurantData.restaurantImg)
      restaurantCreateData.append('image', restaurantData.restaurantImg);
    const result = await restaurantModule?.addRestaurant(restaurantCreateData);
    if (result?.success) {
      toast.success(
        `${restaurantData.restaurantName} Restaurant Created succesfully, Credentials are sent to email`,
        {
          toastId: TOAST_GENERAL,
        }
      );
      getTRestaurants();
      dispatch({type: 'CLOSE_MODAL'});
      return true;
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const deleteTRestaurant = async (restaurantIds) => {
    const result = await restaurantModule?.deleteRestaurants(restaurantIds);

    if (result?.success) {
      toast.success(`Restaurant Deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      getTRestaurants();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  return (
    <>
      <PageTitle> Restaurants List</PageTitle>
      <DataTableWrapper
        totalData={state.totalData}
        subHeader={subHeader}
        selectableRows={true}
        loading={state.loading}
        columns={columns}
        handleSelected={(selected) => {
          dispatch({type: 'SELECT_ROW', payload: selected});
          dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
        }}
        data={state.data}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [
            {
              label: 'Status',
              options: ['Active', 'Inactive', 'Email not verified', 'Deleted'].map((t) => ({
                value: t.toUpperCase(),
                label: t,
              })),
              selected: location?.state,
            },
          ],
          preserveType: 'RESTAURANT',
        }}
        filtersCallback={(values) => {
          dispatch({
            type: 'SET_FILTER',
            payload: {
              statusFilter: values?.Status ? values.Status.value : '',
              verifiedFilter: values['Email verified'] ? values['Email verified'].value : '',
            },
          });
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: values.startDate, endDate: values.endDate},
          });
        }}
        isFilterApplied={Boolean(state.search || state.statusFilter || state.verifiedFilter)}
      />
      {state.isModalOpen && (
        <CustomModal
          title='Add Restaurant'
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={(value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state.selectedRow.id];
              deleteTRestaurant({ids: ids});
              dispatch({type: 'CLOSE_MODAL'});
            } else {
              return creataTRestaurant(value);
            }
          }}
          type={state.modalType}
        />
      )}
    </>
  );
}
