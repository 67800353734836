import {useState} from 'react';
import AsyncSelect from 'react-select/async';
import {useAuth} from '../../../../modules/auth';
import {useParams} from 'react-router-dom';

function TableSelection({value, onChange, name, as, onblur, ...otherProps}) {
  const {auctionModule, currentUser} = useAuth();
  const [tableData, setTableData] = useState([]);
  const theme = localStorage.getItem('kt_theme_mode_menu') || 'light';
  const params: any = useParams();

  const isAdmin = currentUser?.role === 'ADMIN';
  const getTAllTables = async function () {
    const myData = isAdmin
      ? await auctionModule?.getAllTablesAdmin(params?.restaurantId)
      : await auctionModule?.getAllTables();
    const options = myData?.data?.rows?.map((t) => ({
      value: t.id,
      label: `${t.capacity} Persons`,
    }));
    setTableData(options);
    return options;
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: 'white', // Text color of the control
      backgroundColor: '#1b1b29', // Background color of the control
      borderColor: 'transparent',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'white', // Text color of the options
      backgroundColor: state.isFocused || state.isSelected ? '#1b1b29' : 'transparent', // Background color of the options
      '&:hover': {
        backgroundColor: '#2b2b40', // Background color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white', // Text color of the selected option
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1b1b29',
    }),
  };
  return (
    <AsyncSelect
      {...otherProps}
      onBlur={onblur}
      name={name}
      loadOptions={getTAllTables}
      defaultOptions
      onChange={onChange}
      value={tableData.find((singleTableData: any) => singleTableData?.value === value)}
      styles={theme === 'dark' ? customStyles : {}}
    />
  );
}

export default TableSelection;
