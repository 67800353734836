import {useEffect, useRef} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {CommonRowInTable, CommonViewInHeader} from '..';
import {KTIcon} from '../../../../../_metronic/helpers';
import {useThemeMode} from '../../../../../_metronic/partials';
import DataTableWrapper from '../../../../DataTable';
import {
  API_ERROR_MESSAGE,
  AUCTION_ROUTE,
  TOAST_GENERAL,
  convertUtcToLocal,
} from '../../../../Helpers';
import {useSessionReducer} from '../../../../hook/useSessionReducer';
import {useAuth} from '../../../../modules/auth';
import reducerFunc, {TInitialState} from './action';

const initialState: TInitialState = {
  loading: true,
  data: [],
  isModalOpen: false,
  currentPage: 1,
  totalData: -1,
  totalPages: 1,
  pageSize: 10,
  restaurantFilter: '',
  error: null,
  capacity: '',
  search: '',
  startDate: '',
  endDate: '',
  userFilter: '',
};

interface IParams {
  showUserFilter?: boolean;
  showRestaurantFilter?: boolean;
  userId?: string;
  restautantId?: string;
  setUserName?: any;
  filterType?: string;
}
function BiddingDataTable({
  showUserFilter = true,
  userId = '',
  restautantId = '',
  showRestaurantFilter = true,
  setUserName,
  filterType,
}: IParams) {
  const param: any = useParams();
  const [state, dispatch] = useSessionReducer<any, any, string>(
    reducerFunc,
    initialState,
    param.auctionId ? 'BIDDING2' : 'BIDDING'
  );

  const {currentUser, biddingModule} = useAuth();
  const {state: locationState}: {state: any} = useLocation();
  const restaurantUserLcoationFilter = useRef({
    user: userId,
    restautant: restautantId,
  });
  const {mode} = useThemeMode();
  const isAdmin = currentUser?.role === 'ADMIN';

  const tableColumn = [
    {
      name: <CommonViewInHeader name='Table' />,
      selector: (row, i) => row?.auction?.table?.capacity,
      sortable: true,
      width: isAdmin ? '140px' : '180px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auction?.table?.capacity + ' Persons'}
            classes={`${
              currentUser?.role === 'ADMIN' ? 'text-theme-color text-dark' : 'text-dark'
            }`}
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
    },
  ];
  const mainColumn = [
    {
      name: <CommonViewInHeader name='User Name' />,
      selector: (row, i) => row?.user?.firstName,
      width: isAdmin ? '200px' : '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.user?.firstName + ' ' + row?.user?.lastName}
            classes='text-dark text-capitalize'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Bid Price' />,
      selector: (row, i) => parseFloat(row.amount),
      width: isAdmin ? '140px' : '190px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={'$ ' + row.amount}
            classes='text-theme-color text-dark'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Mobile Number' />,
      selector: (row, i) => row?.user?.mobileNumber,
      minWidth: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
          text={row?.user?.mobileNumber !== null
            ? `${row?.user?.mobileCountryCode.startsWith('+') ? '' : '+'}${row?.user?.mobileCountryCode}${
                row?.user?.mobileNumber
              }`
            : '-'}            classes='text-theme-color text-dark'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row?.updated_at,
      width: isAdmin ? '265px' : '290px',
      // width: isAdmin ? '140px' : '170px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row?.updated_at)}
            classes='text-uppercase text-dark'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
      sortable: true,
    },
  ];

  const navigate = useNavigate();

  const actionColumn = [
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.email,
      width: isAdmin ? '140px' : '170px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <Link
              title='View auction details'
              className='linkIcon'
              to={`${AUCTION_ROUTE}/${row.auction?.id}`}
            >
              <div>
                <KTIcon iconName='eye' className='fs-1' />
              </div>
            </Link>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const restaurantColumn = [
    {
      name: <CommonViewInHeader name='Restaurant' />,
      selector: (row, i) => row.auction.restaurants?.name,
      width: '240px',
      sortable: true,
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auction?.restaurants?.name}
            avatar={row?.auction?.restaurants?.images ?? 'media/avatars/restaurant-blank.png'}
            classes='text-dark'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='City' />,
      selector: (row, i) => row.auction.restaurants?.city,
      width: '150px',
      sortable: true,
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auction?.restaurants?.city}
            classes='text-dark'
            navigateURL={`${AUCTION_ROUTE}/${row.auction?.id}`}
          />
        );
      },
    },
  ];

  let columns = mainColumn;
  if (!param.auctionId && currentUser?.role !== 'ADMIN') {
    columns = [...tableColumn, ...columns, ...actionColumn];
  }
  if (currentUser?.role === 'ADMIN' && !param.auctionId) {
    columns = [...restaurantColumn, ...tableColumn, ...columns, ...actionColumn];
  }
  const getTBidding = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await biddingModule?.getAllBiddings({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      restaurantIdFilter: restaurantUserLcoationFilter.current.restautant || state.restaurantFilter,
      userIdFilter: restaurantUserLcoationFilter.current.user || state.userFilter,
      capacity: state.capacity,
      startDate: state.startDate,
      endDate: state.endDate,
      auctionIdFilter: param.auctionId,
    });

    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };
  useEffect(() => {
    getTBidding();
  }, [
    state.currentPage,
    state.pageSize,
    state.search,
    state.restaurantFilter,
    state.userFilter,
    state.capacity,
    state.startDate,
    state.endDate,
  ]);

  const pathname = useLocation();
  const path = pathname.pathname;
  const isBidding = path.includes('/auction');

  return (
    <>
      {isBidding ? (
        <DataTableWrapper
          loading={state.loading}
          totalData={state.totalData}
          columns={columns}
          data={state.data}
          currentPage={state.currentPage}
          totalPages={state.totalPages}
          pageSize={state.pageSize}
          handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
          handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
          handleSearch={(searchVal) => {
            dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
          }}
          searchValue={state.search}
          filtersCallback={(value) => {
            dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
            dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
            dispatch({type: 'SET_USER', payload: value.userFilter?.value});
            dispatch({
              type: 'DATE_FILTER',
              payload: {startDate: value.startDate, endDate: value.endDate},
            });
          }}
          isFilterApplied={Boolean(
            state.capacity ||
              state.userFilter ||
              state.search ||
              state.restaurantFilter ||
              (state.startDate && state.endDate)
          )}
          filterType={filterType}
        />
      ) : (
        <DataTableWrapper
          loading={state.loading}
          totalData={state.totalData}
          columns={columns}
          data={state.data}
          currentPage={state.currentPage}
          totalPages={state.totalPages}
          pageSize={state.pageSize}
          handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
          handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
          handleSearch={(searchVal) => {
            dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
          }}
          searchValue={state.search}
          filters={{
            date: true,
            userFilter: showUserFilter,
            restaurantFilter: isAdmin && showRestaurantFilter,
            capacity: true,
            preserveType: param.auctionId ? 'BIDDING2' : 'BIDDING',
          }}
          filtersCallback={(value) => {
            dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
            dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
            dispatch({type: 'SET_USER', payload: value.userFilter?.value});
            dispatch({
              type: 'DATE_FILTER',
              payload: {startDate: value.startDate, endDate: value.endDate},
            });
          }}
          isFilterApplied={Boolean(
            state.capacity ||
              state.userFilter ||
              state.search ||
              state.restaurantFilter ||
              (state.startDate && state.endDate)
          )}
          filterType={filterType}
        />
      )}
    </>
  );
}
export default BiddingDataTable;
