/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {CustomModal} from '../../../components/Modal';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(5, 'Email should be of minimum 5 chars long')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
    // .max(40, 'Email should be of maximum 40 chars long')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password should be of minimum 8 chars long')
    .max(16, 'Password should be of maximum 16 chars long')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {saveAuth, setCurrentUser, authModule} = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const [title, setTitle] = useState('');
  const [inActiveMessage, setInActiveMessage] = useState('');
  const [statusCode, setStatusCode]: any = useState('');
  const [message, setMessage] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [stripeModalView, setStripeModalView] = useState(false);

  useEffect(() => {
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      const filteredParams = {};
      params.forEach((value, key) => {
        if (key === 'message') {
          setMessage(value);
        } else if (key === 'authKey') {
          setAuthKey(value);
        } else {
          setStatusCode(value);
        }
      });
      return filteredParams;
    };
    getQueryParams();
  }, []);

  useEffect(() => {
    if (message) {
      setStripeModalView(true);
      if (statusCode === '200') {
        toast.success(message);
      } else {
        toast.error(message);
      }
      window.history.replaceState({}, document.title, window.location.pathname);
      // setTimeout(() => {
      //   setMessage('');
      //   setStatusCode('');
      // }, 2000);
    }
  }, [message, statusCode]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true);
      try {
        const result = await authModule?.login(values.email, values.password);
        const currentUser = {
          ...result?.data,
          stripeStatus: result?.status || 0,
          isStripeConnect: result?.data?.isStripeConnect,
        };
        localStorage?.setItem('loginStatus', result?.status);
        if (result?.status === 600) {
          setShowModal(true);
          setLoading(false);
          setTitle(result?.message);
          return;
        }

        if (result?.status === 601) {
          setShowModal(true);
          setLoading(false);
          setTitle(result?.message);
          return;
        }

        if (result?.success) {
          saveAuth(result.data, result.data);
          setCurrentUser(currentUser);
        } else {
          if (result?.status === 255) {
            navigate('/stripe-onboard');
            saveAuth(result.data, result.data);
            setCurrentUser(currentUser);
          }
          toast(result?.message);
          saveAuth(undefined, undefined);
          setSubmitting(false);
          setLoading(false);
        }
      } catch (error: any) {
        console.log(error.response);
      }
    },
  });

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            placeholder='password'
            type={showPassword.password ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />{' '}
          {showPassword?.password ? (
            <span
              className={clsx(
                {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
                {
                  'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
                },
                'password-toggle-icon'
              )}
              onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
            >
              <KTIcon iconName='eye' className='fs-2' />
            </span>
          ) : (
            <span
              className={clsx(
                {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
                {
                  'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
                },
                'password-toggle-icon'
              )}
              onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
            >
              <KTIcon iconName='eye-slash' className='fs-2' />
            </span>
          )}
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack justify-content-end flex-wrap gap-3 fs-base fw-semibold mb-8'>
          {/* <div /> */}

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        {/* begin::Privacy Policy and Terms */}
        <div className='text-center'>
          <Link to='/privacy-policy' className='link-primary fw-bold ms-2 fs-6'>
            Privacy Policy
          </Link>
          <span className='text-muted mx-2'> | </span>
          <Link to='/terms-and-conditions' className='link-primary fw-bold ms-2 fs-6'>
            Terms and Conditions
          </Link>
        </div>
        {/* end::Privacy Policy and Terms */}
      </form>
      {showModal && (
        <CustomModal
          title=''
          data=''
          handleCancelCallback={() => setShowModal(false)}
          handleSaveCallback={() => {
            navigate('contact-us');
          }}
          inActiveTitle={title ? title : inActiveMessage}
          type='INACTIVE_MESSAGE'
        />
      )}
      {/* {stripeModalView && (
        <CustomModal
          title=''
          data=''
          handleCancelCallback={() => {
            setStripeModalView(false);
            setMessage('');
            setStatusCode('');
          }}
          type='STRIPE_ONBOARD'
          stripeTitle={message}
          handleSaveCallback={() => {
            setMessage('');
            setStatusCode('');
          }}
        />
      )} */}
    </>
  );
}
