import {PageTitle} from '../../../_metronic/layout/core';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import {SelectPreferences} from '../../components/common/SelectPreferences';
import {useAuth} from '../../modules/auth';
import {
  API_ERROR_MESSAGE,
  EDIT_ERROR,
  EDIT_ERROR_IMG,
  EMAIL_NOT_EDITABLE,
  TOAST_GENERAL,
  TOAST_PROFILE,
} from '../../Helpers';
import {toast} from 'react-toastify';
import {useSessionReducer} from '../../hook/useSessionReducer';
import reducerFunction from '../Admin/Restautant/actions';
import {CustomModal} from '../../components/Modal';
import {useThemeMode} from '../../../_metronic/partials';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {Autocomplete, LoadScript} from '@react-google-maps/api';
import {Navigate, useNavigate} from 'react-router-dom';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const countries = defaultCountries.filter((country) => {
  const {iso2} = parseCountry(country);
  return [
    'us',
    'am',
    'au',
    'ua',
    'gb',
    'in',
    'fr',
    'tr',
    'by',
    'ca',
    'ie',
    'is',
    'ht',
    'fi',
    'ar',
    'uk',
  ].includes(iso2);
});

export interface ICUISINE {
  value: string;
  label: string;
}
export interface IProfileDetails {
  name: string;
  avatar: string;
  description: string;
  location: string;
  city: string;
  cuisines: ICUISINE[];
  email: string;
  password: string;
  images: string;
  imageFile: undefined | File | null;
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassWord?: string;
  phoneNumber: string;
  address: {
    street: any;
    city: string;
    state: string;
    country: string;
    coordinates: {
      lat: string;
      long: string;
    };
  };
}

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters')
    .max(500, 'Description must not exceed 500 characters'),
  // location: Yup.string().required('Location is required'),
  // city: Yup.string().required('City name is required'),
  email: Yup.string().required('Email is required'),
  currentPassword: Yup.string(),
  newPassword: Yup.string()
    .test('isRequired', 'New password is required', function (value) {
      const currentPassword = this.parent.currentPassword;

      if (currentPassword && currentPassword.length > 0) {
        return !!value;
      }
      return true;
    })
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  confirmNewPassWord: Yup.string().test('newPassword', 'Passwords must match', function (value) {
    return this.parent.newPassword === value;
  }),
  imageFile: Yup.mixed()
    .test('fileSize', 'File size is too large (max 4MB)', (value) => {
      if (!value) {
        // Handle case where no file is selected
        return true;
      }
      const file = value as File; // Type assertion
      return file.size <= 4 * 1024 * 1024; // 4MB in bytes
    })
    .test('fileType', 'Invalid file type', (value) => {
      if (!value) {
        // Handle case where no file is selected
        return true;
      }
      const file = value as File; // Type assertion
      return ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type);
    }),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('isValidPhoneNumber', 'Phone number is not valid', function (value) {
      return isPhoneValid(value as string);
    }),
  address: Yup.object().shape({
    city: Yup.string().required('Please enter complete address'),
    state: Yup.string().required('Please enter complete address'),
    country: Yup.string().required('Please enter complete address'),
  }),
});

const initialState = {
  loading: true,
  isModalOpen: false,
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    coordinates: {
      lat: 0,
      long: 0,
    },
  },
};

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

export const ProfileForRestaurant = () => {
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIssEditMode] = useState(false);
  const {restaurantModule, authModule, setCurrentUser} = useAuth();
  const [restaurantId, setRestaurantId] = useState();
  const [showPassword, setShowPassword] = useState({
    currentPassword: true,
    newPassword: true,
    confirmNewPassword: true,
  });
  const {mode} = useThemeMode();
  const [formData, setFormData] = useState({});
  const {logout, currentUser} = useAuth();
  const [addressError, setAddressError] = useState('');
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [inputLocation, setInputLocation] = useState('');
  const [location, setLocation] = useState<any>({
    coordinates: {lat: '0', long: '0'},
    address: {
      lane: '',
      city: '',
      state: '',
      pinCode: '',
      country: '',
    },
    place_id: '',
  });
  const [val, setValue] = useState('');

  const [state, dispatch] = useSessionReducer<any, any, string>(
    reducerFunction,
    initialState,
    'RESTAURANT'
  );
  const initialValues: IProfileDetails = {
    name: '',
    description: '',
    location: '',
    city: '',
    avatar: '/media/avatars/300-1.jpg',
    email: '',
    password: '',
    images: '',
    cuisines: [],
    currentPassword: '',
    newPassword: '',
    confirmNewPassWord: '',
    imageFile: undefined,
    phoneNumber: '',
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      coordinates: {lat: '0', long: '0'},
    },
  };

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (value) => {
      if (err) {
        setAddressError('Location is required.');
        formik.setFieldError('address.city', 'Location is required.');
        return;
      }

      if (val.length > 2) {
        setAddressError(
          'No match found for the entered address. Please select from the dropdown or revise your input.'
        );
        return;
      }

      if (
        !formik?.values?.address?.city ||
        !formik?.values?.address?.state ||
        !formik?.values?.address?.country
      ) {
        setAddressError('Please enter complete address');
        formik.setFieldError('address.city', 'Please enter complete address');
        formik.setFieldError('address.state', 'Please enter complete address');
        formik.setFieldError('address.country', 'Please enter complete address');
      }
      setLoading(true);
      if (value?.newPassword) {
        // Update the password if a new password is provided
        const passwordResult = await authModule?.updatePassword({
          oldPassword: value?.currentPassword,
          newPassword: value?.newPassword,
        });

        // Handle the result of updating the password
        if (passwordResult?.success) {
          toast.success(`Profile Updated successfully`, {toastId: TOAST_PROFILE});
        } else {
          toast.error(passwordResult?.message || `Problem occurred while updating password`, {
            toastId: TOAST_PROFILE,
          });
        }
      }
      const restaurantData = new FormData();
      restaurantData.append('name', value.name);
      restaurantData.append('description', value.description);
      restaurantData.append('street', value?.address?.street ? value?.address?.street : null);
      restaurantData.append('city', value.address.city);
      restaurantData.append('state', value.address.state);
      restaurantData.append('country', value.address.country);
      restaurantData.append('phoneNumber', value?.phoneNumber);
      restaurantData.append('latitude', value?.address?.coordinates?.lat);
      restaurantData.append('longitude', value?.address?.coordinates?.long);
      let arr: any = [];
      if (value?.cuisines?.length > 0) {
        value.cuisines.forEach((cuisine: any) => {
          arr.push(cuisine?.value);
        });
      }
      restaurantData.append('cuisine', JSON.stringify(arr));
      if (value.imageFile) restaurantData.append('image', value.imageFile);
      if (value.imageFile === null) restaurantData.append('images', '');

      await updateTRestaurant(restaurantId, restaurantData);
      setLoading(false);
      setIssEditMode(false);
    },
  });

  const updateTRestaurant = async (restaurantId, restaurantData) => {
    const result = await restaurantModule?.updateRestaurant(restaurantId, restaurantData);
    if (result?.success) {
      toast.success(`Restaurant Info Updated succesfully`, {toastId: TOAST_PROFILE});
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_PROFILE,
      });
    }
  };

  const getTSingleRestaurant = async function () {
    const result = await restaurantModule?.getRestaurantProfile();
    if (result?.success) {
      setCurrentUser((currentUser) => ({
        ...currentUser,
        firstName: result?.data?.data?.restaurant?.name,
        email: result?.data?.data?.email,
        profilePicture: result?.data?.data?.restaurant?.images,
      }));
      let locValue = {
        street: result?.data?.data?.restaurant?.street,
        city: result?.data?.data?.restaurant?.city,
        state: result?.data?.data?.restaurant?.state,
        country: result?.data?.data?.restaurant?.country,
        coordinates: {
          lat: result?.data?.data?.restaurant?.latitude,
          long: result?.data?.data?.restaurant?.longitude,
        },
      };

      if (locValue) {
        formik.setValues({
          ...initialValues,
          email: result?.data?.data?.email,
          name: result?.data?.data?.restaurant?.name,
          description: result?.data?.data?.restaurant?.description,
          // location: result?.data?.data?.restaurant?.location,
          phoneNumber: result?.data?.data?.restaurant?.phoneNumber || '',
          city: result?.data?.data?.restaurant?.city,
          cuisines: result?.data?.data?.restaurant?.cuisine?.map((cuisine) => ({
            value: cuisine.id,
            label: cuisine.name,
          })),
          images: result?.data?.data?.restaurant?.images,
          imageFile: undefined,
          address: {
            ...formik.values.address,
            street: locValue?.street,
            city: locValue?.city,
            state: locValue?.state,
            country: locValue?.country,
            coordinates: {
              lat: locValue?.coordinates?.lat,
              long: locValue?.coordinates?.long,
            },
          },
        });
      }

      setRestaurantId(result?.data?.data?.restaurant?.id);
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const deleteTRestaurant = async (restaurantIds) => {
    const result = await restaurantModule?.deleteSingleRestaurants(
      restaurantIds,
      currentUser?.token
    );
    if (result?.success) {
      toast.success(result?.message || `Restaurant Deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      logout();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  useEffect(() => {
    getTSingleRestaurant();
  }, [isEditMode]);

  const onPlaceChanged = () => {
    setValue('');
    setErr(false);

    if (autoComplete !== null) {
      const place = autoComplete?.getPlace();
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place);
        const {city, state, country} = address;

        formik.setValues({
          ...formik.values,
          address: {
            ...formik.values.address,
            street: lane,
            city,
            state,
            country,
            coordinates: {
              lat: place?.geometry.location.lat().toString(),
              long: place?.geometry.location.lng().toString(),
            },
          },
        });
        if (!city || !state || !country) {
          setAddressError('Please enter complete address');

          formik.setFieldError('address.city', 'Please enter complete address');
          formik.setFieldError('address.state', 'Please enter complete address');
          formik.setFieldError('address.country', 'Please enter complete address');
        } else {
          formik.setFieldError('address.city', '');
          formik.setFieldError('address.state', '');
          formik.setFieldError('address.country', '');
        }
      }
    }
  };

  const getAddressComponents = (place) => {
    const address: any = {};
    let lane = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'locality':
          address.city = component.long_name;
          break;

        case 'administrative_area_level_1':
          address.state = component.long_name;
          break;

        case 'country':
          address.country = component.long_name;
          break;

        case 'neighborhood':
        case 'premise':
        case 'sublocality':
        case 'route':
          lane += component.long_name + ', ';
          break;
        default:
          break;
      }
    }
    lane = lane.slice(0, -2);
    return {address, lane};
  };

  // const img = formik.values.imageFile
  //   ? URL.createObjectURL(formik.values.imageFile)
  //   : formik.values.images || toAbsoluteUrl('/media/avatars/restaurant-blank.png');

  const img = useMemo(
    () =>
      formik.values.imageFile
        ? URL.createObjectURL(formik.values.imageFile)
        : formik.values.images || toAbsoluteUrl('/media/avatars/blank.png'),
    [formik.values.imageFile, formik.values.images]
  );

  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const hasToastBeenShown = localStorage.getItem('toastShown');

  return (
    <>
      {formik.values.address.city !== '' && (
        <>
          <PageTitle breadcrumbs={[]}>Profile</PageTitle>
          <div className='card mb-5 mb-xl-10'>
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              // data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Profile Details</h3>
              </div>
              <div className='card-title m-0'>
                <button
                  type='button'
                  className='btn btn-primary mx-3'
                  onClick={() => {
                    dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
                  }}
                >
                  Delete Account{' '}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={(e) => {
                    setIssEditMode(!isEditMode);
                  }}
                >
                  {isEditMode ? 'Cancel Edit' : 'Edit'}
                </button>
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                    <div className='col-lg-8'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(
                            '/media/avatars/restaurant-blank.png'
                          )})`,
                        }}
                        title={isEditMode ? '' : EDIT_ERROR_IMG}
                      >
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{
                            backgroundImage: `url(${img})`,
                          }}
                        ></div>
                        {isEditMode && (
                          <>
                            <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='change'
                              htmlFor='restaurantImage'
                            >
                              <i className='ki-duotone ki-pencil fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>

                              <input
                                type='file'
                                id='restaurantImage'
                                name='avatar'
                                accept='.png, .jpg, .jpeg, .webp'
                                onChange={(e: any) => {
                                  formik.setFieldValue('imageFile', e.target.files[0]);
                                }}
                              />
                            </label>
                            {(formik.values.imageFile || formik.values.images) && (
                              <span
                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                data-kt-image-input-action='remove'
                                onClick={() => {
                                  formik.setFieldValue('imageFile', null);
                                  formik.setFieldValue('images', '');
                                }}
                              >
                                <i className='ki-duotone ki-cross fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {formik.errors.imageFile && isEditMode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.imageFile}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Company name'
                        {...formik.getFieldProps('name')}
                        disabled={!isEditMode}
                        maxLength={50}
                        title={isEditMode ? '' : EDIT_ERROR}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Description
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Description'
                        {...formik.getFieldProps('description')}
                        maxLength={500}
                        disabled={!isEditMode}
                        title={isEditMode ? '' : EDIT_ERROR}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.description}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Cuisines</label>
                    <div className='col-lg-8 fv-row'>
                      <SelectPreferences
                        isDisabled={!isEditMode}
                        onChange={(values) => {
                          formik.setFieldValue('cuisines', values);
                        }}
                        value={formik.getFieldProps('cuisines').value}
                        title={isEditMode ? '' : EDIT_ERROR}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Location
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
                        <Autocomplete
                          onLoad={(autocomplete: any) => {
                            setAutoComplete(autocomplete);
                          }}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            placeholder='Location'
                            defaultValue={`${
                              formik?.values?.address?.street === null ||
                              formik?.values?.address?.street === 'null'
                                ? ''
                                : formik?.values?.address?.street &&
                                  formik?.values?.address?.street + ','
                            }  ${
                              formik?.values?.address?.city == null
                                ? ''
                                : formik?.values?.address?.city &&
                                  formik?.values?.address?.city + ','
                            } ${
                              formik?.values?.address?.state == null
                                ? ''
                                : formik?.values?.address?.state &&
                                  formik?.values?.address?.state + ','
                            } ${
                              formik?.values?.address?.country == null
                                ? ''
                                : formik?.values?.address?.country &&
                                  formik?.values?.address?.country
                            }`}
                            className='form-control form-control-lg form-control-solid'
                            type='text'
                            autoComplete='off'
                            onChange={(e) => {
                              setValue(e.target.value);
                              setAddressError('');
                              if (e.target.value.length === 0) {
                                setErr(true);
                              } else {
                                setErr(false);
                              }
                            }}
                            disabled={!isEditMode}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && !formik.isSubmitting) {
                                formik.handleBlur(e);
                                e.preventDefault();
                              }
                            }}
                          />
                        </Autocomplete>
                      </LoadScript>
                      {addressError && (
                        <div className='fv-plugins-message-container '>
                          <div className='fv-help-block'>{addressError}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Location</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Location'
                    {...formik.getFieldProps('location')}
                    disabled={!isEditMode}
                    title={isEditMode ? '' : EDIT_ERROR}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.location}</div>
                    </div>
                  )}
                </div>
              </div> */}

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='email'
                        className='form-control form-control-lg form-control-solid cursor-not-allowed'
                        placeholder='Email'
                        {...formik.getFieldProps('email')}
                        disabled={true}
                        title={EMAIL_NOT_EDITABLE}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Phone Number
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <PhoneInput
                        defaultCountry='us'
                        value={formik.values.phoneNumber}
                        onChange={(phone) => {
                          formik.setFieldValue('phoneNumber', phone);
                          if (!isPhoneValid(phone)) {
                            if (phone.length > 5) formik.setFieldTouched('phoneNumber', true);
                            formik.setFieldError('phoneNumber', 'Phone number is not valid');
                          } else {
                            formik.setFieldError('phoneNumber', '');
                          }
                        }}
                        countries={countries}
                        inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
                        inputProps={{
                          name: 'phoneNumber',
                          autoComplete: 'off',
                          disabled: formik.isSubmitting || !isEditMode,
                        }}
                        disabled={!isEditMode || true}
                      />
                      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.phoneNumber}</span>
                          </div>
                        </div>
                      )}
                      <div className='mt-6 d-flex justify-content-between'>
                        <div
                          className='btn btn-secondary'
                          onClick={() => {
                            dispatch({
                              type: 'OPEN_MODAL',
                              payload: 'RESTAURANT_MOBILE_VERIFICATION',
                            });
                          }}
                        >
                          {hasToastBeenShown ? 'Add Phone Number' : 'Change Phone Number'}
                        </div>

                        <div
                          className='btn btn-secondary'
                          onClick={() => {
                            navigate('/manage-host');
                          }}
                        >
                          Manage Hosts{' '}
                        </div>
                      </div>
                    </div>
                  </div>

                  {isEditMode && (
                    <>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Old Password</label>

                        <div className='col-lg-8 fv-row'>
                          <input
                            type={showPassword?.currentPassword ? 'text' : 'password'}
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Current Password'
                            {...formik.getFieldProps('currentPassword')}
                            disabled={!isEditMode}
                            autoComplete='current-password'
                          />
                          {showPassword?.currentPassword ? (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  currentPassword: !showPassword.currentPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye' className='fs-2' />
                            </span>
                          ) : (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  currentPassword: !showPassword.currentPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye-slash' className='fs-2' />
                            </span>
                          )}
                          {formik.touched.currentPassword && formik.errors.currentPassword && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>New Password</label>

                        <div className='col-lg-8 fv-row'>
                          <input
                            type={showPassword?.newPassword ? 'text' : 'password'}
                            className='form-control form-control-lg form-control-solid'
                            placeholder='New Password'
                            {...formik.getFieldProps('newPassword')}
                            disabled={!isEditMode}
                          />
                          {showPassword?.newPassword ? (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  newPassword: !showPassword.newPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye' className='fs-2' />
                            </span>
                          ) : (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  newPassword: !showPassword.newPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye-slash' className='fs-2' />
                            </span>
                          )}
                          {formik.errors.newPassword && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.newPassword}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          Confirm New Password
                        </label>

                        <div className='col-lg-8 fv-row'>
                          <input
                            type={showPassword?.confirmNewPassword ? 'text' : 'password'}
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Confirm New Password'
                            {...formik.getFieldProps('confirmNewPassWord')}
                            disabled={!isEditMode}
                          />
                          {showPassword?.confirmNewPassword ? (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confirmNewPassword: !showPassword.confirmNewPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye' className='fs-2' />
                            </span>
                          ) : (
                            <span
                              className='password-toggle-icon'
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confirmNewPassword: !showPassword.confirmNewPassword,
                                })
                              }
                            >
                              <KTIcon iconName='eye-slash' className='fs-2' />
                            </span>
                          )}
                          {formik.touched.confirmNewPassWord && formik.errors.confirmNewPassWord && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.confirmNewPassWord}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isEditMode && (
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && 'Save Changes'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          {state.isModalOpen && (
            <CustomModal
              title=''
              data={state.selectedRow}
              handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
              handleSaveCallback={(value) => {
                if (state.modalType === 'DELETE') {
                  deleteTRestaurant({ids: [restaurantId]});
                  dispatch({type: 'CLOSE_MODAL'});
                }
              }}
              type={state.modalType}
            />
          )}
        </>
      )}
    </>
  );
};
