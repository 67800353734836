/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import noUiSlider, {target} from 'nouislider';
import {useLayout} from '../../core';
import {KTIcon, toAbsoluteUrl} from '../../../helpers';
import {DefaultTitle} from './page-title/DefaultTitle';
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials';
import {useAuth} from '../../../../app/modules/auth';
const HeaderToolbar = () => {
  const {classes} = useLayout();
  const [status, setStatus] = useState<string>('1');
  const {currentUser} = useAuth();
  useEffect(() => {
    const slider: target = document.querySelector('#kt_toolbar_slider') as target;
    const rangeSliderValueElement: Element | null = document.querySelector(
      '#kt_toolbar_slider_value'
    );

    if (!slider) {
      return;
    }

    slider.innerHTML = '';

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    });

    slider.noUiSlider?.on('update', function (values: any, handle: any) {
      if (!rangeSliderValueElement) {
        return;
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1);
    });
  }, []);

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-lg-row align-items-lg-stretch justify-content-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Label */}
            {/* end::Label */}

            {/* begin::Actions */}
            <div className='d-flex'>
              {/* begin::Action */}
              {/* <a
                href='#'
                className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_invite_friends'
              >
                <KTIcon iconName='files' className='fs-1' />
              </a> */}
              {/* end::Action */}

              {/* begin::Notifications */}
              <div className='d-flex align-items-center'>
                {/* begin::Menu- wrapper */}
                {/* <a href='#' className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                  <KTIcon iconName='add-files' className='fs-1' />
                </a> */}
                {/* end::Menu wrapper */}
              </div>
              {/* end::Notifications */}

              {/* begin::Quick links */}
              <div className='d-flex align-items-center'>
                {/* begin::Menu wrapper */}
                {/* <a href='#' className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                  <KTIcon iconName='file-up' className='fs-1' />
                </a> */}
                {/* end::Menu wrapper */}
              </div>
              {/* end::Quick links */}

              {/* begin::Theme mode */}
              {/* <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div> */}
              <div className='mx-3 d-flex align-items-center fw-bold fs-4'>
                {currentUser?.firstName && currentUser?.firstName.length > 10
                  ? currentUser?.firstName.slice(0, 10).padEnd(13, '...')
                  : currentUser?.firstName}
              </div>
              {/* end::Theme mode */}
            </div>
            <div className='ms-6 mt-1'>
              {/*begin::Action*/}
              <a
                href='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2 d-flex'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                {/* <KTIcon iconName='arrow-down' className='text-muted fs-1' /> */}
                <div className='symbol symbol-35px'>
                  <img
                    className='rounded-circle'
                    src={
                      currentUser?.profilePicture
                        ? currentUser?.profilePicture
                        : currentUser?.role === 'ADMIN' || currentUser?.role === 'SUPER_ADMIN'
                        ? toAbsoluteUrl('/media/avatars/blank.png')
                        : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
                    }
                    alt=''
                  />
                </div>
                <div className=''>
                  <KTIcon iconName='down' className='text-muted fs-4 ms-1 text-link-hover' />
                </div>
              </a>

              <HeaderUserMenu />
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  );
};

export {HeaderToolbar};
