export const DASHBOARD_ROUTE = '/dashboard';
export const AUTH_ROUTE = '/auth';
export const RESTAURANTS_ROUTE = '/restaurants';
export const RESTAURANT_DETAILS_ROUTE = '/restaurants/:restaurantId';
export const ADMIN_DETAILS_ROUTE = 'admin/:adminId';
export const USERS_DETAILS_ROUTE = '/users/:userId';
export const BOOKING_ROUTE = '/bookings';
export const CONTACT_ROUTE = '/contact';
export const TRANSACTIONS_ROUTE = '/transactions';
export const ADD_RESTAURANT = '/add-restaurant';
export const STRIPE_CONNECT = '/stripe-connect';
export const MANAGE_HOST = '/manage-host';

export const TABLES_ROUTE = '/tables';
export const USERS_ROUTE = '/users';

export const ADMIN_CONTACT = '/contacts';
export const ADMIN_ROUTE = '/admin';
export const AUCTION_ROUTE = '/auction';
export const AUCTION_TYPE_ROUTE = '/types';
export const AUCTION_DETAILS_ROUTE = '/auction/:auctionId';
export const RES_RECURRING_AUTCTION_DETAILS_ROUTE = '/recurring-auction';
export const PROFILE_ROUTE = '/profile';
export const BIDDING_ROUTE = '/bidding';
export const CONTACT_DETAILS_ROUTE = '/contact/:contactId';
export const REVIEWS_ROUTE = '/reviews';
export const REPORTS_ROUTE = '/report';
export const COUPON_LIST = '/coupons';

export const USERS_BIDDING_ROUTE = `${USERS_ROUTE}${BIDDING_ROUTE}/:userId`;
export const USERS_TRANSACTION_ROUTE = `${USERS_ROUTE}${TRANSACTIONS_ROUTE}/:userId`;
export const USERS_BOOKING_ROUTE = `${USERS_ROUTE}${BOOKING_ROUTE}/:userId`;

export const RESTAURANT_BIDDING_ROUTE = `${RESTAURANTS_ROUTE}${BIDDING_ROUTE}/:restaurantId`;
export const RESTAURANT_TRANSACTION_ROUTE = `${RESTAURANTS_ROUTE}${TRANSACTIONS_ROUTE}/:restaurantId`;
export const RESTAURANT_BOOKING_ROUTE = `${RESTAURANTS_ROUTE}${BOOKING_ROUTE}/:restaurantId`;
export const RESTAURANT_AUCTION_ROUTE = `${RESTAURANTS_ROUTE}${AUCTION_ROUTE}/:restaurantId`;

export const BOOKING_ATTENDANCE = [
  {label: 'Pending', value: ''},
  {label: 'Absent', value: 'absent'},
  {label: 'Present', value: 'present'},
];
export const USER_STATUS = [
  {name: 'Active', value: 'ACTIVE'},
  {name: 'Inactive', value: 'INACTIVE'},
];

export const DAYS_OF_WEEK = [
  {name: 'Sunday', value: 'SUNDAY'},
  {name: 'Monday', value: 'MONDAY'},
  {name: 'Tuesday', value: 'TUESDAY'},
  {name: 'Wednesday', value: 'WEDNESDAY'},
  {name: 'Thursday', value: 'THURSDAY'},
  {name: 'Friday', value: 'FRIDAY'},
  {name: 'Saturday', value: 'SATURDAY'},
];

export const VIEW_STATUS = ['Viewed', 'Pending'];

export const DEFAULT_PAGINATION_FOR_ASYNC_SELECTBOX = 100;

export const API_ERROR_MESSAGE = 'Some Problem occured, Please try again';

// toast ids
export const TOAST_USER_DETAILS = 'userId';
export const TOAST_RESTAURANT_DETAILS = 'restaurantId';
export const TOAST_PROFILE = 'profile';
export const TOAST_GENERAL = 'generalToast';

// if startdate will come in future -> return 1
// if start date & end date in past -> return -1
// if it's b/w start & end date -> return 0
export const isDateTimeInRange = (startDateTime, endDateTime) => {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);
  const currentDate = new Date();

  if (startDate > currentDate) {
    return 1;
  }

  // Check if the current date and time is between the start and end dates and times
  if (startDate <= currentDate && currentDate <= endDate) {
    return 0;
  }
  return -1;
};

export const getAuctionStatus = (startDateTime, endDateTime) => {
  const status = isDateTimeInRange(startDateTime, endDateTime);
  if (status === 1) {
    return 'Auction not started yet';
  }
  if (status === 0) {
    return 'Auction is going on';
  }
  if (status === -1) {
    return 'Auction Ended';
  }
};

export const EDIT_ERROR = 'Edit profile to change text';
export const EDIT_ERROR_RESTAURANT = 'Edit restaurant to change text';
export const EDIT_ERROR_IMG = 'Edit profile to change Image';
export const EDIT_ERROR_IMG_RESTAURANT = 'Edit restaurant to change Image';
export const EDIT_USR_ERROR = 'Edit user to change text';
export const EMAIL_NOT_EDITABLE = "Email can't be edited";
export function convertDateToUTC(inputDate, MinMinus = false) {
  // return inputDate;
  if (!inputDate) return '';
  let date: any = new Date(`${inputDate}T00:00:00`);
  if (MinMinus) {
    date.setMinutes(date.getMinutes() - 1);
  }
  return date.toISOString();
}

export function convertDateFormat(str) {
  if (str !== undefined && str !== 'NaN-aN-aN') {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
}
