import {TInitialState} from './index.d';

export default function reducerFunc(
  state: TInitialState,
  action: {type: any; payload?: any | undefined}
): TInitialState {
  if (action.type === 'OPEN_MODAL') {
    return {...state, isModalOpen: true, modalType: action.payload};
  }
  if (action.type === 'CLOSE_MODAL') {
    return {...state, isModalOpen: false, selectedRow: null};
  }
  if (action.type === 'SELECT_ROW') {
    return {...state, selectedRow: action.payload};
  }
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      currentPage: action.payload,
    };
  }

  if (action.type === 'SEARCH_CHANGE') {
    return {...state, search: action.payload, currentPage: 1};
  }

  if (action.type === 'GET_DATA') {
    return {...state, data: action.payload, loading: false};
  }

  if (action.type === 'LOADING') {
    return {...state, loading: action.payload};
  }
  if (action.type === 'SET_TOTAL_PAGES') {
    return {
      ...state,
      totalPages: Math.ceil(action.payload / state.pageSize),
      totalData: action.payload,
    };
  }

  if (action.type === 'SET_STATUS') {
    return {...state, statusFilter: action.payload, currentPage: 1};
  }
  if (action.type === 'SET_RESTAURANT') {
    return {...state, restaurantFilter: action.payload, currentPage: 1};
  }
  if (action.type === 'SET_CAPACITY') {
    return {...state, capacity: action.payload, currentPage: 1};
  }
  if (action.type === 'SET_DATE') {
    return {...state, dateFilter: action.payload, currentPage: 1};
  }
  if (action.type === 'SET_PAGE_SIZE') {
    return {
      ...state,
      pageSize: action.payload,
    };
  }

  return state;
}
