import {useEffect, useReducer, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import {useThemeMode} from '../../../../_metronic/partials';
import DataTableWrapper from '../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL, convertUtcToLocal} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import {
  CommonRowInTable,
  CommonStatusInTable,
  CommonViewInHeader,
} from '../../../components/common/DataTableViews';
import {useAuth} from '../../../modules/auth';
import reducerFunc from './actions';

export interface InitialState {
  loading: boolean;
  currentPage: number;
  totalData: number;
  data: any[];
  totalPages: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  error: null | any;
  search: string;
  changeStatusId?: null | string;
  isModalOpen: boolean;
  transactionData: any;
  restaurantFilter: string;
  userFilter: string;
  statusFilter: string;
}

const initialState: InitialState = {
  loading: true,
  data: [],
  totalData: -1,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  error: null,
  search: '',
  restaurantFilter: '',
  changeStatusId: null,
  isModalOpen: false,
  statusFilter: '',
  userFilter: '',
  transactionData: {},
  startDate: '',
  endDate: '',
};

interface IParamsInfo {
  userId?: string;
  restaurantId?: string;
  showRestaurantFilter?: boolean;
  showUserFilter?: boolean;
  setUserName?: any;
  breadcrumb?: any;
  filterType?: string;
}
const RestaurantTransactions = ({
  userId = '',
  restaurantId = '',
  showRestaurantFilter = true,
  showUserFilter = true,
  setUserName,
  breadcrumb,
  filterType,
}: IParamsInfo) => {
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  const {currentUser, transactionModule} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const {mode} = useThemeMode();

  const columns = [
    {
      name: <CommonViewInHeader name='Restaurants' classes='ms-8' />,
      selector: (row, i) => row?.restaurant?.name,
      sortable: true,
      width: '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.restaurant?.name}
            avatar={row?.restaurant?.images ?? 'media/avatars/restaurant-blank.png'}
            classes='text-dark cursor-pointer'
            onClick={() => dispatch({type: 'OPEN_MODAL', payload: row})}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='User Name' />,
      selector: (row, i) => row.user?.firstName,
      sortable: true,
      width: '180px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.user?.firstName + ' ' + row?.user?.lastName}
            classes='text-theme-color text-capitalize cursor-pointer'
            onClick={() => dispatch({type: 'OPEN_MODAL', payload: row})}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row?.updated_at,
      width: '220px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row.updated_at)}
            classes='text-theme-color text-uppercase cursor-pointer'
            onClick={() => dispatch({type: 'OPEN_MODAL', payload: row})}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      width: '140px',
      format: (row, i) => {
        return <CommonStatusInTable text={row.status} />;
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Amount' />,
      selector: (row, i) => parseFloat(row.amount),
      width: '140px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={'$ ' + row.amount}
            classes='text-theme-color cursor-pointer'
            onClick={() => dispatch({type: 'OPEN_MODAL', payload: row})}
          />
        );
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Attempt' />,
      selector: (row, i) => row.attempt,
      width: '120px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            onClick={() => dispatch({type: 'OPEN_MODAL', payload: row})}
            text={row.attempt}
            classes='text-theme-color cursor-pointer text-link-hover'
          />
        );
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.email,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='View transaction details'
              className='linkIcon'
              onClick={() => {
                dispatch({type: 'OPEN_MODAL', payload: row});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  // const getBookings = () => {
  //   dispatch({type: 'LOADING', payload: true});
  //   setTimeout(() => {
  //     dispatch({type: 'GET_DATA', payload: RestaurantTransactionsData});
  //   }, 1000);
  // };

  // useEffect(() => {
  //   getBookings();
  // }, []);

  const {state: locationState}: {state: any} = useLocation();
  const restaurantUserLcoationFilter = useRef({
    user: userId,
    restautant: restaurantId,
  });
  const getTransctions = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await transactionModule?.getAllTransactions({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      restaurantIdFilter: restaurantUserLcoationFilter.current.restautant || state.restaurantFilter,
      userIdFilter: restaurantUserLcoationFilter.current.user || state.userFilter,
      statusFilter: state.statusFilter,
      startDate: state.startDate,
      endDate: state.endDate,
    });

    if (result?.success) {
      const user = showUserFilter
        ? result?.data?.rows[0]?.restaurant?.name
        : result?.data?.rows[0]?.user.firstName;
      setUserName && setUserName(user);
      dispatch({type: 'GET_DATA', payload: result?.data?.rows});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});

      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  useEffect(() => {
    getTransctions();
  }, [
    state.search,
    state.currentPage,
    state.pageSize,
    state.restaurantFilter,
    state.userFilter,
    state.statusFilter,
    state.startDate,
    state.endDate,
  ]);

  return (
    <>
      {breadcrumb ? breadcrumb : <PageTitle breadcrumbs={[]}>Transaction List</PageTitle>}
      <DataTableWrapper
        loading={state.loading}
        totalData={state.totalData}
        columns={columns}
        data={state.data}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        handlePageChange={(page) => dispatch({type: 'CHANGE_PAGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        filters={{
          dropdown: [
            // {
            //   label: 'User Name',
            //   options: RestaurantTransactionsData.map((t) => ({
            //     value: t.restaurantName,
            //     label: t.restaurantName,
            //   })),
            // },
            {
              label: 'Status',
              options: ['succeeded', 'failed'].map((t) => ({
                value: t,
                label: t,
              })),
            },
          ],
          userFilter: showUserFilter,
          restaurantFilter: isAdmin && showRestaurantFilter,
          date: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_STATUS', payload: value.Status?.value});
          dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
          dispatch({type: 'SET_USER', payload: value.userFilter?.value});
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: value.startDate, endDate: value.endDate},
          });
        }}
        isFilterApplied={Boolean(
          state.restaurantFilter || state.userFilter || state.search || state.statusFilter
        )}
        filterType={filterType}
      />
      {state.isModalOpen && (
        <CustomModal
          handleSaveCallback={() => {}}
          handleCancelCallback={() => {
            dispatch({type: 'CLOSE_MODAL'});
          }}
          data={state.transactionData}
          title='Transaction Details'
          type='TRANSACTION_DETAILS'
        />
      )}
    </>
  );
};

export default RestaurantTransactions;
