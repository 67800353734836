import {useState} from 'react';
import {Link} from 'react-router-dom';
import {KTIcon} from '../../../_metronic/helpers';

export default function SectionWrapper({
  children,
  title,
  id,
  rating,
  report,
  subTitle = '',
}: {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
  rating?: any;
  report?: any;
  subTitle?: JSX.Element | string;
}) {
  const [showSection, setShowSection] = useState(true);
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className={`card-header border-0 cursor-pointer d-flex align-items-center ${
            !showSection && 'collapsed'
          }`}
          // role='button'
          // data-bs-toggle='collapse'
          // data-bs-target={`#${id}`}
          // aria-expanded='true'
          // aria-controls={id}
          onClick={() => {
            setShowSection((oldState) => !oldState);
          }}
          style={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          <div className={`card-title m-0 d-flex justify-content-between ${subTitle && ''}`}>
            <h3 className='fw-bolder m-0'>{title}</h3>
          </div>
          <div
            className='d-flex gap-4 align-items-center me-4 ms-auto'
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {subTitle}
            {rating !== false && rating !== undefined ? (
              <>
                <Link to='/reviews' state={{restaurant: id}} className='d-flex'>
                  <div className='fs-4 fw-bold'>
                    {rating == 0 ? 'Not Rated Yet' : Number(rating).toFixed(1)}
                  </div>
                  {rating > 0 && (
                    <div className='rating-label checked'>
                      <i className='ki-duotone ki-star fs-1'></i>
                    </div>
                  )}
                </Link>
                <div className='mt-1'>|</div>
                <Link to='/report' state={{restaurant: id}} className='d-flex'>
                  <KTIcon iconName='tablet-delete' className='fs-2 mt-1' />
                  <div className='fs-4'>{report}</div>
                </Link>
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            <KTIcon
              iconName={`arrow-${showSection ? 'down' : 'up'}`}
              className='fs-1 text-link-hover'
            />
          </div>
        </div>
        <div className={`collapse ${showSection && 'show'}`} id={id}>
          <div className='card-body border-top'>{children}</div>
        </div>
      </div>
    </>
  );
}
