import React, {useState, useEffect, useRef, useCallback} from 'react';
import {KTIcon} from '../../_metronic/helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dataTable.css';
import Select from 'react-select';
import UserFilter from './Filters/UserFilter';
import RestaurantFilter from './Filters/RestaurantFilter';
import {useLocation, useParams} from 'react-router-dom';
import {useAuth} from '../modules/auth';
import DateFilter from './Filters/DateFilter';
import {convertDateStringToDate} from '../Helpers';
import {useThemeMode} from '../../_metronic/partials';
import {useSessionState} from '../hook/useSessionState';
import {useNavigate} from 'react-router-dom';

interface IINITIAL_STATE {
  startDate: string;
  endDate: string;
  isOpen: boolean;
  userFilter: string | {value: string; label: string};
  setUserFilter?: string;
  restaurantFilter: string | {value: string; label: string};
  setRestaurantFilter?: string;
  capacity: any;
  minPrice: any;
  maxPrice: any;
  dateConfig: any;
  Status: string;
  ['Email verified']: string;
}
export default function Filters({
  filters,
  filtersCallback,
  isClearCalled,
  preserveType,
  filterType,
}) {
  const {mode} = useThemeMode();
  const btnFilterWrapper = useRef<HTMLButtonElement | null>(null);
  const selectBoxStyle = {
    control: (base, state) => ({
      ...base,
      border: mode === 'light' ? 'none' : 'var(--bs-gray-100)',
      backgroundColor: mode === 'light' ? '#f9f9f9' : 'var(--bs-gray-100)',
      boxShadow: 'none',

      '&:hover': {
        border: 0,
        backgroundColor: mode === 'light' ? '#f1f1f2' : 'var(--bs-gray-100)',
      },
      '&:focus': {
        border: 0,
        backgroundColor: mode === 'light' ? '#f1f1f2' : 'var(--bs-gray-100)',
      },
    }),
    option: (provided) => ({
      ...provided,
      color: mode === 'light' ? 'var(--bs-text-dark)' : '#fff',
      backgroundColor: mode === 'light' ? '#f9f9f9' : 'var(--bs-gray-100)',
      border: mode === 'light' ? 'inherit' : 'var(--bs-gray-100)',
      '&:hover': {
        backgroundColor: mode === 'light' ? '#f8f8f8' : 'var(--bs-gray-200)',
      },
      '&:focus': {
        backgroundColor: mode === 'light' ? '#f8f8f8' : 'var(--bs-gray-200)',
      },
      boxShadow: 'none',
    }),
    menu: (base) => ({
      ...base,
      border: 'none',
      marginTop: 0,
    }),

    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      border: mode === 'light' ? 'none' : 'var(--bs-gray-100)',
      color: mode === 'light' ? 'var(--bs-text-dark)' : '#fff',
      boxShadow: 'none',
    }),
  };
  const [state, setState] = useSessionState(
    {
      startDate: '',
      endDate: '',
      isOpen: false,
      userFilter: '',
      restaurantFilter: '',
      Status: '',
      capacity: '',
      minPrice: '',
      maxPrice: '',
      filterType: '',
      dateConfig: filters?.date?.occurance && [{startDate: '', endDate: ''}],
      ['Email verified']: '',
    },
    preserveType
  );
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !btnFilterWrapper.current?.contains(event.target) &&
        !event.target.closest?.('svg')
      ) {
        setState((prevState) => ({...prevState, isOpen: false}));
      }
    },
    [filterRef]
  );

  const enterKeyPressed = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        const isFilterChanged = Object.entries(state)?.reduce((acc, data) => {
          if (data[1]) return true;
          return acc;
        }, false);
        isFilterChanged && !disabledSubmitBtn && filtersCallback(state);

        setState((prevState) => ({...prevState, isOpen: true}));
      }
    },
    [disabledSubmitBtn, state, filtersCallback]
  );

  useEffect(() => {
    const handleOutsideClickWrapper = (event) => handleOutsideClick(event);
    const enterKeyPressedWrapper = (event) => enterKeyPressed(event);

    document.addEventListener('mousedown', handleOutsideClickWrapper);
    filterRef.current && filterRef.current.addEventListener('keydown', enterKeyPressedWrapper);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClickWrapper);
      filterRef.current && filterRef.current.removeEventListener('keydown', enterKeyPressedWrapper);
    };
  }, [handleOutsideClick, enterKeyPressed, filterRef]);

  // for preselected data
  const {state: locationState}: {state: any} = useLocation();
  const {restaurantModule, userModule} = useAuth();
  useEffect(() => {
    if (!state?.userFilter)
      if (state.setUserFilter || locationState?.user) {
        userModule?.getSingleUser(state.setUserFilter || locationState.user).then(({data}: any) =>
          setState({
            ...state,
            setUserFilter: '',
            userFilter: {value: data.id, label: data.firstName + ' ' + data.lastName},
          })
        );
      } else if (!state?.restaurantFilter)
        if (state.setRestaurantFilter || locationState?.restaurant) {
          restaurantModule
            ?.getSingleRestaurant(state.setRestaurantFilter || locationState.restaurant)
            .then(({data}: any) =>
              setState({
                ...state,
                setRestaurantFilter: '',
                restaurantFilter: {value: data.id, label: data.restaurant.name},
              })
            );
        }
  }, [locationState?.user, locationState?.restaurant]);
  // ended
  const onChange = (dates) => {
    const [start, end] = dates;
    let isOpen = true;
    if (!start && !end) {
      filtersCallback({...state, endDate: end, startDate: start});
      // isOpen = false;
    }

    setDisabledSubmitBtn(start && !end);
    setState((oldState) => ({
      ...oldState,
      endDate: end,
      startDate: start,
      isOpen,
    }));
  };

  const clearFilters = () => {
    const newState: any = {};
    Object.keys(state).forEach((e) => {
      if (e === 'dateConfig' && filters?.date?.occurance) {
        newState[e] = Array.from({length: filters?.date?.occurance}, (v, k) => ({
          startDate: '',
          endDate: '',
        }));
        setDisabledSubmitBtn(false);
      } else newState[e] = '';
    });

    setState(newState);
    filtersCallback({...newState, reset: true});
    navigate('', {state: null});
  };

  useEffect(() => {
    if (isClearCalled) {
      clearFilters();
    }
  }, [isClearCalled]);
  const onFilterOptionChange = function (key, val) {
    if (state[key] && !val) {
      filtersCallback({...state, [key]: ''});
    }
    setState((oldState) => ({...oldState, [key]: val}));
  };
  const filterWrapper = useRef<any>();

  const params = useParams();
  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        ref={btnFilterWrapper}
        onClick={() => setState({...state, isOpen: !state.isOpen})}
        style={
          state.isOpen
            ? {
                position: 'relative',
              }
            : {}
        }
      >
        <KTIcon iconName='filter' className='fs-2' />
        {/* Filter */}
      </button>
      <div
        tabIndex={0}
        ref={filterRef}
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu={state.isOpen}
        style={
          state.isOpen
            ? {
                display: 'flex',
                zIndex: 5,
                position: 'absolute',
                inset: ' 0px 0px auto auto',
                margin: '0px',
                top: 65,
                right: 50,
              }
            : {}
        }
      >
        <div className='px-7 py-5 text-start'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5 text-start'>
          {filters.dropdown &&
            filters.dropdown.map((ele, ind) => {
              return (
                <div className='mb-4 filters-select' key={ind}>
                  <Select
                    styles={selectBoxStyle}
                    isClearable={true}
                    className='capitalize'
                    onChange={onFilterOptionChange.bind(null, ele.label)}
                    options={ele.options}
                    placeholder={ele.label}
                    value={
                      state[ele.label]
                        ? state[ele.label]
                        : ele.selected
                        ? ele?.options?.find(
                            (opt) => opt.value === state[ele.label] || ele.selected
                          )
                        : ''
                    }
                  />
                </div>
              );
            })}
          {filters.capacity && !params.auctionId && (
            <div className='mb-4 '>
              <input
                pattern='[0-9]'
                type='number'
                value={state.capacity}
                onChange={(e) => {
                  setDisabledSubmitBtn(+e.target.value > 50);
                  setState((oldState) => {
                    return {...oldState, capacity: e.target.value};
                  });
                }}
                onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-') && evt.preventDefault()}
                min={2}
                max={50}
                placeholder='Capacity'
                className={`form-control form-control-lg form-control-solid w-100  ${
                  mode === 'light' ? 'price-inputbox-light' : ''
                }`}
              />
              {state.capacity > 50 && (
                <span className='errorColor'>Capacity can't be geater then 50</span>
              )}
            </div>
          )}

          {filters.userFilter && (
            <UserFilter
              style={selectBoxStyle}
              onChange={onFilterOptionChange.bind(null, 'userFilter')}
              value={state.userFilter}
              filterType={filterType}
            />
          )}
          {filters.restaurantFilter && !params.auctionId && (
            <RestaurantFilter
              style={selectBoxStyle}
              onChange={onFilterOptionChange.bind(null, 'restaurantFilter')}
              value={state.restaurantFilter}
              filterType={filterType}
            />
          )}
          {filters.price && (
            <div className='mb-4 '>
              <label className='form-label fs-6 fw-bold'>Min Price - Max Price</label>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    placeholder='Min Price'
                    className='form-control form-control-lg form-control-solid w-100 price-inputbox'
                    type='number'
                    value={state.minPrice}
                    onChange={(e) =>
                      setState((oldState) => ({...oldState, minPrice: e.target.value}))
                    }
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type='number'
                    disabled={!state.minPrice}
                    min={state.minPrice}
                    placeholder='Max Price'
                    className='form-control form-control-lg form-control-solid w-100 price-inputbox'
                    value={state.maxPrice}
                    onChange={(e) => {
                      const maxPriceValue = e.target.value === '' ? '' : parseFloat(e.target.value);
                      setState((oldState) => ({...oldState, maxPrice: maxPriceValue}));
                      setDisabledSubmitBtn(
                        state.minPrice !== '' &&
                          (maxPriceValue === '' || state.minPrice >= maxPriceValue)
                      );
                    }}
                  />
                </div>
              </div>
              {state.minPrice && state.maxPrice === '' && (
                <span className='errorColor'>Max price can not be null</span>
              )}
              {state.maxPrice !== '' && state.minPrice >= state.maxPrice && (
                <span className='errorColor'>Max price should be higher than min price</span>
              )}
            </div>
          )}

          {typeof filters.date === 'boolean' && filters.date && (
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Start Date - End Date</label>
              <DatePicker
                dateFormat='dd/MM/yyyy'
                isClearable={state.startDate || state.endDate}
                className='form-control form-control-lg form-control-solid'
                onChange={onChange}
                startDate={state.startDate}
                endDate={state.endDate}
                selectsRange
                // popperPlacement='auto'
              />
              {state.startDate && !state.endDate && (
                <span className='errorColor'>End Date is Required</span>
              )}
            </div>
          )}

          {state.dateConfig?.length > 0 && (
            <DateFilter
              setState={setState}
              state={state}
              dateConfig={filters.date}
              setDisabledSubmitBtn={setDisabledSubmitBtn}
            />
          )}

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              onClick={() => clearFilters()}
            >
              Reset
            </button>
            <button
              type='button'
              disabled={disabledSubmitBtn}
              className={`${
                disabledSubmitBtn && 'cursor-not-allowed'
              } btn btn-primary fw-bold px-6`}
              onClick={() => {
                setState((state) => ({...state, isOpen: false}));
                const modifiedState = {...state};
                if (typeof filters.date === 'boolean') {
                  modifiedState.startDate = convertDateStringToDate(modifiedState.startDate);
                  modifiedState.endDate = convertDateStringToDate(modifiedState.endDate);
                } else if (state.dateConfig?.length) {
                  modifiedState.dateConfig = modifiedState.dateConfig.map((date) => ({
                    startDate: convertDateStringToDate(date.startDate),
                    endDate: convertDateStringToDate(date.endDate),
                  }));
                }
                filtersCallback(modifiedState);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
