import { PageTitle } from '../../../../_metronic/layout/core';
import { BookingDataTable } from '../../../components/common/DataTableViews/Booking';

const BookingForAdmin = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Bookings List</PageTitle>
      <BookingDataTable />
    </>
  );
};

export { BookingForAdmin };
