import {FC, useState, createContext, useContext, Dispatch, SetStateAction} from 'react';
import {AuthModel} from './_models';
import * as authHelper from './AuthHelpers';
import {WithChildren} from '../../../../_metronic/helpers';
import {UserApiModule} from '../../../ApiHelper/UserApiModule';
import {AuthApiModule} from '../../../ApiHelper/AuthApiModule';
import {RestaurantApiModule} from '../../../ApiHelper/RestaurantApiModule';
import {AuctionApiModule} from '../../../ApiHelper/AuctionApiModule';
import {ContactApiModule} from '../../../ApiHelper/ContactApiModule';
import {TransactionApiModule} from '../../../ApiHelper/TransactionaApiModule';
import {BiddingApiModule} from '../../../ApiHelper/BiddingApiModule';
import {ReviewApiModule} from '../../../ApiHelper/ReviewApiModule';
import {ReportApiModule} from '../../../ApiHelper/ReportApiModule';
import {DashboardApiModule} from '../../../ApiHelper/DashboardApiModule';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined | any, user: AuthModel | undefined | any) => void;
  currentUser: AuthModel | undefined;
  userModule: UserApiModule | undefined;
  authModule: AuthApiModule | undefined;
  restaurantModule: RestaurantApiModule | undefined;
  reviewModule: ReviewApiModule | undefined;
  reportModule: ReportApiModule | undefined;
  contactModule: ContactApiModule | undefined;
  transactionModule: TransactionApiModule | undefined;
  dashboardModule: DashboardApiModule | undefined;
  biddingModule: BiddingApiModule | undefined;
  setCurrentUser: Dispatch<SetStateAction<AuthModel | undefined>> | any;
  auctionModule: AuctionApiModule | undefined;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  userModule: undefined,
  authModule: undefined,
  contactModule: undefined,
  restaurantModule: undefined,
  dashboardModule: undefined,
  reviewModule: undefined,
  reportModule: undefined,
  biddingModule: undefined,
  auctionModule: undefined,
  transactionModule: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());

  const [currentUser, setCurrentUser] = useState<AuthModel | undefined>(
    authHelper.getCurrentUser()
  );

  const saveAuth = (auth: AuthModel | undefined, user: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setCurrentUser(user);
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const [userModule] = useState<UserApiModule>(new UserApiModule());
  const [authModule] = useState<AuthApiModule>(new AuthApiModule());
  const [restaurantModule] = useState<RestaurantApiModule>(new RestaurantApiModule());
  const [reviewModule] = useState<ReviewApiModule>(new ReviewApiModule());
  const [reportModule] = useState<ReportApiModule>(new ReportApiModule());
  const [auctionModule] = useState<AuctionApiModule>(new AuctionApiModule());
  const [transactionModule] = useState<TransactionApiModule>(new TransactionApiModule());
  const [biddingModule] = useState<BiddingApiModule>(new BiddingApiModule());
  const [contactModule] = useState<ContactApiModule>(new ContactApiModule());
  const [dashboardModule] = useState<DashboardApiModule>(new DashboardApiModule());

  const logout = () => {
    saveAuth(undefined, undefined);
    setCurrentUser(undefined);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        userModule,
        authModule,
        auctionModule,
        restaurantModule,
        transactionModule,
        biddingModule,
        reviewModule,
        reportModule,
        contactModule,
        dashboardModule,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export {AuthProvider, useAuth};
