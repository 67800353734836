import { useEffect, useState } from 'react';

export function CalculateTimeDifference({time}) {
  const [timeRemaining, setTimeRemaining] = useState('');

  function formatWithZero(value: number): string {
    if (value === 0 || value < 0) return '0';
    return value < 10 ? `0${value}` : `${value}`;
  }

  useEffect(() => {
    const givenDate = new Date(time);

    function updateDisplay() {
      const currentDate = new Date();
      const timeDifference = givenDate.getTime() - currentDate.getTime();
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // const formattedDays = formatWithZero(days);
      const formattedHours = days > 0 || hours > 0 ? `${formatWithZero(hours)} H ` : '';
      const formattedMinutes =
        days > 0 || hours > 0 || minutes > 0 ? `${formatWithZero(minutes)} M ` : '';
      // const formattedSeconds = formatWithZero(seconds);
      const formattedSeconds = '';
      const daysString = days > 0 ? `${days} D ` : '';

      // const timeString = `${daysString} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      const timeString = `${daysString} ${formattedHours} ${formattedMinutes} ${
        seconds > 0 ? seconds : 0
      } S`;
      setTimeRemaining(timeString);
    }

    const intervalId = setInterval(updateDisplay, 1000);
    updateDisplay();

    const clearTime = givenDate.getTime() - Date.now();
    setTimeout(() => {
      clearInterval(intervalId);
    }, clearTime);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on mount

  return <div>{timeRemaining}</div>;
}
