import ContentLoader from 'react-content-loader';

const NumberLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={40}
    viewBox='0 0 400 160'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <circle cx='20' cy='20' r='20' />
  </ContentLoader>
);

export default NumberLoader;
