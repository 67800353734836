import {useFormik} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import {
  API_ERROR_MESSAGE,
  EDIT_ERROR,
  EDIT_ERROR_IMG,
  EMAIL_NOT_EDITABLE,
  TOAST_PROFILE,
} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';

export interface IProfileDetails {
  firstName: string;
  lastName: string;
  profilePicture: string;
  imageFile: undefined | File;
  email: string;
  street: string;
  address: string;
  password: string;
  phoneNo: number;
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassWord?: string;
}

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First name should have minimum 3 characters')
    .max(15, 'First name should have maximun 15 characters')
    .required('First Name is required')
    .matches(/^[a-zA-Z]+$/, 'First name should contains characters only'),
  lastName: Yup.string()
    .min(3, 'Last name should have minimum 3 characters')
    .max(15, 'First name should have maximun 15 characters')
    .required('Last Name is required')
    .matches(/^[a-zA-Z]+$/, 'Last name should contains characters only'),
  email: Yup.string().required('Email is required'),
  // address: Yup.string().required('Address is required'),
  street: Yup.string().required('Address is required'),

  currentPassword: Yup.string(),
  newPassword: Yup.string()
    .test('isRequired', 'New password is required', function (value) {
      const currentPassword = this.parent.currentPassword;

      if (currentPassword && currentPassword.length > 0) {
        return !!value;
      }

      return true; // No validation needed if currentPassword is not provided
    })
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  confirmNewPassWord: Yup.string().test('newPassword', 'Passwords must match', function (value) {
    return this.parent.newPassword === value;
  }),
  imageFile: Yup.mixed()
    .test('fileSize', 'File size is too large (max 4MB)', (value) => {
      if (!value) {
        // Handle case where no file is selected
        return true;
      }
      const file = value as File; // Type assertion
      return file.size <= 4 * 1024 * 1024; // 4MB in bytes
    })
    .test('fileType', 'Invalid file type', (value) => {
      if (!value) {
        // Handle case where no file is selected
        return true;
      }
      const file = value as File; // Type assertion
      return ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type);
    }),
  // phoneNo: Yup.string()
  //   .required('Phone number is required')
  //   .min(10, 'Phone number must be at least 10 digits')
  //   .max(10, 'Phone number must not exceed 10 digits'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  avatar: '/media/avatars/blank.png',
  email: '',
  address: '',
  street: '',
  password: '',
  phoneNo: 9876543210,
  currentPassword: '',
  newPassword: '',
  confirmNewPassWord: '',
  profilePicture: '',
  imageFile: undefined,
};

const Profile = () => {
  const {currentUser, setCurrentUser, userModule, authModule} = useAuth();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIssEditMode] = useState(false);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    address: '',
  });
  // const initialValues: AuthModel | undefined = currentUser as AuthModel

  const getTSingleUser = async function () {
    const result = await userModule?.getSingleUser(currentUser?.id);
    if (result?.success) {
      formik.setValues(result.data);
      console.log();

      setUserData({...result.data, imageFile: undefined});
      setCurrentUser((currentUser) => ({
        ...currentUser,
        firstName: result?.data?.firstName,
        profilePicture: result?.data?.profilePicture,
      }));
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_PROFILE,
      });
    }
  };

  useEffect(() => {
    getTSingleUser();
  }, [isEditMode]);

  const [showPassword, setShowPassword] = useState({
    currentPassword: true,
    newPassword: true,
    confirmNewPassword: true,
  });

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (values?.newPassword) {
        // Update the password if a new password is provided
        const passwordResult = await authModule?.updatePassword({
          oldPassword: values?.currentPassword,
          newPassword: values?.newPassword,
        });

        // Handle the result of updating the password
        if (passwordResult?.success) {
          toast.success(`Profile Updated successfully`, {toastId: TOAST_PROFILE});
        } else {
          toast.error(passwordResult?.message || `Problem occurred while updating password`, {
            toastId: TOAST_PROFILE,
          });
          // You might want to return here to prevent further execution if updating the password fails
        }
      }
      if (
        userData.firstName !== values.firstName ||
        userData.lastName !== values.lastName ||
        userData.address !== values.address ||
        values.imageFile ||
        values.imageFile == null
      ) {
        const profileData = new FormData();
        // Continue with the logic to update the user profile
        profileData.append('firstName', values.firstName);
        profileData.append('lastName', values.lastName);
        profileData.append('street', values.street);
        if (values.imageFile) profileData.append('image', values.imageFile);
        if (values.imageFile === null) profileData.append('profilePicture', '');
        const result = await userModule?.updateUser(currentUser?.id, profileData);

        // Handle the result of updating the user profile
        if (result?.success) {
          toast.success(`Profile Updated successfully`, {toastId: TOAST_PROFILE});
        } else {
          toast.error(`Problem occurred while updating user`, {toastId: TOAST_PROFILE});
        }
      }
      setLoading(false);
      setIssEditMode(false);
    },
  });

  const img = useMemo(
    () =>
      formik.values.imageFile
        ? URL.createObjectURL(formik.values.imageFile)
        : formik.values.profilePicture || toAbsoluteUrl('/media/avatars/blank.png'),
    [formik.values.imageFile, formik.values.profilePicture]
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>Profile</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
          <div className='card-title m-0'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                setIssEditMode(!isEditMode);
              }}
            >
              {isEditMode ? 'Cancel Edit' : 'Edit'}
            </button>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/avatars/restaurant-blank.png'
                      )})`,
                    }}
                    title={isEditMode ? '' : EDIT_ERROR_IMG}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${img})`,
                      }}
                    ></div>
                    {isEditMode && (
                      <>
                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          htmlFor='restaurantImage'
                        >
                          <i className='ki-duotone ki-pencil fs-7'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>

                          <input
                            type='file'
                            id='restaurantImage'
                            name='avatar'
                            accept='.png, .jpg, .jpeg, .webp'
                            onChange={(e: any) => {
                              formik.setFieldValue('imageFile', e.target.files[0]);
                            }}
                          />
                        </label>
                        {(formik.values.imageFile || formik.values.profilePicture) && (
                          <span
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='remove'
                            onClick={() => {
                              formik.setFieldValue('imageFile', undefined);
                              formik.setFieldValue('profilePicture', '');
                            }}
                          >
                            <i className='ki-duotone ki-cross fs-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {formik.errors.imageFile && isEditMode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.imageFile}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/avatars/restaurant-blank.png'
                      )})`,
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          '/media/avatars/restaurant-blank.png'
                        )})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div> */}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>First Name</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    {...formik.getFieldProps('firstName')}
                    disabled={!isEditMode}
                    title={isEditMode ? '' : EDIT_ERROR}
                    maxLength={15}
                  />
                  {/* {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )} */}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last Name</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Last Name'
                    {...formik.getFieldProps('lastName')}
                    disabled={!isEditMode}
                    maxLength={15}
                    title={isEditMode ? '' : EDIT_ERROR}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid cursor-not-allowed'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    title={isEditMode ? '' : EMAIL_NOT_EDITABLE}
                    disabled
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address'
                    {...formik.getFieldProps('street')}
                    disabled={!isEditMode}
                    title={isEditMode ? '' : EDIT_ERROR}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik?.errors?.street}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone No</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone No'
                    {...formik.getFieldProps('phoneNo')}
                    disabled={disable}
                  />
                  {formik.touched.phoneNo && formik.errors.phoneNo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phoneNo}</div>
                    </div>
                  )}
                </div>
              </div> */}
              {isEditMode && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Current Password</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.currentPassword ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Current Password'
                        {...formik.getFieldProps('currentPassword')}
                        disabled={!isEditMode}
                        autoComplete='current-password'
                      />
                      {showPassword?.currentPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              currentPassword: !showPassword.currentPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              currentPassword: !showPassword.currentPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.currentPassword && formik.errors.currentPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>New Password</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.newPassword ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='New Password'
                        {...formik.getFieldProps('newPassword')}
                        disabled={!isEditMode}
                      />
                      {showPassword?.newPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              newPassword: !showPassword.newPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              newPassword: !showPassword.newPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.newPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      Confirm New Password
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.confirmNewPassword ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Confirm New Password'
                        {...formik.getFieldProps('confirmNewPassWord')}
                        disabled={!isEditMode}
                      />
                      {showPassword?.confirmNewPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmNewPassword: !showPassword.confirmNewPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmNewPassword: !showPassword.confirmNewPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.confirmNewPassWord && formik.errors.confirmNewPassWord && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.confirmNewPassWord}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {isEditMode && (
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
