import { PageTitle } from '../../../../_metronic/layout/core';
import { BookingDataTable } from '../../../components/common/DataTableViews/Booking';

const RestaurantBooking = () => {

  return (
    <>
      <PageTitle breadcrumbs={[]}>Bookings</PageTitle>
      <BookingDataTable/>
    </>
  );
};

export { RestaurantBooking };
