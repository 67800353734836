import React from 'react';
import {useThemeMode} from '../../../../_metronic/partials';

interface DeleteBodyProps {
  handleCancelCallback?: any;
  handleSaveCallback?: any;
  type?: any;
  optionalText?: any;
}

const DeleteBody: React.FC<DeleteBodyProps> = ({
  handleCancelCallback,
  handleSaveCallback,
  type,
  optionalText,
}) => {
  const {mode} = useThemeMode();

  return (
    <div className='text-center'>
      <div className='card-body'>
        {optionalText?.length > 1 ? (
          <h4
            className={`card-title fw-bold ${
              mode === 'dark' ? 'text-white' : 'text-black'
            } fs-4 mb-6`}
          >
            {optionalText}
          </h4>
        ) : (
          <h4
            className={`card-title fw-bold ${mode === 'dark' ? 'text-white' : 'text-black'} fs-4`}
          >
            {type === 'DELETE'
              ? 'Are you sure you want to delete this?'
              : "Want to Mark this as Present?, This action can't be reverted."}
          </h4>
        )}

        <div className='d-flex my-4 flex-center'>
          <button className='btn btn-sm btn-light me-3' onClick={handleCancelCallback}>
            No, cancel
          </button>
          <button className='btn btn-sm btn-primary me-3' onClick={handleSaveCallback}>
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBody;
