import {useEffect, useState} from 'react';
import {PageTitle} from '../../../_metronic/layout/core';
import {AuctionDashboard, TodaysReservation} from '../../components/Dashboard';
import {CustomModal} from '../../components/Modal';
import Card from '../../components/Dashboard/Card';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../Helpers';
import {useAuth} from '../../modules/auth';
import {toast} from 'react-toastify';
import {BiddingDashboard} from '../../components/Dashboard/BiddingDashboard';
import NumberLoader from '../../components/Dashboard/NumberLoader';
import {DashboardData} from '.';
import {Link} from 'react-router-dom';

export const DashboardForAdmin = () => {
  const [modalInfo, setModalInfo] = useState({
    open: false,
    selectedRow: null,
  });
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const {currentUser, dashboardModule} = useAuth();

  const openModal = function (row) {
    setModalInfo({
      open: true,
      selectedRow: row,
    });
  };
  const getDashboardData = async () => {
    const result = await dashboardModule?.getAllDashboardData();

    if (result?.success) {
      setDashboardData(result?.data);
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div className='row g-6 g-xl-9 mb-5 mb-xl-8'>
        <div className='col-sm-6 col-xl-4'>
          <Link to='/restaurants' className='text-dark' state={{value: 'ACTIVE', label: 'Active'}}>
            <Card
              name='Active Restaurants'
              count={dashboardData ? dashboardData?.totalActiveRestaurants : <NumberLoader />}
              icon='home-2'
            />
          </Link>
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Link to='/users' className='text-dark' state={{value: 'ACTIVE', label: 'Active'}}>
            <Card
              name='Active Users'
              count={dashboardData ? dashboardData?.totalActiveUsers : <NumberLoader />}
              icon='user'
            />
          </Link>
        </div>
        <div className='col-sm-6 col-xl-4'>
          <Link
            to='/auction'
            className='text-dark'
            state={{value: 'ACTIVE', label: 'Active', status: 'filter'}}
          >
            <Card
              name='Ongoing Auctions'
              count={dashboardData ? dashboardData?.totalOngoingAuctions : <NumberLoader />}
              icon='element-11'
            />{' '}
          </Link>
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-5 col-md-5'>
          <TodaysReservation
            className='card-xl-stretch mb-xl-8 dashboard-list'
            dashboardData={dashboardData?.topReservations}
          />
        </div>
        <div className='col-xl-7 col-md-7'>
          <BiddingDashboard
            onEyeIconClick={openModal}
            isBiddingTable={true}
            title='Recent Bidding'
            dashboardData={dashboardData?.TopBiddings}
            className='card-xl-stretch mb-5 mb-xl-8 dashboard-list'
          />
        </div>
      </div>
      <AuctionDashboard
        onEyeIconClick={openModal}
        title='Recent Auctions'
        className='card-xl-stretch mb-5 mb-xl-8 dashboard-list'
        dashboardData={dashboardData?.TopAuctions}
      />
      {modalInfo.open && (
        <CustomModal
          title={'Auction Details'}
          data={modalInfo.selectedRow}
          handleCancelCallback={() => setModalInfo({open: false, selectedRow: null})}
          handleSaveCallback={() => {}}
          type='AUCTION_DETAILS'
        />
      )}
    </>
  );
};
