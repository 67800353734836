import { useState } from 'react';
import Select from 'react-select';

let options: {value: string; label: string}[] = [];

export default function Days({onChange, isView, value}) {
  const [categories, setCategories] = useState<any>([
    'Select All Days',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]);

  options = categories?.map((t) => ({value: t, label: t}));
  value = value?.map((t) => ({value: t, label: t}));
  return (
    <>
      <div className='row mb-6'>
        <div className={`fv-row col-lg-8'`}>
          <Select
            isMulti
            isSearchable
            onChange={onChange}
            options={options}
            value={value}
            isDisabled={isView}
          />
        </div>
      </div>
    </>
  );
}
