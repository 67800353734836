import {forwardRef, useMemo, useState} from 'react';
import DataTable, {createTheme} from 'react-data-table-component';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {KTIcon} from '../../_metronic/helpers';
import {useThemeMode} from '../../_metronic/partials';
import {debounce} from '../Helpers';
import {useAuth} from '../modules/auth';
import CustomPagination from './CustomPagination';
import Filters from './Filters';
import NotFound from './NotFound';
import TableLoader from './TableLoader';
import './dataTable.css';
import {TDataTableWrapperInter} from './index.d';

createTheme(
  'darkMode',
  {
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    background: {
      default: '#2b2b40',
      hover: 'transparent',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'transparent',
      disabled: 'rgba(0,0,0,.12)',
    },
  },
  'dark'
);

const initialFilterState = {
  search: '',
  clearCalled: false,
  selectedRows: [],
  clearSelectedRows: false,
};

const DataTableWrapper = forwardRef(
  (
    {
      data,
      totalData = -1,
      columns,
      handleSearch = undefined,
      viewDetailsLink,
      filters = undefined,
      filtersCallback = undefined,
      isFilterApplied = false,
      loading,
      handlePageChange,
      currentPage,
      totalPages,
      handleSelected,
      selectableRows,
      subHeader,
      pageSize = 10,
      searchValue,
      totalBooking,
      todaysBooking,
      totalBookingAmount,
      todaysBookingAmount,
      showBookingCount,
      filterType,
      handlePageSizeChange = () => {},
      pagination = true,
    }: TDataTableWrapperInter,
    ref
  ) => {
    const {mode} = useThemeMode();
    const [searchVal, setSearchVal] = useState(searchValue || '');
    const checkBox: any = forwardRef((props, ref) => {
      return (
        <input
          className={`form-check-input ms-2 ${mode === 'dark' && 'new-input-checkbox'}`}
          // style={{border: '1px solid #4b4b50'}}
          type='checkbox'
          data-kt-check-target='#kt_table_users .form-check-input'
          {...props}
        />
      );
    });

    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const [state, setState] = useState<any>(initialFilterState);
    const pathname: any = useLocation();
    const {restaurantId, userId} = useParams();
    let viewMoreData = {state: {}};
    if (userId) {
      viewMoreData.state = {user: userId};
    } else if (restaurantId) {
      viewMoreData.state = {restaurant: restaurantId};
    }
    const debouncedDispatch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);
    const subHeaderComponent = (
      <>
        <div
          className='d-flex justify-content-between align-items-center w-100 table-header-custom datatable-header'
          style={{overflowX: 'auto', flexDirection: 'row'}}
        >
          {' '}
          <div>
            {currentUser?.role == 'RESTAURANT' && pathname?.pathname == '/auction'
              ? ''
              : handleSearch && (
                  <div className='d-flex align-items-center position-relative my-1'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                      type='text'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search'
                      value={searchVal}
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                        debouncedDispatch(e.target.value);
                      }}
                    />
                  </div>
                )}
          </div>
          <div className='d-flex align-items-center'>
            {selectableRows && state.selectedRows.length ? (
              <>
                <span
                  className='pe-4 fs-6 ms-auto fw-bold'
                  style={{
                    minWidth: 'auto',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {state.selectedRows.length} Selected
                </span>
                {selectableRows && state.selectedRows.length && (
                  <>
                    <button
                      type='button'
                      className='btn btn-danger px-12 mx-2'
                      style={{
                        minWidth: 'auto',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={() => {
                        handleSelected && handleSelected(state.selectedRows);
                        setState({...state, selectedRows: [], clearSelectedRows: true});
                      }}
                    >
                      {/* <KTIcon iconName='minus' className='fs-2 ' /> */}
                      Delete Selected
                    </button>
                  </>
                )}
              </>
            ) : (
              ' '
            )}
            {filters && filtersCallback && (
              <div>
                <Filters
                  filtersCallback={filtersCallback}
                  isClearCalled={state.clearCalled}
                  filters={filters}
                  preserveType={filters.preserveType}
                  filterType={filterType || ''}
                />
              </div>
            )}

            <>{subHeader}</>
          </div>
        </div>
        <div
          className='d-flex justify-content-between mt-4 align-items-center w-100 table-header-custom datatable-header display-flex-rows'
          style={{overflowX: 'auto', flexDirection: 'row'}}
        >
          {(pathname.pathname === '/bookings' || showBookingCount) && (
            <div className='d-flex justify-content-between mt-4 mb-4 align-items-center w-100 table-header-custom datatable-header display-flex-rows'>
              {' '}
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  backgroundColor: '#f7f7f7',
                  padding: '8px',
                  borderRadius: '5px',
                }}
                className='text-black fs-3 my-2'
              >
                Total Booking :<span className='text-red fw-bold fs-3 mx-1'>{totalBooking}</span>
              </span>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  backgroundColor: '#f7f7f7',
                  padding: '8px',
                  borderRadius: '5px',
                }}
                className='text-black fs-3 my-2 mx-0 mx-md-2'
              >
                Total Booking Amount :{' '}
                <span className='text-red fw-bold fs-3 mx-1'>{totalBookingAmount?.toFixed(2)}</span>
              </span>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  backgroundColor: '#f7f7f7',
                  padding: '8px',
                  borderRadius: '5px',
                }}
                className='text-black fs-3 my-2 mx-0 mx-md-2'
              >
                Today's Booking :<span className='text-red fw-bold fs-3 mx-1'>{todaysBooking}</span>
              </span>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  backgroundColor: '#f7f7f7',
                  padding: '8px',
                  borderRadius: '5px',
                }}
                className='text-black fs-3 my-2 mx-0 mx-md-2'
              >
                Today's Booking Amount :{' '}
                <span className='text-red fw-bold fs-3 mx-1'>
                  {todaysBookingAmount?.toFixed(2)}
                </span>
              </span>
            </div>
          )}
        </div>
      </>
    );

    const customSort = (rows, selector, direction) => {
      return rows.sort((a, b) => {
        // use the selector to resolve your field names by passing the sort comparitors
        const aField = typeof selector(a) === 'string' ? selector(a).toLowerCase() : selector(a);
        const bField = typeof selector(b) === 'string' ? selector(b).toLowerCase() : selector(b);

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }

        return direction === 'desc' ? comparison * -1 : comparison;
      });
    };
    return (
      <>
        <DataTable
          selectableRows={selectableRows}
          progressPending={loading}
          progressComponent={<TableLoader />}
          columns={columns}
          selectableRowDisabled={(rows: any) => rows?.deletable === 'false' || rows?.deletedAt}
          fixedHeader
          fixedHeaderScrollHeight='600px'
          data={data}
          pagination={pagination}
          paginationServer
          paginationPerPage={pageSize}
          onSelectedRowsChange={({selectedRows}) =>
            setState({...state, selectedRows: selectedRows})
          }
          paginationComponent={() =>
            !viewDetailsLink ? (
              <CustomPagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalData={totalData}
              />
            ) : (
              <div className='text-center mt-5'>
                <span
                  className='text-primary cursor-pointer view-more-btn'
                  onClick={() => {
                    navigate(viewDetailsLink);
                  }}
                >
                  View More
                </span>
              </div>
            )
          }
          noDataComponent={
            <NotFound
              searchKeyword={state.search}
              filters={isFilterApplied}
              data={data}
              clearSearch={() => {
                setState({...state, clearCalled: true});
                handleSearch && handleSearch('');
                setSearchVal('');
                setTimeout(() => setState(initialFilterState), 1000);
              }}
            />
          }
          paginationTotalRows={totalPages}
          onChangePage={(page) => (handlePageChange ? handlePageChange(page) : null)}
          highlightOnHover
          pointerOnHover
          subHeader={viewDetailsLink === undefined}
          selectableRowsComponent={checkBox}
          clearSelectedRows={state.clearSelectedRows}
          subHeaderComponent={subHeaderComponent}
          theme={mode === 'light' ? 'default' : 'darkMode'}
          sortFunction={customSort}
          // selectableRowDisabled={(rows: any) => rows.status}
          // sortIcon={sortIcon}
        />
      </>
    );
  }
);

export default DataTableWrapper;
