import { Link } from 'react-router-dom';
import { useThemeMode } from '../../../../_metronic/partials';
import { convertUtcToLocal } from '../../../Helpers';

export const CommonViewInHeader = ({name, classes = ''}: {name: string; classes?: string}) => {
  return <span className={`${classes} fs-6 fw-bolder text-uppercase text-dark`}>{name}</span>;
};

export const DateTimeViewInTable = ({dateTime}) => {
  const {mode} = useThemeMode();
  const inputDate = new Date(dateTime);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };

  const formattedDate = inputDate.toLocaleString('en-US');
  return <span className={`fs-6 min-w-100px text-dark`}>{formattedDate}</span>;
};

export const CommonRowInTable = ({
  text,
  navigateURL = '',
  avatar = '',
  classes = '',
  onClick = () => {},
}: {
  text: any;
  avatar?: string;
  navigateURL?: string;
  classes?: string;
  onClick?: () => void;
}) => {
  return (
    <div className='d-flex align-items-center m-2 py-3'>
      {avatar ? (
        navigateURL ? (
          <Link className={classes} to={navigateURL}>
            <div className='symbol symbol-50px me-5'>
              <img
                src={avatar || 'media/avatars/restaurant-blank.png'}
                className='h-[75px] rounded-circle w-[75px] symbol-label align-self-end'
                alt=''
              />
            </div>
          </Link>
        ) : (
          <div className='symbol symbol-50px me-5 cursor-default'>
            <img
              src={avatar || 'media/avatars/restaurant-blank.png'}
              className='h-[75px] rounded-circle w-[75px] symbol-label align-self-end'
              alt=''
            />
          </div>
        )
      ) : (
        ''
      )}
      <div className='d-flex justify-content-start flex-column'>
        <span
          className={`${classes} cursor-default mb-1 fs-6 text-dark`}
          onClick={onClick}
          title={text}
        >
          {navigateURL ? (
            <Link className={`${classes} text-link-hover`} to={navigateURL}>
              {text}
            </Link>
          ) : (
            text
          )}
        </span>
      </div>
    </div>
  );
};

export const CommonPriceInTable = ({price}) => {
  price += '$';
  return <CommonRowInTable text={price} />;
};

export const CommonDateInTable = ({date}) => {
  const formattedDate = convertUtcToLocal(date);
  return <CommonRowInTable text={formattedDate} />;
};

export const CommonStatusInTable = ({text}: {text: any}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <span
          className={`badge text-capitalize ${
            text == 'succeeded' || text.toLowerCase() == 'present'
              ? 'badge-light-success'
              : text == 'failed' || text.toLowerCase() == 'absent'
              ? 'badge-light-danger'
              : 'badge-light'
          } cursor-default mb-1 fs-6`}
        >
          {text}
        </span>
      </div>
    </div>
  );
};
