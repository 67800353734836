import {useState} from 'react';
import {RestaurantParamsInfo} from './DataTableViews/Auction';
import AuctionDataTable from './DataTableViews/Auction/AuctionTable';
import RecurringAuctionDataTable from './DataTableViews/Auction/RecurringAuctionTable';

function AuctionWrapper({restaurantId = '', setRestaurantName}: RestaurantParamsInfo) {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabNumber, tabType) => {
    setActiveTab(tabNumber);
    localStorage.removeItem('auctionTabValue');
    localStorage.setItem(
      'auctionTabValue',
      tabType === 'normalAuction' ? 'normalAuction' : 'recurringAuction'
    );
  };

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('auctionTabValue');
  });

  return (
    <div className='card'>
      <div className='tab-header'>
        <ul className='nav nav-pills nav-justified'>
          <li className='nav-item'>
            <a
              className={`nav-link fw-bold ${activeTab === 1 ? 'active text-white' : 'text-dark'}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_1'
              onClick={() => handleTabChange(1, 'normalAuction')}
            >
              Auctions
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link fw-bold ${
                activeTab === 2 ? 'active text-white' : 'text-dark'
              } w-150px`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_2'
              onClick={() => handleTabChange(2, 'recurringAuction')}
            >
              Recurring Auctions
            </a>
          </li>
        </ul>
      </div>

      <div className='card-body'>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <AuctionDataTable restaurantId={restaurantId} setRestaurantName={setRestaurantName} />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <RecurringAuctionDataTable restaurantId={restaurantId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuctionWrapper;
