import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import DataTableWrapper from '../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL, convertUtcToLocal} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import {CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import {useAuth} from '../../../modules/auth';
import reducerFunc from '../User/action';
import StatusSelectBox from '../../../components/Sections/Admin/StatusSelectBox';

const initialState: any = {
  loading: true,
  data: [],
  totalData: -1,
  isModalOpen: false,
  modalType: 'COUPON',
  selectedRow: undefined,
  currentPage: 1,
  totalPages: 1,
  error: null,
  search: '',
  changeAttendanceId: null,
  shouldRefreshTable: true,
  verifiedFilter: '',
  statusFilter: '',
  startDate: '',
  endDate: '',
  pageSize: 10,
};

export const CouponList = () => {
  const [state, dispatch] = useSessionReducer<any, any, string>(reducerFunc, initialState, 'USER');
  const [formLoading, setFormLoading] = useState(false);
  const {userModule, currentUser} = useAuth();
  const [view, setView] = useState('');

  let columns = [
    {
      name: <CommonViewInHeader name='Coupon Code' />,
      selector: (row, i) => row.code,
      sortable: true,
      width: '180px',
      format: (row, i) => {
        return (
          <span
            className='text-dark text-uppercase'
            onClick={() => {
              setView('view');
              dispatch({
                type: 'SELECT_ROW',
                payload: {...row, expiry: new Date(row.expiry)},
              });
              dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
            }}
          >
            {row.code}
          </span>
        );
      },
    },
    {
      name: <CommonViewInHeader name='Required Amount' />,
      selector: (row, i) => row.requiredAmount,
      sortable: true,
      width: '200px',
      format: (row, i) => {
        return (
          <span
            className='text-dark'
            onClick={() => {
              setView('view');
              dispatch({
                type: 'SELECT_ROW',
                payload: {...row, expiry: new Date(row.expiry)},
              });
              dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
            }}
          >
            {row.requiredAmount}
          </span>
        );
      },
      sortFunction: (a, b) => {
        return a.requiredAmount - b.requiredAmount;
      },
    },
    {
      name: <CommonViewInHeader name='Discount' />,
      selector: (row, i) => row.discount,
      sortable: true,
      width: '150px',
      format: (row, i) => {
        return (
          <span
            className='text-dark'
            onClick={() => {
              setView('view');
              dispatch({
                type: 'SELECT_ROW',
                payload: {...row, expiry: new Date(row.expiry)},
              });
              dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
            }}
          >
            {row.discount}%
          </span>
        );
      },
      sortFunction: (a, b) => {
        return a.discount - b.discount;
      },
    },
    {
      name: <CommonViewInHeader name='Limit' />,
      selector: (row, i) => row.limit,
      sortable: true,
      width: '100px',
      format: (row, i) => {
        return (
          <span
            className='text-dark'
            onClick={() => {
              setView('view');
              dispatch({
                type: 'SELECT_ROW',
                payload: {...row, expiry: new Date(row.expiry)},
              });
              dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
            }}
          >
            {row.limit}
          </span>
        );
      },
      sortFunction: (a, b) => {
        return a.limit - b.limit;
      },
    },
    {
      name: <CommonViewInHeader name='Expiry Date' />,
      selector: (row, i) => row.expiry,
      sortable: true,
      width: '220px',
      format: (row, i) => {
        return (
          <span
            className='text-dark'
            onClick={() => {
              setView('view');
              dispatch({
                type: 'SELECT_ROW',
                payload: {...row, expiry: new Date(row.expiry)},
              });
              dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
            }}
          >
            {convertUtcToLocal(row.expiry)}
          </span>
        );
      },
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      width: '150px',
      format: (row, i) => {
        return (
          <StatusSelectBox
            value={row.status ? 'ACTIVE' : 'INACTIVE'}
            onChange={async (e) => {
              await updateCoupon(row?.id, {
                code: row?.code,
                discountType: 'percentage',
                requiredAmount: row?.requiredAmount,
                discount: row?.discount,
                attempt: row?.attempt,
                limit: row?.limit,
                expiry: row?.expiry,
                status: row.status ? false : true,
              });
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.id,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='View coupon details'
              className='linkIcon'
              onClick={() => {
                setView('view');
                dispatch({
                  type: 'SELECT_ROW',
                  payload: {...row, expiry: new Date(row.expiry)},
                });
                dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>
            <span
              title='Edit coupon details'
              className={`linkIcon px-3 ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                setView('edit');
                dispatch({
                  type: 'SELECT_ROW',
                  payload: {...row, expiry: new Date(row.expiry)},
                });
                dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </span>
            <span
              title='Delete coupon'
              className={`linkIcon ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Admin is deleted.');
                  return;
                }
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getCouponListing = async () => {
    dispatch({type: 'LOADING', payload: true});

    const result = await userModule?.getCouponListing({
      pageNo: state.currentPage ? state.currentPage : 1,
      pageSize: state.pageSize,
      statusFilter:
        state.statusFilter === 'ACTIVE' ? true : state?.statusFilter === 'INACTIVE' ? false : '',
      searchKeyword: state.search,
      startDate: state.startDate,
      endDate: state.endDate,
    });

    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});

      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const createCoupon = async (userData) => {
    setFormLoading(true);

    const result = await userModule?.createCoupon({
      ...userData,
      discountType: 'percentage',
      status: true,
    });
    if (result?.success) {
      toast.success(`Coupon created successfully!!`, {
        toastId: TOAST_GENERAL,
      });
      getCouponListing();
      return true;
    } else {
      toast.error(result?.message || `Problem occurred, While creating Admin`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const updateCoupon = async (userId, couponDetails) => {
    setFormLoading(true);
    const result = await userModule?.updateCoupon(userId, couponDetails);
    if (result?.success) {
      toast.success(`Coupon Updated successfully`);
      getCouponListing();
      dispatch({type: 'CLOSE_MODAL'});
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    setFormLoading(false);
  };

  const deleteCoupon = async (couponIds) => {
    dispatch({type: 'CLOSE_MODAL'});
    const result = await userModule?.deleteCoupon(couponIds);
    if (result?.success) {
      toast.success(`Coupon Deleted successfully`);
      getCouponListing();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  useEffect(() => {
    getCouponListing();
  }, [
    state?.pageNo,
    state?.pageSize,
    state?.statusFilter,
    state.search,
    state.startDate,
    state.endDate,
  ]);

  const deleteItem = function (rows) {
    dispatch({type: 'SELECT_ROW', payload: rows});
    dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
  };

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary mt-2'
      onClick={() => {
        setView('edit');
        dispatch({type: 'OPEN_MODAL', payload: 'COUPON'});
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Coupon{' '}
    </button>
  );

  let filters: any = {
    label: 'Status',
    options: ['Active', 'Inactive'].map((t) => ({
      value: t.toUpperCase(),
      label: t,
    })),
  };

  return (
    <>
      <PageTitle>Coupons</PageTitle>
      <DataTableWrapper
        totalData={state.totalData}
        subHeader={subHeader}
        selectableRows={true}
        loading={state.loading}
        columns={columns}
        data={state.data}
        handleSelected={deleteItem}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        pageSize={state.pageSize}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [filters],
          date: currentUser?.role === 'ADMIN',
          preserveType: 'ADMIN',
        }}
        filtersCallback={(values) => {
          dispatch({
            type: 'SET_FILTER',
            payload: {
              statusFilter: values?.Status ? values.Status.value : '',
            },
          });
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: values.startDate, endDate: values.endDate},
          });
        }}
        isFilterApplied={Boolean(state.search || state.statusFilter)}
      />
      {state.isModalOpen && (
        <CustomModal
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state.selectedRow.id];
              deleteCoupon({ids: ids});
            } else if (state?.selectedRow) {
              await updateCoupon(state.selectedRow.id, value);
            } else {
              const result = await createCoupon(value);
              if (result) dispatch({type: 'CLOSE_MODAL'});
              else return false;
            }
          }}
          title=''
          type={state.modalType}
          isView={view}
        />
      )}
    </>
  );
};
