import { PageTitle } from '../../../_metronic/layout/core';
import ContactForm from '../../components/common/ContactForm';

const AdminContact = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Contact</PageTitle>
      <ContactForm onSubmit={() => {}}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Contact Form</h3>
          </div>
        </div>
      </ContactForm>
    </>
  );
};

export default AdminContact;
