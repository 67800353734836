import axios from 'axios';
import {
  BASE_URL,
  PAGINATION,
  USERS_URL,
  USERS__CREATE_URL,
  USERS__UPDATE_URL,
  VERIFIED_FILTER,
  SEARCH,
  STATUS_FILTER,
  GET_USER_CARD_URL,
  REMOVE_USER_CARD_URL,
  CHANGE_DEFAULT_USER_CARD_URL,
  DATE_FILTER,
  COUPON_LISTING,
  ADD_COUPON,
  DELETE_COUPON,
  UPDATE_COUPON,
} from './Urls';
import {IParamsInfo} from './index.d';
export class UserApiModule {
  constructor() {}
  async getSingleUser(userId) {
    try {
      const response = await axios.get(BASE_URL + USERS_URL + '/' + userId);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getUsers(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(USERS_URL, paramInfo.pageNo, paramInfo?.pageSize) +
          VERIFIED_FILTER(paramInfo.verifiedFilter ?? '') +
          STATUS_FILTER(paramInfo?.statusFilter) +
          SEARCH(paramInfo?.searchKeyword) +
          DATE_FILTER(paramInfo?.startDate, paramInfo?.endDate)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteUsers(userIds) {
    try {
      const response = await axios.delete(BASE_URL + USERS_URL, {headers: {}, data: userIds});
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getUserCard(userData) {
    try {
      const response = await axios.post(BASE_URL + GET_USER_CARD_URL, userData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async removeUserCard(cardData) {
    try {
      const response = await axios.post(BASE_URL + REMOVE_USER_CARD_URL, cardData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async changeDefaultUserCard(cardData) {
    try {
      const response = await axios.post(BASE_URL + CHANGE_DEFAULT_USER_CARD_URL, cardData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async createUser(userData) {
    try {
      const response = await axios.post(BASE_URL + USERS__CREATE_URL, userData);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateUser(userId, userData) {
    try {
      const response = await axios.put(BASE_URL + USERS__UPDATE_URL + '/' + userId, userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getCouponListing(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(COUPON_LISTING, paramInfo.pageNo, paramInfo?.pageSize) +
          `&status=${paramInfo.statusFilter}` +
          SEARCH(paramInfo.searchKeyword) +
          DATE_FILTER(paramInfo?.startDate, paramInfo?.endDate)
      );

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response?.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }
  async createCoupon(userData) {
    try {
      const response = await axios.post(BASE_URL + ADD_COUPON, userData);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteCoupon(couponIds) {
    try {
      const response = await axios.delete(BASE_URL + DELETE_COUPON, {data: couponIds});
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateCoupon(Id, couponDetails) {
    try {
      const response = await axios.put(BASE_URL + UPDATE_COUPON + '/' + Id, couponDetails);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }
}
