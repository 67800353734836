import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAuth } from '../../modules/auth';

const options = [
  {value: 'chocolate', label: 'Chocolate'},
  {value: 'strawberry', label: 'Strawberry'},
  {value: 'vanilla', label: 'Vanilla'},
];

export const SelectPreferences = ({value, onChange, isDisabled = false, title = ''}) => {
  const {restaurantModule} = useAuth();
  const [cusineData, setCusineData] = useState([]);
  const getAllPrefrenses = async () => {
    const myData = await restaurantModule?.getRestaurantsCusines();
    setCusineData(myData?.data?.map((t) => ({value: t.id, label: t.name})));
  };
  const theme = localStorage.getItem('kt_theme_mode_menu') || 'light';
  useEffect(() => {
    getAllPrefrenses();
  }, []);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: 'white', // Text color of the control
      backgroundColor: '#1b1b29', // Background color of the control
      borderColor: 'transparent',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'white', // Text color of the options
      backgroundColor: state.isFocused || state.isSelected ? '#1b1b29' : 'transparent', // Background color of the options
      '&:hover': {
        backgroundColor: '#2b2b40', // Background color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white', // Text color of the selected option
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1b1b29',
    }),
  };
  return (
    <>
      <Select
        isDisabled={isDisabled}
        value={cusineData?.filter((singleCusine: any) =>
          value?.find((value) => singleCusine?.value === value.value)
        )}
        menuPlacement='auto'
        name='cuisine'
        menuPosition='fixed'
        isMulti
        // isSearchable
        onChange={onChange}
        options={cusineData}
        styles={theme === 'dark' ? customStyles : {}}
      />
    </>
  );
};
