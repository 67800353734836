import React, {useState} from 'react';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';
import BiddingDataTable from '../../../../components/common/DataTableViews/Bidding';
import {useLocation, useParams} from 'react-router-dom';

function UserBiddings() {
  const [userName, setUserName] = useState('');
  const location = useLocation().pathname.split('/')[1];
  const userid = useParams().userId;
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Bidding',
      path: `${location}/${userid}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: userName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{userName}</PageTitle>
      <BiddingDataTable userId={userid} showUserFilter={false} setUserName={setUserName} />
    </>
  );
}

export default UserBiddings;
