import {convertDateFormat, convertDateToUTC} from '../Helpers';

export const BASE_URL = process.env.REACT_APP_API_URL;

export const RESTAURANT_BASE_URL = '/restaurant';
export const PAYMENT_BASE_URL = '/payment';
export const USERS_URL = '/admin/user';
export const USERS__CREATE_URL = '/admin/addUser';
export const USERS__UPDATE_URL = '/admin/updateUser';
export const FILTER_LIST = (type) => `/common/getUserForFilter?type=${type}`;

export const GET_USER_CARD_URL = `${PAYMENT_BASE_URL}/getListOfPaymentMethod`;
export const REMOVE_USER_CARD_URL = `${PAYMENT_BASE_URL}/removePaymentMethod`;
export const CHANGE_DEFAULT_USER_CARD_URL = `${PAYMENT_BASE_URL}/updatePaymentMethod`;

export const RESTAURANTS_URL = `${RESTAURANT_BASE_URL}/restaurant`;
export const RESTAURANT_PROFILE_URL = `${RESTAURANT_BASE_URL}/restaurantProfile`;
export const ADD_RESTAURANT_URL = `${RESTAURANT_BASE_URL}/addRestaurant`;
export const RESTAURANT_CUSINE_URL = `${RESTAURANT_BASE_URL}/cuisine`;
export const RESTAURANT__UPDATE_URL = `${RESTAURANT_BASE_URL}/updateRestaurant`;

export const BOOKINGS_URL = '/booking/booking';
export const BOOKINGS_UPADATE_URL = '/booking/manageAttendance';
export const TRANSACTIONS_URL = '/transaction/transaction';
export const BIDDINGS_URL = '/bidding/bidding';

export const MANAGE_ATTENDANCE = `${PAYMENT_BASE_URL}/manageAttendance`;
export const GET_HOST_DETAILS = `/restaurant/getHostContact`;
export const CREATE_HOST = `/restaurant/getHostContact`;

export const REVIEWS_URL = '/rateReport/rating';
export const REPORT_URL = '/rateReport/reporting';

export const TABLES_URL = `${RESTAURANT_BASE_URL}/table`;
export const ADD_TABLE_URL = `${RESTAURANT_BASE_URL}/addTable`;
export const UPDATE_TABLE_URL = `${RESTAURANT_BASE_URL}/updateTable`;

export const LOGIN_URL = '/auth/signin';
export const VERIFY_OTP_URL = '/auth/verifyOtp';
export const RESEND_OTP_URL = '/auth/resendOtp';
export const FORGOT_PASSWORD_URL = '/auth/forgotPassword';
export const RESET_PASSWORD_URL = '/auth/setPassword';
export const UPDATE_PASSWORD = '/auth/updatePassword';
export const GET_USER_DATA = '/common/getProfile';

export const GET_TABLES = '/restaurant/table';
export const GET_AUCTION_TYPE = '/common/getAuctionType';
export const CREATE_AUCTION = '/auction/addAuction';
export const UPDATE_AUCTION = (auctionId) => `/auction/updateAuction/${auctionId}`;
export const GET_AUCTIONS = '/auction/auction';
export const GET_SINGLE_AUCTION = '/auction/auction';
export const GET_RECURRING_AUCTION = '/auction/getAllRecurringAuction';
export const UPDATE_RECURRING_AUCTION = '/auction/updateRecurringAuction';
export const ADD_RECURRING_AUCTION = '/auction/addRecurringAuction';
export const GET_SINGLE_RECURRING_AUCTION = '/auction/getRecurringAuction';
export const DELETE_SINGLE_RECURRING_AUCTION = '/auction/deleteRecurringAuction';
export const UPDATE_SINGLE_RECURRING_AUCTION = (auctionId) =>
  `/auction/updateRecurringAuction/${auctionId}`;

export const CREATE_CONTACT = '/common/contactUs';
export const GET_CONTACT = '/common/contactUsQueries';
export const UPDATE_CONTACT = '/common/markAsRead/';

export const GET_DASHBOARD = '/common/dashboard';

// for pagination, filters & Search helpers
export const PAGINATION = (url, pageNo, pageSize = 10, separator = '?') =>
  `${url}${separator}page=${pageNo}&pageSize=${pageSize}`;
export const VERIFIED_FILTER = (isVerified, separator = '&') =>
  `${separator}isVerified=${isVerified ?? ''}`;
export const USER_ID_FILTER = (id, separator = '&') => `${separator}userId=${id || ''}`;
export const RESTAURANT_ID_FILTER = (id, separator = '&') => `${separator}restaurantId=${id || ''}`;
export const AUCTION_ID_FILTER = (id, separator = '&') => `${separator}auctionId=${id || ''}`;
export const STATUS_FILTER = (status, separator = '&') => `${separator}status=${status || ''}`;
export const CAPACITY_FILTER = (capacity, separator = '&') =>
  `${separator}capacity=${capacity || ''}`;
export const SEARCH = (search, separator = '&') => `${separator}search=${search || ''}`;
export const DATE_FILTER = (startDate, endDate, separator = '&') => {
  if (startDate && endDate) {
    startDate = convertDateFormat(startDate);
    endDate = convertDateFormat(endDate);
    let inputDate = new Date(endDate);
    inputDate.setDate(inputDate.getDate() + 1);
    endDate = inputDate.toISOString().split('T')[0];
    return `${separator}startDate=${convertDateToUTC(startDate) || ''}&endDate=${
      convertDateToUTC(endDate, true) || ''
    }`;
  } else {
    return '';
  }
};
export const MIN_MAX_PRICE_FILTER = (minPrice, maxPrice, separator = '&') =>
  `${separator}minPrice=${minPrice || ''}&maxPrice=${maxPrice || ''}`;
export const RATING_FILTER = (rating, separator = '&') => `${separator}rating=${rating || ''}`;
export const AUCTION_DATE_FILTER = (dineDateRangeStart, dineDateRangeEnd, separator = '&') => {
  if (dineDateRangeStart && dineDateRangeEnd) {
    dineDateRangeStart = convertDateFormat(dineDateRangeStart);
    dineDateRangeEnd = convertDateFormat(dineDateRangeEnd);
    let inputDate = new Date(dineDateRangeEnd);
    inputDate.setDate(inputDate.getDate() + 1);
    dineDateRangeEnd = inputDate.toISOString().split('T')[0];
  }
  return `${separator}dineDateRangeStart=${
    convertDateToUTC(dineDateRangeStart) || ''
  }&dineDateRangeEnd=${convertDateToUTC(dineDateRangeEnd, true) || ''}`;
};

export const TYPE_FILTER = (type, separator = '&') => `${separator}type=${type || ''}`;
export const ATTENDANCE_FILTER = (attendance, separetor = '&') => {
  if (attendance === 'pending') {
    return `${separetor}attendance=`;
  } else if (attendance === 'present' || attendance === 'absent') {
    return `${separetor}attendance=${attendance}`;
  }
  return '';
};

export const COUPON_LISTING = '/couponCode/couponCode';
export const ADD_COUPON = '/couponCode/addCouponCode';
export const UPDATE_COUPON = '/couponCode/updateCouponCode';
export const DELETE_COUPON = '/couponCode/couponCode';

export const AUCTION_TYPE_LISTING = '/auction/auctionType';
export const ADD_AUCTION_TYPE = '/auction/addAuctionType';
export const DELETE_AUCTION_TYPE = '/auction/auctionType';
export const UPDATE_AUCTION_TYPE = `/auction/auctionType`;

export const RESTAURANT_SEND_OTP_VERIFICATION = '/common/sendOtp';
export const RESTAURANT_VERIFY_OTP_VERIFICATION = '/common/verifyOtp';
export const ADD_HOST_CONTACT = '/restaurant/addHostContact';
export const EDIT_HOST_CONTACT = '/restaurant/updateHostContact';
export const DELETE_HOST_CONTACT = '/restaurant/deleteHostContact';
