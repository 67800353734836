import axios from 'axios';
import {
  BASE_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  RESEND_OTP_URL,
  RESET_PASSWORD_URL,
  UPDATE_PASSWORD,
  VERIFY_OTP_URL,
} from './Urls';

export class AuthApiModule {
  constructor() {}
  async login(email, password) {
    try {
      const response = await axios.post(BASE_URL + LOGIN_URL, {email, password, platform : 'web_app'});
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }

  async forgotPassword(email) {
    try {
      const response = await axios.post(BASE_URL + FORGOT_PASSWORD_URL, {
        email,
        type: 'website',
      });
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }

  async resetPassword(userId, resetPasswordCode, password) {
    try {
      const response = await axios.post(
        BASE_URL + RESET_PASSWORD_URL + '/' + userId + '/' + resetPasswordCode,
        {
          password,
        }
      );
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }

  async resendOtp(email) {
    try {
      const response = await axios.post(BASE_URL + RESEND_OTP_URL, {
        email,
      });
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }

  async verifyOtp(email, otp) {
    try {
      const response = await axios.post(BASE_URL + VERIFY_OTP_URL, {
        email,
        otp,
      });
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }

  async updatePassword(paramInfo) {
    try {
      const response = await axios.post(BASE_URL + UPDATE_PASSWORD, paramInfo);
      return {
        status: response.status,
        success: response.data.success,
        data: response.data?.data,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error?.message || 'Something went wrong',
      };
    }
  }
}
