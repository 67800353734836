import {useEffect, useReducer} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../../_metronic/helpers';
import {useThemeMode} from '../../../../../_metronic/partials';
import DataTableWrapper from '../../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../../Helpers';
import {CustomModal} from '../../../../components/Modal';
import {CommonRowInTable, CommonViewInHeader} from '../../../../components/common/DataTableViews';
import {useAuth} from '../../../../modules/auth';
import reducerFunction from './actions';
import {TInitialState} from './index.d';
import {TRUE} from 'sass';

const initialState: TInitialState = {
  loading: true,
  pageSize: 10,
  data: [],
  currentPage: 1,
  totalPages: 1,
  totalData: -1,
  error: null,
  search: '',
  isModalOpen: false,
  selectedRow: null,
  capacity: '',
};

const TableDataTable = () => {
  const [state, dispatch] = useReducer(reducerFunction, initialState);
  const {mode} = useThemeMode();
  const {currentUser} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const [searchParams] = useSearchParams();
  const restaurantId = searchParams.get('restaurant');
  const param = useParams();
  const {restaurantModule} = useAuth();

  let columns = [
    {
      name: <CommonViewInHeader name='Capacity' />,
      selector: (row, i) => row.capacity,
      sortable: true,
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.capacity + ' Persons'}
            classes={`${isAdmin ? 'text-theme-color' : 'text-dark'}`}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.price,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='Edit table'
              className='linkIcon'
              onClick={() => {
                if (row?.deletable === 'false') {
                  toast.error(
                    'Can’t edit this table as it has ongoing auctions or upcoming reservation.'
                  );                } else {
                  dispatch({type: 'SELECT_ROW', payload: row});
                  dispatch({type: 'OPEN_MODAL', payload: 'TABLE'});
                }
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </span>

            <span
              title='Delete table'
              className='linkIcon'
              onClick={() => {
                if (row?.deletable === 'false') {
                  toast.error(
                    'Can’t delete this table as it has ongoing auctions or upcoming reservation.'
                  );
                } else {
                  dispatch({type: 'SELECT_ROW', payload: row});
                  dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
                }
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  columns = isAdmin
    ? [
        {
          name: <CommonViewInHeader name='Restaurant Name' classes='ms-8' />,
          selector: (row, i) => row.restaurants.name,
          sortable: true,
          format: (row, i) => {
            return (
              <CommonRowInTable
                text={row?.restaurants?.name}
                avatar={row?.restaurants?.images || 'media/avatars/restaurant-blank.png'}
                classes='text-dark'
              />
            );
          },
        },
        ...columns,
      ]
    : columns;

  const getTRestaurantTables = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await restaurantModule?.getRestaurantTables({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      restaurantIdFilter: restaurantId,
      capacity: state.capacity,
    });
    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  useEffect(() => {
    getTRestaurantTables();
  }, [state.currentPage, restaurantId, state.capacity, state.pageSize]);

  const addTRestaurantTables = async (value) => {
    const result = await restaurantModule?.addRestaurantTables(
      isAdmin && param?.restaurantId,
      value
    );
    if (result?.success) {
      toast.success(result?.message || `Table added succesfully`, {});
      dispatch({type: 'CLOSE_MODAL'});
      getTRestaurantTables();
    } else {
      toast.error(result?.message, {});
    }
  };

  const updateTRestaurantTables = async (value) => {
    const result = await restaurantModule?.updateRestaurantTables(value);
    if (result?.success) {
      toast.success(result?.message || `Table updated succesfully`, {});
      getTRestaurantTables();
      dispatch({type: 'CLOSE_MODAL'});
    } else {
      toast.error(result?.message, {});
    }
  };

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary'
      onClick={() => dispatch({type: 'OPEN_MODAL', payload: 'TABLE'})}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Table
    </button>
  );

  const deleteTTable = async (tableIds) => {
    const result = await restaurantModule?.deleteTables(tableIds);
    dispatch({type: 'CLOSE_MODAL'});
    if (result?.success) {
      toast.success(`Table deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      getTRestaurantTables();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  return (
    <>
      <DataTableWrapper
        totalData={state.totalData}
        subHeader={subHeader}
        pageSize={state.pageSize}
        selectableRows={true}
        loading={state.loading}
        columns={columns}
        handleSelected={(selected) => {
          dispatch({type: 'SELECT_ROW', payload: selected});
          dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
        }}
        data={state.data}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        filters={{
          capacity: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
        }}
        isFilterApplied={Boolean(state.capacity)}
      />
      {state.isModalOpen && (
        <CustomModal
          title={'Add Table'}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          optionalText={
            state?.selectedRow?.hasRecurring
              ? 'Are you sure you want to delete this table? It currently has recurring auctions scheduled. If you proceed, then recurring auction for this table will also be deleted.'
              : ''
          }
          handleSaveCallback={async (value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state?.selectedRow?.id];
              deleteTTable({ids});
              dispatch({type: 'CLOSE_MODAL'});
            } else if (state.selectedRow) {
              await updateTRestaurantTables(value);
            } else {
              let restaurantData = value;
              if (restaurantId) {
                restaurantData = {...value, restaurantId};
              }
              await addTRestaurantTables(restaurantData);
            }
          }}
          type={state.modalType}
        />
      )}
    </>
  );
};

export default TableDataTable;
