import axios from 'axios';
import {
  ADD_RECURRING_AUCTION,
  AUCTION_DATE_FILTER,
  AUCTION_TYPE_LISTING,
  BASE_URL,
  CAPACITY_FILTER,
  CREATE_AUCTION,
  DELETE_SINGLE_RECURRING_AUCTION,
  GET_AUCTION_TYPE,
  GET_AUCTIONS,
  GET_RECURRING_AUCTION,
  GET_SINGLE_AUCTION,
  GET_SINGLE_RECURRING_AUCTION,
  GET_TABLES,
  PAGINATION,
  RESTAURANT_ID_FILTER,
  SEARCH,
  STATUS_FILTER,
  UPDATE_AUCTION,
  UPDATE_RECURRING_AUCTION,
  UPDATE_SINGLE_RECURRING_AUCTION,
} from './Urls';
import {IParamsInfo} from './index.d';

export class AuctionApiModule {
  constructor() {}

  async getAllTables() {
    try {
      const response = await axios.get(BASE_URL + GET_TABLES);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAllTablesAdmin(restaurantId: string) {
    try {
      const response = await axios.get(`${BASE_URL + GET_TABLES}?restaurantId=${restaurantId}`);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async createAuction(auctionData) {
    try {
      const response = await axios.post(BASE_URL + CREATE_AUCTION, auctionData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async createRecurringAuction(auctionData) {
    try {
      const response = await axios.post(BASE_URL + ADD_RECURRING_AUCTION, auctionData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateSingleRecurringAuction(auction_id, auctionData) {
    try {
      const response = await axios.put(
        BASE_URL + UPDATE_SINGLE_RECURRING_AUCTION(auction_id),
        auctionData
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
  async updateAuction(auction_id, auctionData) {
    try {
      const response = await axios.put(BASE_URL + UPDATE_AUCTION(auction_id), auctionData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAuctions(paramsInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(GET_AUCTIONS, paramsInfo?.pageNo, paramsInfo?.pageSize) +
          SEARCH(paramsInfo?.searchKeyword) +
          STATUS_FILTER(paramsInfo.statusFilter) +
          RESTAURANT_ID_FILTER(paramsInfo.restaurantIdFilter) +
          CAPACITY_FILTER(paramsInfo.capacity) +
          AUCTION_DATE_FILTER(paramsInfo.dineDateRangeStart, paramsInfo.dineDateRangeEnd)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRecurringAuctions(paramsInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(GET_RECURRING_AUCTION, paramsInfo?.pageNo, paramsInfo?.pageSize) +
          SEARCH(paramsInfo?.searchKeyword) +
          STATUS_FILTER(paramsInfo.statusFilter) +
          RESTAURANT_ID_FILTER(paramsInfo.restaurantIdFilter) +
          CAPACITY_FILTER(paramsInfo.capacity)
      );

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateRecurringAuctions(userId, userData) {
    try {
      const response = await axios.put(
        BASE_URL + UPDATE_RECURRING_AUCTION + '/' + userId,
        userData
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getSingleAuctions(paramsInfo: IParamsInfo) {
    try {
      const response = await axios.get(BASE_URL + GET_SINGLE_AUCTION + '/' + paramsInfo);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getSingleRecurringAuctions(paramsInfo: IParamsInfo) {
    try {
      const response = await axios.get(BASE_URL + GET_SINGLE_RECURRING_AUCTION + '/' + paramsInfo);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteAuctions(auctionIds) {
    try {
      const response = await axios.delete(BASE_URL + GET_SINGLE_AUCTION, {
        headers: {},
        data: auctionIds,
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteRecurringAuctions(reucurringAuctionIds) {
    try {
      const response = await axios.delete(BASE_URL + DELETE_SINGLE_RECURRING_AUCTION, {
        headers: {},
        data: reucurringAuctionIds,
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAuctionType() {
    try {
      const response = await axios.get(`${BASE_URL + AUCTION_TYPE_LISTING}?list=true`);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response?.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAuctionTypeAdmin(resId:string) {
    try {
      const response = await axios.get(`${BASE_URL + AUCTION_TYPE_LISTING}?restaurantId=${resId}&list=true`);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response?.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
}
