import {useEffect, useReducer} from 'react';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import {useThemeMode} from '../../../../_metronic/partials';
import DataTableWrapper from '../../../DataTable';
import {convertUtcToLocal, debounce} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import {CommonRowInTable, CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useAuth} from '../../../modules/auth';
import reducerFunc from './action';

export interface InitialState {
  loading: boolean;
  currentPage: number;
  pageSize: number;
  data: any[];
  totalData: number;
  totalPages: number;
  selectedRow: any;
  error: null | any;
  search: string;
  isModalOpen: boolean;
  changeStatusId?: null | string;
  typeFilter: string;
  statusFilter: string;
  startDate: string;
  endDate: string;
  userFilter: string;
  restaurantFilter: string;
  modalType: string;
}

const initialState: InitialState = {
  loading: true,
  data: [],
  totalData: -1,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  isModalOpen: false,
  error: null,
  selectedRow: null,
  search: '',
  changeStatusId: null,
  typeFilter: '',
  statusFilter: '',
  startDate: '',
  endDate: '',
  userFilter: '',
  restaurantFilter: '',
  modalType: 'CONTACT',
};

const Contact = () => {
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  const {mode} = useThemeMode();
  const {currentUser, contactModule} = useAuth();

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary'
      onClick={() => dispatch({type: 'OPEN_MODAL', payload: 'CONTACT'})}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Contact us
    </button>
  );

  const columns = [
    {
      name: <CommonViewInHeader name='Name' classes='ms-2' />,
      selector: (row, i) => row?.user?.restaurant?.name || row?.user?.firstName,
      sortable: true,
      width: '250px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.user?.restaurant?.name || `${row?.user?.firstName} ${row?.user?.lastName}`}
            // navigateURL={`${CONTACT_ROUTE}/${row.restaurantName}`}
            avatar={
              row?.user?.restaurant
                ? row?.user?.restaurant?.images ?? 'media/avatars/restaurant-blank.png'
                : row?.user?.profilePicture ?? 'media/avatars/blank.png'
            }
            classes='text-dark text-capitalize'
            onClick={async () => {
              row.status !== 'viewed' && (await contactModule?.UpdateContact(row.id));
              row.status !== 'viewed' && getTContact();
              dispatch({type: 'OPEN_MODAL', payload: 'CONTACTDETAILS'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Email' />,
      selector: (row, i) => row?.user?.email,
      width: '250px',
      sortable: true,
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.user?.email}
            classes='text-theme-color'
            onClick={async () => {
              row.status !== 'viewed' && (await contactModule?.UpdateContact(row.id));
              row.status !== 'viewed' && getTContact();
              dispatch({type: 'OPEN_MODAL', payload: 'CONTACTDETAILS'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='date' />,
      selector: (row, i) => row.created_at,
      sortable: true,
      width: '250px',

      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row.created_at)}
            classes='text-theme-color text-uppercase'
            onClick={async () => {
              row.status !== 'viewed' && (await contactModule?.UpdateContact(row.id));
              row.status !== 'viewed' && getTContact();
              dispatch({type: 'OPEN_MODAL', payload: 'CONTACTDETAILS'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      width: '170px',
      sortable: true,
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.status}
            classes='text-theme-color px-4 py-2 badge-light text-capitalize cursor-default mb-1 fs'
            onClick={async () => {
              row.status !== 'viewed' && (await contactModule?.UpdateContact(row.id));
              row.status !== 'viewed' && getTContact();
              dispatch({type: 'OPEN_MODAL', payload: 'CONTACTDETAILS'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.status,
      width: '120ox',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='View query'
              className='linkIcon'
              onClick={async () => {
                row.status !== 'viewed' && (await contactModule?.UpdateContact(row.id));
                row.status !== 'viewed' && getTContact();
                dispatch({type: 'OPEN_MODAL', payload: 'CONTACTDETAILS'});
                dispatch({type: 'SELECT_ROW', payload: row});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getTContact = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await contactModule?.getContact({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      statusFilter: state.statusFilter,
      type: state.typeFilter,
      startDate: state.startDate,
      endDate: state.endDate,
      userIdFilter: state.userFilter,
      restaurantIdFilter: state.restaurantFilter,
    });

    dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
    dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
  };
  useEffect(() => {
    getTContact();
  }, [
    state.currentPage,
    state.search,
    state.pageSize,
    state.statusFilter,
    state.typeFilter,
    state.startDate,
    state.endDate,
    state.userFilter,
    state.restaurantFilter,
  ]);

  let dropDownFilter = [
    {
      label: 'Status',
      options: ['Viewed', 'Pending'].map((t) => ({value: t.toLowerCase(), label: t})),
    },
  ];
  let isFilterApplied = state.userFilter || state.search || state.statusFilter || state.endDate;
  if (currentUser?.role === 'ADMIN') {
    dropDownFilter = [
      ...dropDownFilter,
      {
        label: 'Type',
        options: ['Restaurant', 'User'].map((t) => ({value: t.toUpperCase(), label: t})),
      },
    ];
    isFilterApplied = isFilterApplied || state.typeFilter || state.restaurantFilter;
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Contact</PageTitle>
      <DataTableWrapper
        subHeader={currentUser?.role === 'ADMIN' ? subHeader : ''}
        totalData={state.totalData}
        loading={state.loading}
        columns={columns}
        pageSize={state.pageSize}
        data={state.data}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(search) => {
          debounce(dispatch({type: 'SEARCH_CHANGE', payload: search}));
        }}
        searchValue={state.search}
        handlePageChange={(page) => dispatch({type: 'CHANGE_PAGE', payload: page})}
        filters={{
          dropdown: dropDownFilter,
          userFilter: true,
          restaurantFilter: currentUser?.role === 'ADMIN',
          date: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
          dispatch({type: 'SET_USER', payload: value.userFilter?.value});
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: value.startDate, endDate: value.endDate},
          });
          dispatch({type: 'SET_TYPE', payload: value.Type?.value});
          dispatch({type: 'SET_STATUS', payload: value.Status?.value});
        }}
        isFilterApplied={isFilterApplied}
      />
      {state.isModalOpen && (
        <CustomModal
          title={'Contact Details'}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL', payload: null})}
          handleSaveCallback={() => {
            dispatch({type: 'CLOSE_MODAL', payload: null});
          }}
          type={state.modalType}
        />
      )}
    </>
  );
};

export default Contact;
