import {FC, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import {DashboardForAdmin} from '../pages/Admin/Dashboard';
import Restaurant from '../pages/Admin/Restautant/';
import RestaurantDetails from '../pages/Admin/Restautant/RestaurantDetails';
import RestaurantTables from '../pages/Admin/Restautant/RestaurantTables';
import {UsersForAdmin} from '../pages/Admin/User';

import {useThemeMode} from '../../_metronic/partials';
import {
  ADMIN_CONTACT,
  AUCTION_DETAILS_ROUTE,
  AUCTION_ROUTE,
  AUCTION_TYPE_ROUTE,
  BIDDING_ROUTE,
  BOOKING_ROUTE,
  CONTACT_DETAILS_ROUTE,
  CONTACT_ROUTE,
  COUPON_LIST,
  DASHBOARD_ROUTE,
  PROFILE_ROUTE,
  REPORTS_ROUTE,
  RESTAURANTS_ROUTE,
  RESTAURANT_AUCTION_ROUTE,
  RESTAURANT_BIDDING_ROUTE,
  RESTAURANT_BOOKING_ROUTE,
  RESTAURANT_DETAILS_ROUTE,
  RESTAURANT_TRANSACTION_ROUTE,
  REVIEWS_ROUTE,
  TABLES_ROUTE,
  TRANSACTIONS_ROUTE,
  USERS_BIDDING_ROUTE,
  USERS_BOOKING_ROUTE,
  USERS_DETAILS_ROUTE,
  USERS_ROUTE,
  USERS_TRANSACTION_ROUTE,
} from '../Helpers';
import Profile from '../components/Sections/common/AdminProfile';
import AuctionDetailsForAdmin from '../components/common/DataTableViews/Auction/AuctionDetails';
import AuctionForAdmin from '../pages/Admin/Auctions';
import AdminBidding from '../pages/Admin/Bidding';
import {BookingForAdmin} from '../pages/Admin/Bookings';
import Contact from '../pages/Admin/Contact';
import ContactDetails from '../pages/Admin/Contact/ContactDetails';
import ReportDataTable from '../pages/Admin/Report';
import SingleRestaurantAuctions from '../pages/Admin/Restautant/Auctions';
import RestaurantBiddings from '../pages/Admin/Restautant/Biddings';
import SingleRestaurantBookings from '../pages/Admin/Restautant/Bookings';
import SingleRestaurantTransactions from '../pages/Admin/Restautant/Transactions';
import Reviews from '../pages/Admin/Reviews';
import RestaurantTransactions from '../pages/Admin/Transactions';
import UserBiddings from '../pages/Admin/User/Biddings';
import SingleUserBookings from '../pages/Admin/User/Bookings';
import SingleUserTransactions from '../pages/Admin/User/Transaction';
import UserDetails from '../pages/Admin/User/UserDetails';
import AdminContact from '../pages/contact-admin';
import {CouponList} from '../pages/Admin/CoupenList';
import {AuctionType} from '../pages/Restaurant/AuctionType';

const AdminRoutes = () => {
  const {mode} = useThemeMode();
  return (
    <div className={`${mode === 'dark' ? 'dark-mode-theme' : 'light-mode-theme'}`}>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to={DASHBOARD_ROUTE} />} />
          <Route
            path={DASHBOARD_ROUTE}
            element={
              <SuspendedView>
                <DashboardForAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <RestaurantDetails />
              </SuspendedView>
            }
          />
          <Route
            path={COUPON_LIST}
            element={
              <SuspendedView>
                <CouponList />
              </SuspendedView>
            }
          />
          <Route
            path={CONTACT_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <ContactDetails />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_ROUTE}
            element={
              <SuspendedView>
                <AuctionForAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_TYPE_ROUTE}
            element={
              <SuspendedView>
                <AuctionType />
              </SuspendedView>
            }
          />
          <Route
            path={REVIEWS_ROUTE}
            element={
              <SuspendedView>
                <Reviews />
              </SuspendedView>
            }
          />
          <Route
            path={REPORTS_ROUTE}
            element={
              <SuspendedView>
                <ReportDataTable />
              </SuspendedView>
            }
          />
          <Route
            path={AUCTION_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <AuctionDetailsForAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={BOOKING_ROUTE}
            element={
              <SuspendedView>
                <BookingForAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={CONTACT_ROUTE}
            element={
              <SuspendedView>
                <Contact />
              </SuspendedView>
            }
          />
          <Route
            path={TRANSACTIONS_ROUTE}
            element={
              <SuspendedView>
                <RestaurantTransactions />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_DETAILS_ROUTE + TABLES_ROUTE}
            element={
              <SuspendedView>
                <RestaurantTables />
              </SuspendedView>
            }
          />
          <Route
            path={TABLES_ROUTE}
            element={
              <SuspendedView>
                <RestaurantTables />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANTS_ROUTE}
            element={
              <SuspendedView>
                <Restaurant />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_BIDDING_ROUTE}
            element={
              <SuspendedView>
                <RestaurantBiddings />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_TRANSACTION_ROUTE}
            element={
              <SuspendedView>
                <SingleRestaurantTransactions />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_BOOKING_ROUTE}
            element={
              <SuspendedView>
                <SingleRestaurantBookings />
              </SuspendedView>
            }
          />
          <Route
            path={RESTAURANT_AUCTION_ROUTE}
            element={
              <SuspendedView>
                <SingleRestaurantAuctions />
              </SuspendedView>
            }
          />
          <Route
            path={USERS_ROUTE}
            element={
              <SuspendedView>
                <UsersForAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={USERS_BIDDING_ROUTE}
            element={
              <SuspendedView>
                <UserBiddings />
              </SuspendedView>
            }
          />

          <Route
            path={USERS_TRANSACTION_ROUTE}
            element={
              <SuspendedView>
                <SingleUserTransactions />
              </SuspendedView>
            }
          />
          <Route
            path={USERS_BOOKING_ROUTE}
            element={
              <SuspendedView>
                <SingleUserBookings />
              </SuspendedView>
            }
          />
          <Route
            path={USERS_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <UserDetails />
              </SuspendedView>
            }
          />
          <Route
            path={PROFILE_ROUTE}
            element={
              <SuspendedView>
                <Profile />
              </SuspendedView>
            }
          />
          <Route
            path={BIDDING_ROUTE}
            element={
              <SuspendedView>
                <AdminBidding />
              </SuspendedView>
            }
          />
          <Route
            path={ADMIN_CONTACT}
            element={
              <SuspendedView>
                <AdminContact />
              </SuspendedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </div>
  );
};

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {AdminRoutes};
