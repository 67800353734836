import React, {useState} from 'react';
import {BookingDataTable} from '../../../../components/common/DataTableViews/Booking';
import {useLocation, useParams} from 'react-router-dom';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';

function SingleUserBookings() {
  const location = useLocation().pathname.split('/')[1];
  const userId = useParams().userId;
  const [userName, setUserName] = useState('');

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Bookings',
      path: `${location}/${userId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: userName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{userName}</PageTitle>
      <BookingDataTable userId={userId} showUserFilter={false} setUserName={setUserName} />
    </>
  );
}

export default SingleUserBookings;
