import {useFormik} from 'formik';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers';
import {
  API_ERROR_MESSAGE,
  EDIT_ERROR_IMG_RESTAURANT,
  EDIT_ERROR_RESTAURANT,
  EMAIL_NOT_EDITABLE,
  TOAST_GENERAL,
} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';
import {SelectPreferences} from '../../common/SelectPreferences';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {Autocomplete, LoadScript} from '@react-google-maps/api';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const countries = defaultCountries.filter((country) => {
  const {iso2} = parseCountry(country);
  return [
    'us',
    'am',
    'au',
    'ua',
    'gb',
    'in',
    'fr',
    'tr',
    'by',
    'ca',
    'ie',
    'is',
    'ht',
    'fi',
    'ar',
    'uk',
  ].includes(iso2);
});
export interface ICUISINE {
  value: string;
  label: string;
}
export interface IProfileDetails {
  restaurantName: string;
  description: string;
  location: string;
  city?: string;
  state?: string;
  country?: string;
  status: string;
  cuisines: ICUISINE[];
  email: string;
  avatar: string;
  newPassword: string;
  confirmPassword: string;
  images: string;
  phoneNumber: string;
  imageFile: undefined | File;
  address: {
    street: any;
    city: string;
    state: string;
    country: string;
    coordinates: {
      lat: string;
      long: string;
    };
  };
}

const initialValues: IProfileDetails = {
  restaurantName: '',
  email: '',
  avatar: '/media/avatars/300-1.jpg',
  description: '',
  location: '',
  status: 'ACTIVE',
  cuisines: [],
  phoneNumber: '',
  newPassword: '',
  confirmPassword: '',
  images: '',
  imageFile: undefined,
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    coordinates: {lat: '0', long: '0'},
  },
};

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

const profileDetailsSchema = Yup.object().shape({
  restaurantName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    // .matches(/^[a-zA-Z\s]+$/, 'Only letters and spaces are allowed')
    .required('Restaurant Name is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters')
    .max(500, 'Description must not exceed 500 characters'),
  city: Yup.string().required('Location is required'),
  state: Yup.string().required('Location is required'),
  country: Yup.string().required('Location is required'),
  address: Yup.object().shape({
    city: Yup.string().required('Please enter complete address'),
    state: Yup.string().required('Please enter complete address'),
    country: Yup.string().required('Please enter complete address'),
  }),
  // city: Yup.string()
  //   .required('City is required')
  //   .min(3, 'Minimum 3 characters')
  //   .max(30, 'Maximum 30 characters'),
  // location: Yup.string()
  //   .required('Location is required')
  //   .min(3, 'Minimum 3 characters')
  //   .max(30, 'Maximum 30 characters'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  confirmPassword: Yup.mixed()
    .test('match', 'Passwords must match', function (value) {
      return value === this.parent.newPassword || value == null;
    })
    .nullable(),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('isValidPhoneNumber', 'Phone number is not valid', function (value) {
      return isPhoneValid(value as string);
    }),
});

function RestaurantProfileDetails({
  setRestaurantName,
  restaurantId,
  toastId,
  setRatingReport,
  setRestautantLoadDelStatus,
  restaurantLoadDelStatus,
}) {
  const [addressError, setAddressError] = useState('');
  const [err, setErr] = useState(false);
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [inputLocation, setInputLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const {restaurantModule} = useAuth();
  const path: any = localStorage.getItem('path');
  const [val, setValue] = useState('');

  const getTSingleRestaurant = async () => {
    const result = await restaurantModule?.getSingleRestaurant(restaurantId);
    if (result?.success) {
      if (result?.data?.restaurant) {
        const locValue = {
          street: result?.data?.restaurant?.street,
          city: result?.data?.restaurant?.city,
          state: result?.data?.restaurant?.state,
          country: result?.data?.restaurant?.country,
          coordinates: {
            lat: result?.data?.restaurant?.latitude,
            long: result?.data?.restaurant?.longitude,
          },
        };
        if (locValue) {
          formik.setValues({
            ...initialValues,
            email: result.data.email,
            restaurantName: result.data.restaurant.name,
            description: result.data.restaurant.description,
            phoneNumber: result.data.restaurant.phoneNumber || '',
            state: result.data.restaurant.state,
            city: result.data.restaurant.city,
            country: result.data.restaurant.country,
            address: {
              ...formik.values.address,
              street: locValue?.street,
              city: locValue?.city,
              state: locValue?.state,
              country: locValue?.country,
              coordinates: {
                lat: locValue?.coordinates?.lat,
                long: locValue?.coordinates?.long,
              },
            },
            status: result.data.status,
            cuisines: result.data.restaurant.cuisine?.map((cuisine) => ({
              value: cuisine.id,
              label: cuisine.name,
            })),
            images: result.data.restaurant.images,
            imageFile: undefined,
          });
        }
      }

      setRatingReport({
        rating: +result.data.rating || 0,
        report: result.data.report,
      });
      setRestaurantName(`${result.data?.restaurant?.name}`);
    }
    setRestautantLoadDelStatus({
      isLoading: false,
      isDeleted: Boolean(result?.data?.restaurant?.deletedAt),
    });
  };

  useEffect(() => {
    getTSingleRestaurant();
  }, []);

  const onPlaceChanged = () => {
    setValue('');
    setErr(false);
    if (autoComplete !== null) {
      const place = autoComplete?.getPlace();
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place);
        const {city, state, country} = address;

        formik.setValues({
          ...formik.values,
          address: {
            ...formik.values.address,
            street: lane,
            city,
            state,
            country,
            coordinates: {
              lat: place?.geometry.location.lat().toString(),
              long: place?.geometry.location.lng().toString(),
            },
          },
        });
        if (!city || !state || !country) {
          setAddressError('Please enter complete address');

          formik.setFieldError('address.city', 'Please enter complete address');
          formik.setFieldError('address.state', 'Please enter complete address');
          formik.setFieldError('address.country', 'Please enter complete address');
        } else {
          formik.setFieldError('address.city', '');
          formik.setFieldError('address.state', '');
          formik.setFieldError('address.country', '');
        }
      }
    }
  };

  const getAddressComponents = (place) => {
    const address: any = {};
    let lane = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'locality':
          address.city = component.long_name;
          break;

        case 'administrative_area_level_1':
          address.state = component.long_name;
          break;

        case 'country':
          address.country = component.long_name;
          break;

        case 'neighborhood':
        case 'premise':
        case 'sublocality':
        case 'route':
          lane += component.long_name + ', ';
          break;
        default:
          break;
      }
    }
    lane = lane.slice(0, -2);
    return {address, lane};
  };

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (value) => {
      if (err) {
        setAddressError('Location is required.');
        formik.setFieldError('address.city', 'Location is required.');
        return;
      }

      if (formik?.values?.address?.city?.length < 2) {
        setAddressError('Location is required.');
        formik.setFieldError('address.city', 'Location is required.');
        return;
      }
      if (val.length > 1) {
        setAddressError(
          'No match found for the entered address. Please select from the dropdown or revise your input.'
        );        return;
      }
      if (
        !formik?.values?.address?.city ||
        !formik?.values?.address?.state ||
        !formik?.values?.address?.country
      ) {
        setAddressError('Please enter complete address');

        formik.setFieldError('address.city', 'Please enter complete address');
        formik.setFieldError('address.state', 'Please enter complete address');
        formik.setFieldError('address.country', 'Please enter complete address');
      } else {
        setLoading(true);
        const restaurantData = new FormData();
        restaurantData.append('name', value.restaurantName);
        restaurantData.append('description', value.description);
        restaurantData.append('street', value?.address?.street ? value?.address?.street : null);
        restaurantData.append('city', value.address.city);
        restaurantData.append('state', value.address.state);
        restaurantData.append('country', value.address.country);
        restaurantData.append('phoneNumber', value?.phoneNumber);
        restaurantData.append('latitude', value?.address?.coordinates?.lat);
        restaurantData.append('longitude', value?.address?.coordinates?.long);

        let arr: any = [];
        if (value?.cuisines?.length > 0) {
          value.cuisines.forEach((cuisine: any) => {
            arr.push(cuisine?.value);
          });
        }
        restaurantData.append('cuisine', JSON.stringify(arr));
        if (value.imageFile) restaurantData.append('image', value.imageFile);
        if (value.imageFile === null) restaurantData.append('images', '');
        if (value.newPassword) restaurantData.append('password', value.newPassword);

        await updateTRestaurant(restaurantId, restaurantData);
        setLoading(false);
      }
    },
  });

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const [searchParams] = useSearchParams();
  let isEditMode = searchParams.get('edit') === 'true';
  if (restaurantLoadDelStatus.isDeleted && isEditMode) {
    isEditMode = false;
  }
  const navigate = useNavigate();
  const updateTRestaurant = async (restaurantId, restaurantData) => {
    const result = await restaurantModule?.updateRestaurant(restaurantId, restaurantData);
    if (result?.success) {
      toast.success(`Restaurant Info Updated succesfully`, {
        toastId: TOAST_GENERAL,
      });
      navigate(path ? path : '/restaurants');
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId,
      });
    }
  };
  // const img = formik.values.imageFile
  //   ? URL.createObjectURL(formik.values.imageFile)
  //   : formik.values.images || toAbsoluteUrl('/media/avatars/restaurant-blank.png');

    const img = useMemo(
      () =>
        formik.values.imageFile
          ? URL.createObjectURL(formik.values.imageFile)
          : formik.values.images || toAbsoluteUrl('/media/avatars/blank.png'),
      [formik.values.imageFile, formik.values.images])
      
  return (
    <>
      {formik.values.address.city !== '' && (
        <form onSubmit={formik.handleSubmit} noValidate className='form' autoComplete='off'>
          <div className='card-body  p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl('/media/avatars/restaurant-blank.png')})`,
                  }}
                  title={isEditMode ? '' : EDIT_ERROR_IMG_RESTAURANT}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url(${img})`,
                    }}
                  ></div>
                  {isEditMode && (
                    <>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        htmlFor='restaurantImage'
                      >
                        <i className='ki-duotone ki-pencil fs-7'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>

                        <input
                          type='file'
                          id='restaurantImage'
                          name='avatar'
                          accept='.png, .jpg, .jpeg, .webp'
                          onChange={(e: any) => {
                            formik.setFieldValue('imageFile', e.target.files[0]);
                          }}
                        />
                      </label>
                      {(formik.values.imageFile || formik.values.images) && (
                        <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='remove'
                          onClick={() => {
                            formik.setFieldValue('imageFile', null);
                            formik.setFieldValue('images', '');
                          }}
                        >
                          <i className='ki-duotone ki-cross fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Restaurant Name
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Restaurant name'
                  {...formik.getFieldProps('restaurantName')}
                  maxLength={50}
                  disabled={!isEditMode}
                  title={isEditMode ? '' : EDIT_ERROR_RESTAURANT}
                />
                {formik.touched.restaurantName && formik.errors.restaurantName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.restaurantName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description</label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                  maxLength={500}
                  disabled={!isEditMode}
                  title={isEditMode ? '' : EDIT_ERROR_RESTAURANT}
                ></textarea>

                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Location</label>
              <div className='col-lg-8 fv-row'>
                <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
                  <Autocomplete
                    onLoad={(autocomplete: any) => {
                      setAutoComplete(autocomplete);
                    }}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      placeholder='Location'
                      defaultValue={`${
                        formik?.values?.address?.street == null ||
                        formik?.values?.address?.street == 'null'
                          ? ''
                          : formik?.values?.address?.street && formik?.values?.address?.street + ','
                      }  ${
                        formik?.values?.address?.city == null
                          ? ''
                          : formik?.values?.address?.city && formik?.values?.address?.city + ','
                      } ${
                        formik?.values?.address?.state == null
                          ? ''
                          : formik?.values?.address?.state && formik?.values?.address?.state + ','
                      } ${
                        formik?.values?.address?.country == null
                          ? ''
                          : formik?.values?.address?.country && formik?.values?.address?.country
                      }`}
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      autoComplete='off'
                      onChange={(e) => {
                        setValue(e.target.value);
                        setAddressError('');
                        if (e.target.value.length === 0) {
                          setErr(true);
                        } else {
                          setErr(false);
                        }
                      }}
                      disabled={!isEditMode}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !formik.isSubmitting) {
                          formik.handleBlur(e);
                          e.preventDefault();
                        }
                      }}
                    />
                  </Autocomplete>
                </LoadScript>

                {addressError && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addressError}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid cursor-not-allowed'
                  placeholder='Email'
                  title={EMAIL_NOT_EDITABLE}
                  {...formik.getFieldProps('email')}
                  disabled={true}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Cuisine</label>
              <div className='col-lg-8 fv-row'>
                <SelectPreferences
                  isDisabled={!isEditMode}
                  onChange={(values) => {
                    formik.setFieldValue('cuisines', values);
                  }}
                  value={formik.getFieldProps('cuisines').value}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone Number</label>
              <div className='col-lg-8 fv-row'>
                <PhoneInput
                  defaultCountry='us'
                  value={formik.values.phoneNumber}
                  onChange={(phone) => {
                    formik.setFieldValue('phoneNumber', phone);
                    if (!isPhoneValid(phone)) {
                      if (phone?.length > 5) formik.setFieldTouched('phoneNumber', true);
                      formik.setFieldError('phoneNumber', 'Phone number is not valid');
                    } else {
                      formik.setFieldError('phoneNumber', '');
                      formik.setFieldTouched('phoneNumber', false);
                    }
                  }}
                  countries={countries}
                  inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
                  inputProps={{
                    name: 'phoneNumber',
                    autoComplete: 'off',
                    disabled: formik.isSubmitting || !isEditMode,
                  }}
                  disabled={!isEditMode}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phoneNumber}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isEditMode && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    New Password
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type={showPassword?.newPassword ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Password'
                      {...formik.getFieldProps('newPassword')}
                      disabled={!isEditMode}
                      name='newPassword'
                      autoComplete='new-password'
                    />
                    {showPassword?.newPassword ? (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
                        }
                      >
                        <KTIcon iconName='eye' className='fs-2' />
                      </span>
                    ) : (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
                        }
                      >
                        <KTIcon iconName='eye-slash' className='fs-2' />
                      </span>
                    )}
                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.newPassword}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Confirm New Password
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type={showPassword?.confirmPassword ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Confirm Password'
                      {...formik.getFieldProps('confirmPassword')}
                      disabled={!isEditMode}
                    />
                    {showPassword?.confirmPassword ? (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                      >
                        <KTIcon iconName='eye' className='fs-2' />
                      </span>
                    ) : (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                      >
                        <KTIcon iconName='eye-slash' className='fs-2' />
                      </span>
                    )}
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {isEditMode && (
            <div className=' d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
}

export default RestaurantProfileDetails;
