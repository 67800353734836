import React, {useRef} from 'react';
import {useAuth} from '../../modules/auth';
import {useLocation, useParams} from 'react-router-dom';
import {AsyncPaginate} from 'react-select-async-paginate';

function UserFilter({onChange, value, style, filterType}) {
  const {dashboardModule} = useAuth();
  const location: any = useLocation();
  const params = useParams();

  const path = location.pathname;
  const allOptions = useRef<any>();
  const options = async function (search) {
    if (search && allOptions.current.length) {
      const options: any = allOptions.current.filter((row) => {
        if (row.label) return row.label.toLowerCase().includes(search.toLowerCase());
        return false;
      });
      return {
        options,
        hasMore: false,
      };
    }

    let type;
    if (path === '/bookings' || filterType === 'booking') {
      type = 'booking';
    } else if (path === '/bidding' || filterType === 'bidding' || path.includes('/auction')) {
      type = 'bidding';
    } else if (path === '/reviews') {
      type = 'ratingReview';
    } else if (path === '/report') {
      type = 'issues';
    } else if (path === '/transactions' || filterType === 'transactions') {
      type = 'transaction';
    } else if (path === '/contact') {
      type = 'contactUsQueries';
    } else if (path === '/auction') {
      type = 'auction';
    }

    // const result = await dashboardModule?.getFilterList(type);
    const result = await dashboardModule?.getUserFilterList(type, params?.restaurantId);
    const options: any = result?.data?.user?.map((row) => {
      return {
        value: row.id,
        label: row?.name && row?.name.length > 30 ? row?.name.slice(0, 30) + '...' : row?.name,
      };
    });
    allOptions.current = options;
    return {
      options,
      hasMore: false,
    };
  };

  return (
    <AsyncPaginate
      styles={style}
      isClearable={true}
      className='mb-4 asyncSelectBox filter-dropDown'
      noOptionsMessage={() => 'No options found'}
      placeholder={'User Name'}
      debounceTimeout={500}
      value={value ? value : location?.state?.role === 'admin' ? '' : location?.state}
      loadOptions={options}
      onChange={onChange}
    />
  );
}

export default UserFilter;
