import {useFormik} from 'formik';
import * as Yup from 'yup';
import {KTIcon} from '../../../../_metronic/helpers';
import '../../../DataTable/dataTable.css';
import {TRestaurant} from '../index.d';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {useState} from 'react';
import {LoadScript, Autocomplete} from '@react-google-maps/api';
import clsx from 'clsx';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const countries = defaultCountries.filter((country) => {
  const {iso2} = parseCountry(country);
  return [
    'us',
    'am',
    'au',
    'ua',
    'gb',
    'in',
    'fr',
    'tr',
    'by',
    'ca',
    'ie',
    'is',
    'ht',
    'fi',
    'ar',
    'uk',
  ].includes(iso2);
});
export const initialRestaurant: TRestaurant = {
  email: '',
  restaurantName: '',
  // location: '',
  // phoneNumber: '',
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    coordinates: {lat: '0', long: '0'},
  },
  // agreeToSms: false,
  restaurantImg: undefined,
};

const restaurantSchemaValidations = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(5, 'Email should be of minimum 5 chars long')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
    // .max(40, 'Email should be of maximum 40 chars long')
    .required('Email is required'),
  // city: Yup.string().required('Location is required'),
  // state: Yup.string().required('Location is required'),
  // country: Yup.string().required('Location is required'),
  address: Yup.object().shape({
    city: Yup.string().required('Please enter complete address'),
    state: Yup.string().required('Please enter complete address'),
    country: Yup.string().required('Please enter complete address'),
  }),
  // location: Yup.string()
  //   .required('Location is required')
  //   .min(3, 'Minimum 3 characters')
  //   .max(30, 'Maximum 30 characters')
  //   .matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed'),
  restaurantName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Restaurant Name is required'),
  // phoneNumber: Yup.string()
  //   .required('Phone number is required')
  //   .test('isValidPhoneNumber', 'Phone number is not valid', function (value) {
  //     return isPhoneValid(value as string);
  //   }),
  restaurantImg: Yup.mixed()
    .test('fileSize', 'File size is too large (max 4MB)', (value) => {
      if (!value) {
        return true;
      }
      const file = value as File; // Type assertion
      return file.size <= 4 * 1024 * 1024; // 4MB in bytes
    })
    .test('fileType', 'Invalid file type', (value) => {
      if (!value) {
        // Handle case where no file is selected
        return true;
      }
      const file = value as File; // Type assertion
      return ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type);
    }),
  // agreeToSms: Yup.boolean()
  //   .oneOf([true], 'You must agree to receive a reservation confirmation SMS.')
  //   .required('You must agree to receive a reservation confirmation SMS.'),
  // city: Yup.string()
  //   .required('City is required')
  //   .min(3, 'Minimum 3 characters')
  //   .max(30, 'Maximum 30 characters')
  //   .matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed'),
});

const RestaurantBody = ({
  restaurantDetails,
  handleSaveCallback,
  handleCancelCallback,
  hideCloseModalBtn,
  setHideCloseModalBtn,
}: {
  restaurantDetails: TRestaurant;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
  hideCloseModalBtn: boolean;
  setHideCloseModalBtn: (oldVal) => void;
}) => {
  const [addressError, setAddressError] = useState('');
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [val, setValue] = useState('');
  const [err, setErr] = useState(false);

  const onPlaceChanged = () => {
    setValue('');
    setErr(false);

    if (autoComplete !== null) {
      const place = autoComplete?.getPlace();
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place);
        const {city, state, country} = address;

        formik.setValues({
          ...formik.values,
          address: {
            ...formik.values.address,
            street: lane,
            city,
            state,
            country,
            coordinates: {
              lat: place?.geometry.location.lat().toString(),
              long: place?.geometry.location.lng().toString(),
            },
          },
        });

        if (!city || !state || !country) {
          setAddressError('Please enter complete address');
          formik.setFieldError('address.city', 'Please enter complete address');
          formik.setFieldError('address.state', 'Please enter complete address');
          formik.setFieldError('address.country', 'Please enter complete address');
        } else {
          formik.setFieldError('address.city', '');
          formik.setFieldError('address.state', '');
          formik.setFieldError('address.country', '');
        }
      }
    }
  };

  const getAddressComponents = (place) => {
    const address: any = {};
    let lane = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'locality':
          address.city = component.long_name;
          break;

        case 'administrative_area_level_1':
          address.state = component.long_name;
          break;

        case 'country':
          address.country = component.long_name;
          break;

        case 'neighborhood':
        case 'premise':
        case 'sublocality':
        case 'route':
          lane += component.long_name + ', ';
          break;
        default:
          break;
      }
    }
    lane = lane.slice(0, -2);
    return {address, lane};
  };
  const formik = useFormik({
    initialValues: initialRestaurant,
    validationSchema: restaurantSchemaValidations,
    // validate: (values) => {
    //   if (err) {
    //     setAddressError('Location is required.');
    //     formik.setFieldError('address.city', 'Location is required.');
    //     return;
    //   }
    //   if (val?.length > 1) {
    //     setAddressError(
    //       'No match found for the entered address. Please select from the dropdown or revise your input.'
    //     );
    //     return;
    //   }
    //   if (formik.errors.address?.city) {
    //     setAddressError('Location is required.');
    //     return;
    //   }
    // },
    onSubmit: async (values) => {
      const {email, restaurantName, phoneNumber, restaurantImg} = values;
      if (
        !values?.address?.city.length ||
        !values?.address?.state.length ||
        !values?.address?.country.length
      ) {
        setAddressError('Please enter complete address');

        formik.setFieldError('address.city', 'Please enter complete address');
        formik.setFieldError('address.state', 'Please enter complete address');
        formik.setFieldError('address.country', 'Please enter complete address');
      }

      const restaurantData = {
        email,
        restaurantName,
        city: values?.address?.city,
        state: values?.address?.state,
        street: values?.address?.street,
        lat: values?.address?.coordinates?.lat,
        long: values?.address?.coordinates?.long,
        country: values?.address?.country,
        // phoneNumber,
        restaurantImg,
      };

      values.email = values.email.toLocaleLowerCase();
      formik.setSubmitting(true);
      setHideCloseModalBtn(true);
      const response = await handleSaveCallback(restaurantData);
      formik.setSubmitting(false);
      setHideCloseModalBtn(response);
    },
  });

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Restaurant Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Restaurant name'
              {...formik.getFieldProps('name')}
              type='text'
              name='restaurantName'
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              autoComplete='off'
              disabled={formik.isSubmitting}
              maxLength={50}
            />
            {formik.touched.restaurantName && formik.errors.restaurantName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.restaurantName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Phone Number</label>
            <PhoneInput
              defaultCountry='fr'
              value={formik.values.phoneNumber}
              onChange={(phone) => {
                formik.setFieldValue('phoneNumber', phone);
                if (!isPhoneValid(phone)) {
                  if (phone.length > 5) formik.setFieldTouched('phoneNumber', true);
                  formik.setFieldError('phoneNumber', 'Phone number is not valid');
                } else {
                  formik.setFieldError('phoneNumber', '');
                }
              }}
              countries={countries}
              inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
              inputProps={{name: 'phoneNumber', autoComplete: 'off', disabled: formik.isSubmitting}}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNumber}</span>
                </div>
              </div>
            )}

            <div className='form-check mt-3 d-flex align-items-center gap-2'>
              <input
                type='checkbox'
                className='form-check-input'
                id='agreeToSms'
                name='agreeToSms'
                // checked={formik.values.agreeToSms}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
              <label className='col-form-label required fw-bold fs-6' htmlFor='agreeToSms'>
                I agree to receive a reservation confirmation SMS.
              </label>
            </div>
            {formik.touched.agreeToSms && typeof formik.errors.agreeToSms === 'string' && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agreeToSms}</span>
                </div>
              </div>
            )}
          </div> */}

          {/* Location */}
          <div className='fv-row mb-7'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Location</label>
            <div className='fv-row mb-7'>
              <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
                <Autocomplete
                  onLoad={(autocomplete: any) => {
                    setAutoComplete(autocomplete);
                  }}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    placeholder='Location'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    type='text'
                    autoComplete='off'
                    onChange={(e) => {
                      setValue(e.target.value);
                      setAddressError('');
                      if (e.target.value.trim() === '' || e.target.value.length === 0) {
                        setErr(true);
                      } else {
                        setErr(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !formik.isSubmitting) {
                        formik.handleBlur(e);
                        e.preventDefault();
                      }
                    }}
                  />
                </Autocomplete>
              </LoadScript>
              {addressError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{addressError}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='fv-row mb-7'>
          <div className='fw-bold fs-6 mb-2'>Image</div>
          <label
            htmlFor='restaurantImg'
            className='btn btn-primary px-5 py-3'
            style={{whiteSpace: 'nowrap'}}
            onBlur={() => formik.setFieldTouched('restaurantImg', true)}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Upload
          </label>
          <input
            className={'form-control form-control-solid mb-1 mb-lg-0 d-none'}
            id='restaurantImg'
            accept='.png, .jpg, .jpeg, .webp'
            type='file'
            autoComplete='off'
            onChange={(e: any) => {
              formik.setFieldValue('restaurantImg', e.target.files[0]);
            }}
            onBlur={() => formik.setFieldTouched('restaurantImg', true)}
            disabled={formik.isSubmitting}
          />
          {formik.errors.restaurantImg && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.restaurantImg}</span>
              </div>
            </div>
          )}
          <div className='mt-5'>
            {formik.values?.restaurantImg && !formik.errors.restaurantImg && (
              <div className='position-relative d-inline-block'>
                <img
                  src={URL.createObjectURL(formik.values?.restaurantImg)}
                  alt={`Restaurant Preview`}
                  className='w-100px h-100px'
                />{' '}
                <button
                  type='button'
                  onClick={() => formik.setFieldValue('restaurantImg', undefined)}
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    background: '#FFFFFF',
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                >
                  ✖
                </button>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={handleCancelCallback}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting}
          >
            {!formik.isSubmitting && 'Submit'}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default RestaurantBody;
