import moment from 'moment';

export * from './constants';

export const debounce = (mainFunction, delay = 300) => {
  let searchTimer;
  return function (...args) {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
};

export const formatDate = (inputDateTime: null | any = null) => {
  let date = new Date();
  if (inputDateTime !== null) {
    date = new Date(inputDateTime);
  }
  const yearStr = date.getFullYear();
  const monthStr = String(date.getMonth() + 1).padStart(2, '0');
  const dayStr = String(date.getDate()).padStart(2, '0');
  const hoursStr = String(date.getHours()).padStart(2, '0');
  const minutesStr = String(date.getMinutes()).padStart(2, '0');
  const secondsStr = String(date.getSeconds()).padStart(2, '0');

  return `${yearStr}-${monthStr}-${dayStr}T${hoursStr}:${minutesStr}:${secondsStr}`;
};

// export function convertUtcToLocal(utcDateString, format = false) {
//   let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   if (userTimeZone?.includes('Unknown')) {
//     userTimeZone = 'America/Chicago';
//   }
//   // Create a Date object from the UTC string
//   const utcDate = new Date(utcDateString);

//   // Convert UTC to local time and format as a string
//   const localDateString = utcDate.toLocaleString('en-IN', {
//     timeZone: userTimeZone,
//     month: '2-digit',
//     day: '2-digit',
//     year: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   });
//   if (format) return formatDate(localDateString);
//   return localDateString;
// }

export function convertUtcToLocal(utcDateString) {
  const moment = require('moment-timezone');
  let userTimeZone = moment.tz.guess();
  if (userTimeZone === 'Etc/Unknown') {
    userTimeZone = 'America/Chicago';
  }
  const localDate = moment.utc(utcDateString).tz(userTimeZone);
  return localDate.format('MM/DD/YY, hh:mm A');
  // return localDate.format('DD/MM/YYYY, hh:mm A');
}

export function convertUtcToLocalDate(dateStr) {
  const moment = require('moment-timezone');
  let userTimeZone = moment.tz.guess();
  if (userTimeZone === 'Etc/Unknown') {
    userTimeZone = 'America/Chicago';
  }
  const localDate = moment.utc(dateStr).tz(userTimeZone);
  return localDate.format('YYYY-MM-DD');
}

// export function convertLocalToUtc(localDateString, format = true) {
//   let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   if (userTimeZone?.includes('Unknown')) {
//     userTimeZone = 'America/Chicago';
//   }
//   // Create a Date object from the local string and set the time zone
//   const localDate = new Date(localDateString);
//   localDate.toLocaleString('en-US', {timeZone: userTimeZone});
//   if (localDate.getHours() === 0 && localDate.getMinutes() === 0) {
//     localDate.setMinutes(localDate.getMinutes() + 1);
//   }

//   // Convert local time to UTC and format as a string
//   const utcDateString = localDate.toISOString();

//   return utcDateString;
// }

export function convertLocalToUtc(localDateString) {
  const moment = require('moment-timezone');

  let userTimeZone = moment.tz.guess();
  if (userTimeZone === 'Etc/Unknown') {
    userTimeZone = 'America/Chicago';
  }

  const localDate = moment.tz(localDateString, 'DD/MM/YYYY, hh:mm A', userTimeZone);

  // Check if the time is midnight, adjust by one minute if so
  if (localDate.hour() === 0 && localDate.minute() === 0) {
    localDate.add(1, 'minute');
  }

  // Convert local time to UTC and format as a string
  const utcDateString = localDate.utc().format();

  return utcDateString;
}

export function convertLocalTimeToUtc(dineTime, todayDate) {
  const moment = require('moment-timezone');

  let userTimeZone = moment.tz.guess();
  if (userTimeZone === 'Etc/Unknown') {
    userTimeZone = 'America/Chicago';
  }

  // Combine today's date and dineTime to create a local date object
  const localDateTime = moment.tz(todayDate + ' ' + dineTime, 'YYYY-MM-DD hh:mm A', userTimeZone);

  // Check if the time is midnight, adjust by one minute if so
  if (localDateTime.hour() === 0 && localDateTime.minute() === 0) {
    localDateTime.add(1, 'minute');
  }

  // Convert local time to UTC and format as a string
  const utcDateTimeString = localDateTime.utc().format();

  return utcDateTimeString;
}

// export function convertUtcToLocalTime(utcDateString) {
//   const moment = require('moment-timezone');
//   let userTimeZone = moment.tz.guess();
//   if (userTimeZone === 'Etc/Unknown') {
//     userTimeZone = 'America/Chicago';
//   }
//   const localDate = moment.utc(utcDateString).tz(userTimeZone);
//   return localDate.format('hh:mm');
// }

export function convertUtcToLocalTime(utcDateString) {
  const moment = require('moment-timezone');
  let userTimeZone = moment.tz.guess();
  if (userTimeZone === 'Etc/Unknown') {
    userTimeZone = 'America/Chicago';
  }
  const localDate = moment.utc(utcDateString).tz(userTimeZone);
  return localDate.format('h:mm A');
}

export const convertDateStringToDate = function (dateString) {
  if (!dateString) return '';
  // Input date string
  const inputDate = new Date(dateString);

  // Format the date in the desired format (YYYY-MM-DD)
  return (
    inputDate.getFullYear() +
    '-' +
    ('0' + (inputDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + inputDate.getDate()).slice(-2)
  );
};

export function convertToCustomFormat(inputString) {
  if (!inputString) return '';
  const dateObj = new Date(inputString);

  // Get individual date and time components in the local timezone
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1; // Months are zero-based
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();

  // Add leading zero if needed
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  // Format the date and time
  const formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}`;
  const meridiem = hours < 12 ? 'AM' : 'PM';

  // Return the combined formatted string
  return `${formattedDate} ${formattedTime} ${meridiem}`;
}
