import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {RestaurantParamsInfo} from '..';
import {CommonRowInTable, CommonViewInHeader} from '../..';
import {KTIcon} from '../../../../../../_metronic/helpers';
import DataTableWrapper from '../../../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL, convertUtcToLocalTime} from '../../../../../Helpers';
import {useSessionReducer} from '../../../../../hook/useSessionReducer';
import {useAuth} from '../../../../../modules/auth';
import {CustomModal} from '../../../../Modal';
import StatusSelectBox from '../../../../Sections/Admin/StatusSelectBox';
import reducerFunc from '../action';

const initialState = {
  loading: true,
  data: [],
  capacity: '',
  selectedRow: null,
  totalData: -1,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  error: null,
  search: '',
  statusFilter: '',
  restaurantFilter: '',
  modalType: 'RECURRING_AUCTION',
};

const RecurringAuctionDataTable = ({restaurantId = ''}: RestaurantParamsInfo) => {
  const [state, dispatch] = useSessionReducer<any, any, string>(
    reducerFunc,
    {...initialState, restaurantFilter: restaurantId || ''},
    'RECURRING_AUCTION'
  );

  const {auctionModule} = useAuth();
  const {currentUser} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const params = useParams();

  let columns = [
    {
      name: <CommonViewInHeader name='Table' />,
      selector: (row, i) => row.table.capacity,
      sortable: true,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.table.capacity + ' Persons'}
            classes={'text-dark'}
            onClick={() => {
              dispatch({
                type: 'SELECT_ROW',
                payload: {
                  id: row.id,
                  isView: true,
                  tableId: row.tableId,
                  tablePrice: row.price,
                  dineTime: convertUtcToLocalTime(row.dineTime),
                  status: row.status,
                  days: row.recurring,
                  auctionType: row?.auctionType?.id,
                },
              });
              dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Auction Type' />,
      selector: (row, i) => row?.auctionType?.type,
      sortable: true,
      width: '250px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auctionType?.type ? row?.auctionType?.type : '-'}
            classes={'text-dark'}
            onClick={() => {
              dispatch({
                type: 'SELECT_ROW',
                payload: {
                  id: row.id,
                  isView: true,
                  tableId: row.tableId,
                  tablePrice: row.price,
                  dineTime: convertUtcToLocalTime(row.dineTime),
                  status: row.status,
                  days: row.recurring,
                  auctionType: row?.auctionType?.id,
                },
              });
              dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <StatusSelectBox
            value={row.status}
            onChange={async (e) => {
              await updateRecurringAuctions(row.id, {status: e.target.value});
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.price,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='view auction'
              className='menu-link px-3'
              onClick={() => {
                dispatch({
                  type: 'SELECT_ROW',
                  payload: {
                    isView: true,
                    id: row.id,
                    tableId: row.tableId,
                    tablePrice: row.price,
                    dineTime: convertUtcToLocalTime(row.dineTime),
                    status: row.status,
                    days: row.recurring,
                    auctionType: row?.auctionType?.id,
                    endTime: convertUtcToLocalTime(row.endTime),
                  },
                });
                dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>

            {!isAdmin && (
              <span
                title='Edit auction'
                className='menu-link px-3'
                onClick={() => {
                  dispatch({
                    type: 'SELECT_ROW',
                    payload: {
                      isView: false,
                      id: row.id,
                      tableId: row.tableId,
                      tablePrice: row.price,
                      dineTime: convertUtcToLocalTime(row.dineTime),
                      status: row.status,
                      days: row.recurring,
                      auctionType: row?.auctionType?.id,
                      endTime: convertUtcToLocalTime(row.endTime),
                    },
                  });
                  dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
                }}
              >
                <KTIcon iconName='pencil' className='fs-1' />
              </span>
            )}
            <span
              title='Delete auction'
              className='linkIcon'
              onClick={() => {
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  columns = isAdmin
    ? [
        {
          name: <CommonViewInHeader name='Restaurant' classes='ms-8' />,
          selector: (row, i) => row.restaurants.name,
          sortable: true,
          format: (row, i) => {
            return (
              <CommonRowInTable
                text={row.restaurants?.name}
                avatar={row.restaurants?.images || 'media/avatars/restaurant-blank.png'}
                classes='text-dark'
                onClick={() => {
                  dispatch({
                    type: 'SELECT_ROW',
                    payload: {
                      isView: true,
                      id: row.id,
                      tableId: row.tableId,
                      tablePrice: row.price,
                      dineTime: convertUtcToLocalTime(row.dineTime),
                      status: row.status,
                      days: row.recurring,
                    },
                  });
                  dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
                }}
              />
            );
          },
        },
        ...columns,
      ]
    : columns;

  const subHeader = !isAdmin ? (
    <button
      type='button'
      className='btn btn-primary text-nowrap'
      onClick={() => {
        dispatch({type: 'OPEN_MODAL', payload: 'RECURRING_AUCTION'});
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Recurring Auction
    </button>
  ) : null;

  const getTAuctions = async () => {
    dispatch({type: 'LOADING', payload: true});

    const result = await auctionModule?.getRecurringAuctions({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state?.search,
      statusFilter: state?.statusFilter,
      // restaurantIdFilter: state.restaurantFilter,
      restaurantIdFilter: state.restaurantFilter ? state.restaurantFilter : params.restaurantId,
      capacity: state.capacity,
    });

    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const tablevalue = localStorage.getItem('auctionTabValue');
  useEffect(() => {
    if (tablevalue === 'recurringAuction') {
      getTAuctions();
    }
  }, [tablevalue]);

  useEffect(() => {
    getTAuctions();
  }, [
    state.currentPage,
    state.pageSize,
    state.search,
    state.restaurantFilter,
    state.statusFilter,
    state.capacity,
  ]);

  const createRecurringAuctions = async (userData) => {
    const result = await auctionModule?.createRecurringAuction(userData);
    if (result?.success) {
      toast.success(result?.message || 'Auction Created succesfully');
      getTAuctions();
      return true;
    } else {
      toast.error(result?.message || `Problem occured, While creating Auction`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };
  const updateRecurringAuctions = async (auction_id, userData) => {
    const result = await auctionModule?.updateSingleRecurringAuction(auction_id, userData);
    if (result?.success) {
      toast.success(result?.message || 'Auction Updated succesfully');
      getTAuctions();
      return true;
    } else {
      toast.error(result?.message || `Problem occured, While creating Auction`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const deleteRecurringAuction = async (recurringAuctionIds) => {
    const result = await auctionModule?.deleteRecurringAuctions(recurringAuctionIds);
    if (result?.success) {
      toast.success(result?.message || `Recurring Auction Deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      getTAuctions();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    dispatch({type: 'CLOSE_MODAL'});
  };
  return (
    <>
      <DataTableWrapper
        subHeader={subHeader}
        totalData={state.totalData}
        selectableRows={true}
        loading={state.loading}
        pageSize={state.pageSize}
        columns={columns}
        handleSelected={(selected) => {
          dispatch({type: 'SELECT_ROW', payload: selected});
          dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
        }}
        data={state.data}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [
            {
              label: 'Status',
              options: ['Active', 'Inactive'].map((t) => ({
                value: t.toUpperCase(),
                label: t,
              })),
            },
          ],
          restaurantFilter: isAdmin && !restaurantId,
          capacity: true,
          preserveType: 'AUCTION',
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_STATUS', payload: value.Status?.value});
          dispatch({type: 'SET_RESTAURANT', payload: value?.restaurantFilter?.value});
          dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
        }}
        isFilterApplied={Boolean(
          state.restaurantFilter || state.statusFilter || state.search || state.capacity
        )}
      />
      {state.isModalOpen && (
        <CustomModal
          title={''}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state?.selectedRow?.id];
              await deleteRecurringAuction({ids});
            } else if (state.selectedRow) {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state?.selectedRow?.id];
              await updateRecurringAuctions(ids, value);
            } else {
              await createRecurringAuctions(value);
            }
            dispatch({type: 'CLOSE_MODAL'});
          }}
          type={state.modalType}
        />
      )}
    </>
  );
};

export default RecurringAuctionDataTable;
