import { PageTitle } from '../../../../_metronic/layout/core';
import AuctionWrapper from '../../../components/common/AuctionWrapper';

const AuctionForAdmin = () => {
  return (
    <>
      <PageTitle>Auctions List</PageTitle>
      <AuctionWrapper />
    </>
  );
};

export default AuctionForAdmin;
