import {Link, useNavigate} from 'react-router-dom';
import {KTIcon} from '../../../_metronic/helpers';
import {useThemeMode} from '../../../_metronic/partials';
import {useAuth} from '../../modules/auth';
import TableLoader from '../../DataTable/TableLoader';
import ReservationLoader from './ReservationLoader';

interface TopReservation {
  id: string;
  amount: number;
  status: string;
  deadline: string; // Consider using a Date type if you want to work with dates
  capacity: number;
  restaurantName: string;
  location: string;
  city: string;
  firstName: string;
  lastName: string;
  created_at: string; // Consider using a Date type if you want to work with dates
}

type Props = {
  className: string;
  dashboardData: TopReservation | null | undefined;
};

const TodaysReservation: React.FC<Props> = ({className, dashboardData}) => {
  const {mode} = useThemeMode();
  const {currentUser} = useAuth();
  const navigate = useNavigate();

  const dateTimeToTime = (dateString) => {
    const dateObject = new Date(dateString);
    const amPmTimeString = dateObject.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return amPmTimeString;
  };

  return (
    <div className={`card ${className} relative`}>
      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-5 sticky ${mode === 'dark' ? 'bg-light' : 'bg-white'}`}
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Today's Booking</span>
          <span className='text-muted mt-1 fw-semibold fs-7 '>
            {new Date().toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}

        {Array.isArray(dashboardData) ? (
          dashboardData?.length ? (
            dashboardData?.map((elem, i) => {
              return (
                <div key={i}>
                  <div
                    className='d-flex align-items-center mb-4'
                    style={{
                      overflowX: 'auto',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {/* begin::Text */}
                    <div className='w-100'>
                      <div className='d-flex'>
                        {currentUser?.role === 'ADMIN' && (
                          <p>
                            <Link to={`/restaurants/${elem?.restaurantId}`}>
                              <span className='text-dark text-capitalize text-hover-primary fs-6 fw-semibold'>
                                {elem?.restaurantName && elem.restaurantName.length > 25
                                  ? elem.restaurantName.slice(0, 25) + '...'
                                  : elem.restaurantName}
                              </span>
                            </Link>
                            <span className='text-muted text-capitalize fw-semibold'>
                              {' '}
                              - {elem?.city}
                            </span>
                          </p>
                        )}
                      </div>
                      {/* end::Text */}
                      <Link
                        to='/bookings'
                        state={
                          currentUser?.role !== 'RESTAURANT'
                            ? {
                                value: elem?.restaurantId,
                                label: elem?.restaurantName,
                                role: 'admin',
                              }
                            : {
                                value: elem?.userId,
                                label: elem?.firstName + ' ' + elem?.lastName,
                                role: 'restaurant',
                              }
                        }
                        className='text-dark'
                      >
                        <div
                          className='d-flex justify-content-between p-4'
                          style={{
                            borderRadius: '999px',
                            border: '2px solid rgb(177, 180, 181)',
                          }}
                        >
                          <div className='d-flex align-items-center' style={{width: '50px'}}>
                            <KTIcon iconName='user' className='fs-6' />
                            <span className='mx-2'>{elem?.capacity}</span>
                          </div>
                          <div
                            className='vr'
                            style={{
                              width: '2px',
                              height: '20px',
                            }}
                          ></div>
                          <div className='d-flex align-items-center' style={{width: '70px'}}>
                            <KTIcon iconName='time' className='fs-6' />
                            <span className='mx-2 text-uppercase'>
                              {dateTimeToTime(elem?.dineDate)}
                            </span>
                          </div>
                          <div
                            className='vr'
                            style={{
                              width: '2px',
                              height: '20px',
                            }}
                          ></div>
                          <div className='d-flex align-items-center' style={{width: '80px'}}>
                            <span className='mx-2 text-capitalize'>
                              {elem?.firstName && elem.firstName.length > 10
                                ? elem.firstName.slice(0, 10) + '...'
                                : elem.firstName}
                            </span>
                          </div>
                          <div
                            className='vr'
                            style={{
                              width: '2px',
                              height: '20px',
                            }}
                          ></div>
                          <div className='d-flex align-items-center' style={{width: '70px'}}>
                            <span className='mx-2'>$ {elem?.amount}</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className='border border-bottom-2 my-6 border-blue-300' />
                </div>
              );
            })
          ) : (
            <>
              <p className='fs-2 fw-bold text-gray-600 m-0 text-center'>No reservation found </p>
              {dashboardData?.length >= 10 && (
                <div className='text-center mt-5'>
                  <span
                    className='text-primary cursor-pointer view-more-btn'
                    onClick={() => {
                      navigate('/bookings');
                    }}
                  >
                    See All Reservation
                  </span>
                </div>
              )}
            </>
          )
        ) : (
          <ReservationLoader />
        )}
        {Array.isArray(dashboardData) && dashboardData?.length > 6 && (
          <div className='text-center mt-5'>
            <span
              className='text-primary cursor-pointer view-more-btn'
              onClick={() => {
                navigate('/bookings');
              }}
            >
              View More
            </span>
          </div>
        )}
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export {TodaysReservation};
