import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core';
import ReviewsDataTable from '../../../components/common/DataTableViews/Review';

const Reviews = () => {
  return (
    <div>
      <PageTitle breadcrumbs={[]}>Reviews List</PageTitle>
      <ReviewsDataTable/>
    </div>
  );
}

export default Reviews
