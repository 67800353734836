import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {AsideDefault} from './components/aside/AsideDefault';
import {Footer} from './components/Footer';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {RightToolbar} from '../partials/layout/RightToolbar';
import {ScrollTop} from './components/ScrollTop';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials';
import {MenuComponent} from '../assets/ts/components';
import {useAuth} from '../../app/modules/auth';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../app/Helpers';
import {toast} from 'react-toastify';

const MasterLayout = () => {
  const location = useLocation();
  const {dashboardModule, currentUser, setCurrentUser, restaurantModule} = useAuth();

  const getDashboardData = async () => {
    const result = await dashboardModule?.getUserData(currentUser?.token);
    if (result?.success) {
      if (currentUser?.role === 'ADMIN' || currentUser?.role === 'SUPER_ADMIN') {
        setCurrentUser((currentUser) => ({
          ...currentUser,
          firstName: result?.data?.firstName,
          profilePicture: result?.data?.profilePicture,
        }));
      } else {
        setCurrentUser((currentUser) => ({
          ...currentUser,
          firstName: result?.data?.name,
          email: result?.data?.email,
          profilePicture: result?.data?.images,
        }));
      }
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const getRestaurantInfo = async function () {
    const result = await restaurantModule?.getRestaurantProfile();
    if (result?.success)
      if (result?.data?.data?.restaurant?.images) {
        setCurrentUser((currentUser) => ({
          ...currentUser,
          firstName: result?.data?.data?.restaurant?.name,
          profilePicture: result?.data?.data?.restaurant?.images,
          rating: result?.data?.data?.rating,
        }));
      }
  };
  useEffect(() => {
    if (currentUser?.role === 'RESTAURANT') {
      getRestaurantInfo();
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export {MasterLayout};
