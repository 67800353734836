import {UsersForAdmin} from '../Admin/User';

const initialState = {
  loading: true,
  data: [],
  isModalOpen: false,
  selectedRow: null,
  currentPage: 1,
  totalData: -1,
  totalPages: 1,
  error: null,
  search: '',
  changeAttendanceId: null,
  modalType: 'USER',
};
export const AdminForSuperAdmin = () => {
  return (
    <>
      <UsersForAdmin />
    </>
  );
};
