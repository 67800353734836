import * as Yup from 'yup';
import {useFormik} from 'formik';
import TableSelection from '../../common/DataTableViews/Auction/TableSelection';
import StatusSelectBox from '../../Sections/Admin/StatusSelectBox';
import Days from '../../Sections/Admin/DaysStatusSelectBox';
import {useEffect, useState} from 'react';
import moment from 'moment';
import {convertLocalTimeToUtc} from '../../../Helpers';
import dayjs from 'dayjs';
import type {TimePickerProps} from 'antd';
import {TimePicker} from 'antd';
import AuctionTypeSelection from '../../common/DataTableViews/Auction/AuctionTypeSelection';

interface TAuction {
  isView?: Boolean;
  id?: string;
  tableId?: string;
  auctionType?: string;
  tablePrice: string;
  dineTime?: Date | string;
  days: string[];
  status: string;
  endTime?: any;
}

export const initialAuctionData: TAuction = {
  id: '',
  isView: false,
  tableId: '',
  dineTime: '',
  tablePrice: '',
  days: [],
  status: 'Active',
  auctionType: '',
  endTime: '',
};

const editAuctionSchema = Yup.object().shape({
  tableId: Yup.string().required('Table is required'),
  tablePrice: Yup.number()
    .min(10, 'Price must be greater than $10')
    .required('Price is required')
    .typeError('Price must be a number')
    .max(20000, 'Value must be less than or equal to 20,000'),
  dineTime: Yup.string().required('Dine In time is required'),
  days: Yup.array().min(1, 'At least one day must be selected'),
  status: Yup.string().required('status is required'),
  auctionType: Yup.string().required('Auction Type is required'),
  endTime: Yup.string().required('End Time is required'),
});

const RecurringAuctionBody = ({
  auctionData,
  handleSaveCallback,
  handleCancelCallback,
}: {
  auctionData: TAuction | undefined;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
}) => {
  const formik = useFormik({
    initialValues: auctionData || initialAuctionData,
    validationSchema: editAuctionSchema,
    validateOnBlur: true,
    onSubmit: async (value) => {
      // if (errorMessage) {
      //   return;
      // }
      formik.setSubmitting(true);
      const auctionData = {
        tableId: value.tableId,
        price: value.tablePrice,
        dineTime: convertLocalTimeToUtc(value.dineTime, moment().format('YYYY-MM-DD')),
        status: value.status,
        days: value.days,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        auctionTypeId: value?.auctionType,
        endTime: convertLocalTimeToUtc(value.endTime, moment().format('YYYY-MM-DD')),
      };
      await handleSaveCallback(auctionData);
      formik.setSubmitting(false);
    },
  });

  const [todayDay, setTodayDay] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const currentTimePlus4Hours = moment().add(4, 'hours').format('HH:mm');

  useEffect(() => {
    const today = new Date().toLocaleDateString('en-US', {weekday: 'long'});
    setTodayDay(today);
  }, []);

  function getCurrentTime() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  // const handleDineTimeChange = (value: string) => {
  //   console.log("🚀 ~ handleDineTimeChange ~ value:", value)
  //   const selectedTime = dayjs(value, 'h:mm A');
  //   const currentTime = dayjs();
  //   const currentTimePlus4Hours = currentTime.add(4, 'hours');
  //   const selectedDays = formik.values.days || [];

  //   if (selectedDays.length === 0) {
  //     setErrorMessage('Please select days first.');
  //     return;
  //   }
  //   if (selectedDays.includes(currentTime.format('dddd')) && selectedTime.isBefore(currentTime)) {
  //     setErrorMessage(`You cannot select a previous time for today's selected day.`);
  //   } else if (selectedTime.isBefore(currentTimePlus4Hours)) {
  //     setErrorMessage(
  //       `Auction won't go live for today as there is less than 4-hour gap between current and Dine-In time.`
  //     );
  //   } else {
  //     setErrorMessage('');
  //   }
  //   formik.setFieldValue('dineTime', value);
  // };

  const handleDineTimeChange = (value: string) => {
    const selectedTime = dayjs(value, 'h:mm A');
    const currentTime = dayjs();
    const currentTimePlus4Hours = currentTime.add(4, 'hours');
    const selectedDays = formik.values.days || [];
    const today = currentTime.format('dddd');

    if (selectedDays.length === 0) {
      setErrorMessage('Please select days first.');
    } else if (
      currentTime.isBefore(selectedTime) &&
      selectedTime.isBefore(currentTimePlus4Hours) &&
      selectedDays.includes(today)
    ) {
      setErrorMessage(
        `Auction won't go live for today as there is less than 4-hour gap between current and Dine-In time.`
      );
    } else {
      setErrorMessage('');
    }
    formik.setFieldValue('dineTime', value);
    const bidsEndAtTime = selectedTime.subtract(4, 'hours').format('h:mm A');
    formik.setFieldValue('endTime', bidsEndAtTime);
  };

  const onChange: TimePickerProps['onChange'] = (time: any, timeString: any) => {
    handleDineTimeChange(timeString);
  };
  const handleDaysChange = (selectedDays: string[]) => {
    const currentTime = dayjs();
    const selectedTime: any = dayjs(formik.values.dineTime, 'h:mm A');
    const currentTimePlus4Hours = currentTime.add(4, 'hours');
    const isPastTime = selectedTime.isBefore(currentTime);
    const isCurrentTimePlus4Hours: any = selectedTime.isAfter(currentTimePlus4Hours);
    const today = currentTime.format('dddd');

    if (selectedDays.includes('Select All Days')) {
      formik.setFieldValue('days', [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]);
    } else {
      formik.setFieldValue('days', selectedDays);
    }

    // if (selectedDays.includes('Select All Days') && selectedTime.isBefore(currentTimePlus4Hours)) {
    //   setErrorMessage(
    //     `Auction won't go live as there is less than 4-hour gap between current and Dine-In time.`
    //   );
    // } else {
    //   setErrorMessage('');
    // }

    if (formik.values.dineTime) {
      // Check if dineTime field has a value

      if (
        (selectedDays.includes('Select All Days') || selectedDays.includes(today)) &&
        !isPastTime &&
        !isCurrentTimePlus4Hours
      ) {
        setErrorMessage(
          `Auction won't go live as there is less than 4-hour gap between current and Dine-In time.`
        );
      } else {
        setErrorMessage('');
      }
    }
  };

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column me-n7 pe-7' id='kt_modal_add_user_scroll'>
          <div className='fv-row row'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Table</label>
              <TableSelection
                onblur={() => {
                  // formik.setFieldTouched('tableId', true);
                }}
                as='select'
                {...formik.getFieldProps('tableId')}
                onChange={(value) => {
                  formik.setFieldValue('tableId', value.value);
                }}
                name='tableId'
                value={formik.getFieldProps('tableId').value}
                isDisabled={auctionData?.isView === true || auctionData}
              />

              {formik.touched.tableId && formik.errors.tableId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tableId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Days</label>
              <Days
                onChange={(values) => {
                  handleDaysChange(values?.map(({label}) => label) || []);
                }}
                isView={auctionData?.isView === true}
                value={formik.getFieldProps('days').value || []}
              />
              {formik.touched.days && formik.errors.days && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.days}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row row'>
            <div className='col-md-4 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>DineIn Time</label>
              <TimePicker
                use12Hours
                defaultValue={
                  (formik?.values?.dineTime && dayjs(formik?.values?.dineTime, 'h:mm A')) || null
                }
                format='h:mm A'
                allowClear={false}
                needConfirm={false}
                disabled={auctionData?.isView === true}
                onChange={onChange}
                className='form-control form-control-lg form-control-solid !h-[50px]'
              />

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker', 'TimePicker']}>
                  <TimePicker
                    timeSteps={{minutes: 1}}
                    ampm={false}
                    value={
                      formik?.values?.dineTime ? dayjs(formik?.values?.dineTime) : dayjs(new Date())
                    }
                    onChange={(selectedDate: any) => {
                      console.log('🚀 ~ selectedDate:', selectedDate);
                      const hours = selectedDate.$d.getHours();
                      const minutes = selectedDate.$d.getMinutes();
                      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}`;
                      console.log('🚀 ~ formattedTime ~ formattedTime:', formattedTime);
                      handleDineTimeChange(formattedTime);
                    }}
                    disabled={auctionData?.isView === true}
                    // className='!form-control !form-control-lg !form-control-solid'
                  />
                </DemoContainer>
              </LocalizationProvider> */}
              {formik.touched.dineTime && formik.errors.dineTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.dineTime}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-4 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Starting Bid</label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text input-prepand-text'>$</span>
                </div>
                <input
                  placeholder='Starting Bid'
                  {...formik.getFieldProps('tablePrice')}
                  type='number'
                  name='tablePrice'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting || auctionData?.isView === true}
                  onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-') && evt.preventDefault()}
                />
              </div>
              {formik.touched.tablePrice && formik.errors.tablePrice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tablePrice}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-4 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Status</label>
              <StatusSelectBox
                onChange={(e) => {
                  formik.setFieldValue('status', e.target.value);
                }}
                value={formik.getFieldProps('status').value}
                className='p-4'
                disabled={auctionData?.isView === true}
              />
              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='text-center my-4'>
            {' '}
            {errorMessage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Note: {errorMessage}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row row mb-7 date-width'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>End Time</label>
              <TimePicker
                use12Hours
                value={formik?.values?.endTime ? dayjs(formik?.values?.endTime, 'h:mm A') : null}
                format='h:mm A'
                allowClear={false}
                needConfirm={false}
                onChange={(time, timeString: any) => {
                  if (time) {
                    formik.setFieldValue('endTime', timeString);
                  }
                }}
                disabled={auctionData?.isView === true}
                className='form-control form-control-lg form-control-solid'
              />

              {formik.touched.endTime && formik.errors.endTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.endTime as string}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Auction Type</label>
              <AuctionTypeSelection
                onblur={() => {
                  formik.setFieldTouched('auctionType', true);
                }}
                as='select'
                {...formik.getFieldProps('auctionType')}
                onChange={(value) => {
                  formik.setFieldValue('auctionType', value.value);
                }}
                name='auctionType'
                value={formik.getFieldProps('auctionType').value}
                isDisabled={auctionData?.isView === true}
              />
              {formik.touched.auctionType && formik.errors.auctionType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.auctionType}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='text-center'>
            {auctionData?.isView !== true && (
              <>
                <button
                  type='reset'
                  onClick={handleCancelCallback}
                  className='btn btn-light me-3'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.touched}
                >
                  {!formik.isSubmitting && 'Submit'}
                  {formik.isSubmitting && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default RecurringAuctionBody;
