import {useFormik} from 'formik';
import * as Yup from 'yup';
import {TUser} from '../index.d';

export const initialUser: TUser = {
  address: '',
  street: '',
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  phoneNo: null,
};

const editUserSchema = Yup.object().shape({
  email: Yup.string()
  .email('Wrong email format')
  .min(5, 'Email should be of minimum 5 chars long')
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
  // .max(40, 'Email should be of maximum 40 chars long')
  .required('Email is required'),
  firstName: Yup.string()
    .min(3, 'First name should have minimum 3 characters')
    .max(15, 'First name should have maximun 15 characters')
    .required('First Name is required')
    .matches(/^[a-zA-Z]+$/, 'First name should contains characters only'),
  lastName: Yup.string()
    .min(3, 'Last name should have minimum 3 characters')
    .required('Last Name is required')
    .max(15, 'First name should have maximun 15 characters')
    .matches(/^[a-zA-Z]+$/, 'Last name should contains characters only'),
  address: Yup.string().required('Address is required'),
});

const UserBody = ({
  userDetails,
  handleSaveCallback,
  handleCancelCallback,
}: {
  userDetails: TUser;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
}) => {
  const formik = useFormik({
    initialValues: userDetails || initialUser,
    validationSchema: editUserSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      await handleSaveCallback({...values, street: values.address});
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>First Name</label>
            <input
              placeholder='First Name'
              {...formik.getFieldProps('firstName')}
              type='text'
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              autoComplete='off'
              maxLength={15}
              disabled={formik.isSubmitting}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Last Name</label>
            <input
              placeholder='Last Name'
              {...formik.getFieldProps('lastName')}
              type='text'
              maxLength={15}
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Address</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Address'
              {...formik.getFieldProps('address')}
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              name='address'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* end::Input */}
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={handleCancelCallback}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            {!formik.isSubmitting && 'Submit'}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  );
};

export {UserBody};
