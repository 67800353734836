interface InitialState {
  loading: boolean;
  data: any[];
  currentPage: number;
  isModalOpen: Boolean;
  selectedRow: any;
  totalPages: number;
  modalType: any;
  error: null | any;
  search: string;
  changeStatusId?: null | string;
  restaurantFilter?: string;
  userFilter: string;
}

export default function reducerFunc(state, action: {type: string; payload?: any}) {
  if (action.type === 'OPEN_MODAL') {
    return {...state, isModalOpen: true, modalType: action.payload};
  }
  if (action.type === 'CLOSE_MODAL') {
    return {...state, isModalOpen: false, selectedRow: null};
  }
  if (action.type === 'CHANGE_PAGE') {
    return {...state, currentPage: action.payload};
  }

  if (action.type === 'SEARCH_CHANGE') {
    return {...state, search: action.payload, currentPage: 1};
  }

  if (action.type === 'SELECT_ROW') {
    return {...state, selectedRow: action.payload};
  }

  if (action.type === 'SET_TOTAL_PAGES') {
    return {
      ...state,
      totalPages: Math.ceil(action.payload / state.pageSize),
      totalData: action.payload,
    };
  }

  if (action.type === 'CLOSE_MODAL') {
    return {...state, isModalOpen: false, selectedRow: null};
  }

  if (action.type === 'GET_DATA') {
    return {...state, data: action.payload, loading: false};
  }

  if (action.type === 'LOADING') {
    return {...state, loading: action.payload};
  }

  if (action.type === 'SET_RESTAURANT') {
    return {...state, restaurantFilter: action.payload, currentPage: 1};
  }

  if (action.type === 'SET_USER') {
    return {...state, userFilter: action.payload, currentPage: 1};
  }

  if (action.type === 'DATE_FILTER') {
    return {
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      currentPage: 1,
    };
  }

  if (action.type === 'SET_PAGE_SIZE') {
    return {
      ...state,
      pageSize: action.payload,
    };
  }
  return state;
}
