import {useEffect, useState} from 'react';
import {convertUtcToLocal, getAuctionStatus} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';

interface AuctionsData {
  restaurants?: {
    name?: string;
  };
  table?: {
    capacity?: number;
  };
  // Add other properties if needed
}

function AuctionDetail({auction_id, setBreadcrumbsTitle}) {
  const [auctionsData, setAuctionsaData] = useState<any>();
  const {auctionModule} = useAuth();
  const {currentUser} = useAuth();

  const getAuctionDetails = async () => {
    const result = await auctionModule?.getSingleAuctions(auction_id);
    if (localStorage.getItem('path')?.includes('restaurants')) {
      setBreadcrumbsTitle(result?.data?.restaurants?.name);
    }

    setAuctionsaData(result?.data);
  };
  useEffect(() => {
    getAuctionDetails();
  }, []);

  return (
    <>
      <div className='card-body  p-9'>
        {currentUser?.role === 'ADMIN' && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Restaurant Name</label>
            <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted text-capitalize'>
              {auctionsData?.restaurants?.name}
            </div>
          </div>
        )}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6'>Table</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {auctionsData?.table?.capacity} People
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6'>Dine In Date</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted text-uppercase'>
            {convertUtcToLocal(auctionsData?.dineDate)}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6'>Auction Status</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted '>
            {getAuctionStatus(auctionsData?.startDate, auctionsData?.deadline)}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6'>Total Bids</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {auctionsData?.totalBid}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6'>Auction Type</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {auctionsData?.auctionType?.type ? auctionsData?.auctionType?.type : '-'}
          </div>
        </div>
        {auctionsData?.highestBid ? (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Highest Bid</label>
            <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
              {auctionsData?.highestBid || '-'}
            </div>
          </div>
        ) : (
          ''
        )}
        {auctionsData?.winner ? (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Winner</label>
            <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted text-capitalize'>
              {auctionsData?.winner?.firstName + ' ' + auctionsData?.winner?.lastName}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default AuctionDetail;
