import axios from 'axios';
import {
  BASE_URL,
  DATE_FILTER,
  PAGINATION,
  RATING_FILTER,
  RESTAURANT_ID_FILTER,
  REVIEWS_URL,
  SEARCH,
  USER_ID_FILTER,
} from './Urls';

export class ReviewApiModule {
  async getAllReviews(paramInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(REVIEWS_URL, paramInfo.pageNo, paramInfo?.pageSize) +
          SEARCH(paramInfo?.searchKeyword) +
          RESTAURANT_ID_FILTER(paramInfo?.restaurantIdFilter) +
          USER_ID_FILTER(paramInfo?.userIdFilter) +
          DATE_FILTER(paramInfo?.startDate, paramInfo?.endDate) +
          RATING_FILTER(paramInfo?.ratingFilter)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
}
