
interface Iprops {
  searchKeyword?: any;
  clearSearch?: any;
  filters?: any;
  data?: any;
}
export default function NotFound({searchKeyword, clearSearch, filters, data}: Iprops) {
  const selectedMethod = (searchKeyword && searchKeyword) || (filters && '') || (data && undefined);

  return (
    <div>
      <h6 className='fw-bolder fs-2x text-gray-900 mb-5 mt-5'>
        {selectedMethod
          ? `Oops! No Records found for "${selectedMethod}"`
          : 'Oops! Records not found'}
      </h6>
      {selectedMethod && (
        <div className='fw-semibold fs-6 text-gray-500 mb-7'>
          - Did not match any records.
          <br />- Suggestions:
          <ul>
            {searchKeyword && <li>Make sure that all words are spelled correctly.</li>}
            <li>Try different {(searchKeyword && 'keywords') || selectedMethod}.</li>
            {searchKeyword && <li>Try more general keywords.</li>}
          </ul>
          <span className='text-primary cursor-pointer' onClick={clearSearch && clearSearch}>
            - Clear {(searchKeyword && 'Search') || selectedMethod}
          </span>
        </div>
      )}
    </div>
  );
}
