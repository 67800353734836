import DatePicker from 'react-datepicker';
function DateFilter({dateConfig, state, setState, setDisabledSubmitBtn}) {
  const onChange = (index, dates) => {
    const [startDate, endDate] = dates;

    const newDate = [...state.dateConfig];
    newDate[index] = {
      startDate,
      endDate,
    };
    setDisabledSubmitBtn(startDate && !endDate);
    setState({...state, dateConfig: newDate});
  };

  return (
    <>
      {state?.dateConfig?.map((_, i) => (
        <div className='mb-4' key={dateConfig?.labels[i]}>
          <label className='form-label fs-6 fw-bold'>{dateConfig?.labels[i]}</label>
          <DatePicker
            dateFormat='MM/dd/yyyy'
            className='form-control form-control-lg form-control-solid'
            onChange={onChange.bind(null, i)}
            startDate={state?.dateConfig[i].startDate}
            endDate={state?.dateConfig[i].endDate}
            isClearable={state?.dateConfig[i].startDate || state?.dateConfig[i].endDate}
            selectsRange
            // popperPlacement='auto'
            shouldCloseOnSelect={true}
          />
          {state?.dateConfig[i].startDate && !state?.dateConfig[i].endDate && (
            <span className='errorColor'>End Date is Required</span>
          )}
        </div>
      ))}
    </>
  );
}
export default DateFilter;
