import React from 'react';
import {convertUtcToLocal} from '../../../Helpers';

const ReportBody = ({
  reportDetails,
  handleSaveCallback,
  handleCancelCallback,
}: {
  reportDetails: any;
  handleSaveCallback: (value: any) => void;
  handleCancelCallback: () => void;
}) => {
  return (
    <div className='card-body p-5 py-0'>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Restaurant Name</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {reportDetails?.name}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>User Name</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {reportDetails?.firstName + ' ' + reportDetails?.lastName}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Date & Time</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
          {convertUtcToLocal(reportDetails?.created_at)}
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Issue</label>
        <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted query-form-pop-up bg-light rounded'>
          {reportDetails?.comment}
        </div>
      </div>
    </div>
  );
};

export default ReportBody;
