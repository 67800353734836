import React from 'react';
import ContactForm from '../../common/ContactForm';

const ContactBody = ({
  setHideCloseModalBtn,
  handleCancelCallback,
  handleSaveCallback,
  isInActive,
}) => {
  return (
    <ContactForm onSubmit={handleSaveCallback} isInActive={isInActive}>
      <></>
    </ContactForm>
  );
};

export default ContactBody;
