import {PageTitle} from '../../../../_metronic/layout/core';
import TableDataTable from '../../../components/common/DataTableViews/Tables';

const Tables = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tables List</PageTitle>
      <TableDataTable />
    </>
  );
};

export {Tables};
