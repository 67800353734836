import {AuthModel} from './_models';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const CURRENT_USER_DETAILS = 'current-user';
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};
const setCurrentUser = (auth: AuthModel | undefined) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(CURRENT_USER_DETAILS, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const getCurrentUser = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(CURRENT_USER_DETAILS);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `${auth.token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export function setupAxiosErr(axios: any, navigate: any, toast: any, logout, setShowErrModal) {
  const auth = getAuth();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if ((auth && auth.role && error.response.status === 401) || error.response.status === 601) {
        navigate('/auth');
        toast.error('Please login again');
        logout();
        // whatever you want to do with the error
      }
      if (auth && auth.role && error.response.status === 600) {
        localStorage.setItem('gotErr', JSON.stringify(error));
        setShowErrModal(true);
      }
      throw error;
    },
    (err: any) => Promise.reject(err)
  );
}
export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, setCurrentUser, getCurrentUser};
