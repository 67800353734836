import {useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';
import {API_ERROR_MESSAGE, TOAST_RESTAURANT_DETAILS} from '../../../../Helpers';
import {CustomModal} from '../../../../components/Modal';
import RestaurantProfileDetails from '../../../../components/Sections/Admin/RestaurantProfileDetails';
import RestaurantTableDataTable from '../../../../components/Sections/Admin/RestaurantTable';
import AuctionWrapper from '../../../../components/common/AuctionWrapper';
import BiddingDataTable from '../../../../components/common/DataTableViews/Bidding';
import {BookingDataTable} from '../../../../components/common/DataTableViews/Booking';
import SectionWrapper from '../../../../components/common/SectionWrapper';
import {useAuth} from '../../../../modules/auth';
import RestaurantTransactions from '../../Transactions';
import ManageHost from '../../../Restaurant/ManageHost';
import HostDetails from '../../../../components/Sections/Admin/HostDetails';
import {AuctionTypeDetails} from '../../../../components/Sections/Admin/AuctionTypeDetails';
import AdminAuctionWrapper from '../../../../components/common/AdminAuctionWrapper';
import {AdminBookingTable} from '../../../../components/common/DataTableViews/Booking/AdminBookingTable';

const restaurantDetailsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Restaurants',
    path: '/restaurants',
    isSeparator: false,
    isActive: false,
  },
];

const initialState = {
  loading: true,
  isModalOpen: false,
};

const RestaurantDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [restaurantLoadDelStatus, setRestautantLoadDelStatus] = useState({
    isLoading: true,
    isDeleted: false,
  });
  const [restaurantName, setRestaurantName] = useState('');
  const [refreshTable, setRefreshTable] = useState(true);
  const [ratingReport, setRatingReport] = useState<any>({
    rating: false,
    report: false,
  });
  const {restaurantModule, currentUser} = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const [modalView, setModalView] = useState(false);
  const navigate = useNavigate();
  const param = useParams();

  const addTableBtn = !restaurantLoadDelStatus.isDeleted ? (
    <div className='card-title m-0'>
      <button
        className='btn btn-primary'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsModalOpen(true);
          return false;
        }}
      >
        Add Table
      </button>
    </div>
  ) : (
    ''
  );

  const deleteTRestaurant = async (restaurantIds) => {
    const result = await restaurantModule?.deleteRestaurants(restaurantIds);
    if (result?.success) {
      toast.success(`Restaurant Deleted succesfully`, {});
      navigate('/restaurants');
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {});
    }
  };

  const isEditMode = searchParams.get('edit');
  const editRestaurantBtn = restaurantLoadDelStatus.isDeleted ? (
    <span
      className='badge capitalize fs-6 text-danger'
      style={{
        minWidth: '100px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      Restaurant Deleted
    </span>
  ) : (
    <div className='card-title m-0'>
      <button
        className='btn btn-primary mx-3'
        style={{
          minWidth: '100px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setModalView(true);
        }}
      >
        Delete Account
      </button>
      <button
        className='btn btn-primary'
        style={{
          minWidth: '100px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isEditMode ? setSearchParams({}) : setSearchParams({edit: 'true'});
          return false;
        }}
      >
        {isEditMode ? 'Cancel Edit' : 'Edit Restaurant'}
      </button>
    </div>
  );

  const addTRestaurantTables = async (value) => {
    const result = await restaurantModule?.addRestaurantTables(
      currentUser?.role === 'ADMIN' && param?.restaurantId,
      value
    );
    if (result?.success) {
      toast.success(result?.message || `Table added succesfully`, {});
      setIsModalOpen(false);
      setRefreshTable(true);
    } else {
      toast.error(result?.message, {});
    }
  };
  return (
    <>
      <PageTitle breadcrumbs={restaurantDetailsBreadcrumbs}>{restaurantName}</PageTitle>
      <SectionWrapper
        subTitle={!restaurantLoadDelStatus.isLoading ? editRestaurantBtn : ''}
        id={param?.restaurantId || ''}
        title='Restaurant Details'
        rating={ratingReport.rating}
        report={ratingReport.report}
      >
        <RestaurantProfileDetails
          setRestaurantName={setRestaurantName}
          restaurantId={param.restaurantId}
          toastId={TOAST_RESTAURANT_DETAILS}
          setRatingReport={setRatingReport}
          setRestautantLoadDelStatus={setRestautantLoadDelStatus}
          restaurantLoadDelStatus={restaurantLoadDelStatus}
        />
      </SectionWrapper>
      <SectionWrapper id='restaurant_auctions_details' title='Auctions Details'>
        <AdminAuctionWrapper
          restaurantId={param.restaurantId}
          setRestaurantName={setRestaurantName}
        />
      </SectionWrapper>

      <SectionWrapper id='restaurant_tables_details' title='Auction Type Details'>
        <AuctionTypeDetails restaurantId={param.restaurantId} />
      </SectionWrapper>

      <SectionWrapper id='restaurant_tables_details' title='Tables Details'>
        <RestaurantTableDataTable
          restaurantId={param.restaurantId}
          isDeleted={restaurantLoadDelStatus.isDeleted}
        />
      </SectionWrapper>

      <SectionWrapper id='restaurant_tables_details' title='Host Details'>
        <HostDetails restaurantId={param.restaurantId} />
      </SectionWrapper>

      <SectionWrapper id='restaurant_booking_details' title='Bidding Details'>
        <BiddingDataTable
          restautantId={param.restaurantId}
          showRestaurantFilter={false}
          filterType='bidding'
        />
      </SectionWrapper>
      <SectionWrapper id='restaurant_transactions_details' title='Transactions Details'>
        <RestaurantTransactions
          restaurantId={param.restaurantId}
          showRestaurantFilter={false}
          breadcrumb={[]}
          filterType={'transactions'}
        />
      </SectionWrapper>

      <SectionWrapper id='restaurant_booking_details' title='Booking Details'>
        <AdminBookingTable
          restaurantId={param.restaurantId}
          showRestaurantFilter={false}
          showBookingCount={true}
          filterType='booking'
        />
      </SectionWrapper>
      {isModalOpen && (
        <CustomModal
          title={'Add Table'}
          data={{}}
          handleCancelCallback={() => setIsModalOpen(false)}
          handleSaveCallback={async (value) => {
            await addTRestaurantTables({...value, restaurantId: param?.restaurantId});
          }}
          type='TABLE'
        />
      )}
      {modalView && (
        <CustomModal
          title=''
          data=''
          handleCancelCallback={() => setModalView(false)}
          handleSaveCallback={(value) => {
            deleteTRestaurant({ids: [param?.restaurantId]});
            setModalView(false);
          }}
          type={'DELETE'}
        />
      )}
    </>
  );
};

export default RestaurantDetails;
