import { useEffect, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommonRowInTable, CommonViewInHeader } from '..';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useThemeMode } from '../../../../../_metronic/partials';
import DataTableWrapper from '../../../../DataTable';
import {
  API_ERROR_MESSAGE,
  TOAST_GENERAL,
  convertUtcToLocal
} from '../../../../Helpers';
import { useAuth } from '../../../../modules/auth';
import { CustomModal } from '../../../Modal';
import reducerFunc from './action';
import { TInitialState } from './index.d';

const initialState: TInitialState = {
  loading: true,
  data: [],
  isModalOpen: false,
  currentPage: 1,
  selectedRow: null,
  totalData: -1,
  totalPages: 1,
  pageSize: 10,
  error: null,
  search: '',
  restaurantFilter: '',
  userFilter: '',
  startDate: '',
  endDate: '',
  ratingFilter: '',
};
const ReviewsDataTable = () => {
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  const {currentUser} = useAuth();
  const isAdmin = currentUser?.role === 'ADMIN';
  const {mode} = useThemeMode();
  const {reviewModule} = useAuth();
  const {state: locationState}: {state: any} = useLocation();
  const restaurantLocationFilter = useRef({
    restautant: locationState?.restaurant,
  });

  let columns = [
    {
      name: <CommonViewInHeader name='User Name' />,
      selector: (row, i) => row.firstName,
      sortable: true,
      width: isAdmin ? '200px' : '270px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.firstName + ' ' + row?.lastName}
            classes='text-dark text-capitalize cursor-pointer'
            onClick={() => {
              dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row.created_at,
      width: isAdmin ? '230px' : '320px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row?.updated_at)}
            classes='cursor-pointer text-theme-color text-uppercase'
            onClick={() => {
              dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
              dispatch({type: 'SELECT_ROW', payload: row});
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Ratings' />,
      selector: (row, i) => row.rating,
      width: isAdmin ? '240px' : '310px',
      format: (row, i) => {
        const rating = parseInt(row?.rating) || 0;
        return (
          <div className='d-flex justify-content-start'>
            <span
              className={`cursor-pointer mb-1 fs-6 text-dark`}
              onClick={() => {
                dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
                dispatch({type: 'SELECT_ROW', payload: row});
              }}
            >
              {[...Array(Math.ceil(rating))].map((_, index) => (
                <span className='svg-icon svg-icon-primary svg-icon-2x' key={index}>
                  {Math.ceil(rating) === index + 1 && !(Math.floor(rating) === rating) ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      version='1.1'
                    >
                      <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                        <polygon points='0 0 24 0 24 24 0 24' />
                        <path
                          d='M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z'
                          fill='#faaf00'
                          opacity='0.3'
                        />
                        <path
                          d='M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z'
                          fill='#faaf00'
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      version='1.1'
                    >
                      <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                        <polygon points='0 0 24 0 24 24 0 24' />
                        <path
                          d='M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z'
                          fill='#faaf00'
                        />
                      </g>
                    </svg>
                  )}
                </span>
              ))}
            </span>
            <div
              className='fs-6 mt-2 ms-2'
              onClick={() => {
                dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
                dispatch({type: 'SELECT_ROW', payload: row});
              }}
            >
              {rating.toFixed(1)}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.price,

      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='View review'
              className='linkIcon'
              onClick={() => {
                dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
                dispatch({type: 'SELECT_ROW', payload: row});
              }}
            >
              <KTIcon iconName='eye' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];
  columns =
    currentUser?.role === 'ADMIN'
      ? [
          {
            name: <CommonViewInHeader name='Restaurant' classes='ms-8' />,
            selector: (row, i) => row.name,
            sortable: true,
            width: '240px',
            format: (row, i) => {
              return (
                <CommonRowInTable
                  text={row?.name}
                  avatar={row?.images ?? 'media/avatars/restaurant-blank.png'}
                  classes='text-dark text-capitalize cursor-pointer'
                  onClick={() => {
                    dispatch({type: 'OPEN_MODAL', payload: 'REVIEW'});
                    dispatch({type: 'SELECT_ROW', payload: row});
                  }}
                />
              );
            },
          },
          ...columns,
        ]
      : columns;

  const getTReview = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await reviewModule?.getAllReviews({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      restaurantIdFilter: restaurantLocationFilter.current.restautant || state.restaurantFilter,
      userIdFilter: state.userFilter,
      startDate: state.startDate,
      endDate: state.endDate,
      ratingFilter: state.ratingFilter,
    });
    restaurantLocationFilter.current = {
      restautant: '',
    };

    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };
  useEffect(() => {
    getTReview();
  }, [
    state.currentPage,
    state.pageSize,
    state.search,
    state.restaurantFilter,
    state.userFilter,
    state.startDate,
    state.endDate,
    state.ratingFilter,
  ]);

  return (
    <div>
      <DataTableWrapper
        totalData={state.totalData}
        loading={state.loading}
        columns={columns}
        data={state.data}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'CHANGE_PAGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [
            {
              label: 'Rating',
              options: ['1', '2', '3', '4', '5'].map((t) => ({value: t, label: t})),
            },
          ],
          restaurantFilter: isAdmin,
          userFilter: true,
          date: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
          dispatch({type: 'SET_USER', payload: value.userFilter?.value});
          dispatch({type: 'RATING_FILTER', payload: value.Rating?.value});
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: value.startDate, endDate: value.endDate},
          });
        }}
      />
      {state?.isModalOpen && (
        <CustomModal
          title={'Reviews'}
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={() => {}}
          type='REVIEW'
        />
      )}
    </div>
  );
};

export default ReviewsDataTable;
