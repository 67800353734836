import {Route, Routes} from 'react-router-dom';
import {Registration} from './components/Registration';
import {ForgotPassword} from './components/ForgotPassword';
import {Login} from './components/Login';
import {AuthLayout} from './AuthLayout';
import ResetPassword from './components/ResetPassword';
import VerifyOtp from './components/VerifyOtp';
import AdminContactUs from './components/ContactUs';
import RestaurantRegister from './components/RestaurantRegister';

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='verify' element={<VerifyOtp />} />
      <Route path='register-restaurant' element={<RestaurantRegister />} />{' '}
      {/* <Route path='resend-otp' element={<ResendOtp />} /> */}
      <Route path='/contact-us' element={<AdminContactUs />} />;
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export {AuthPage};
