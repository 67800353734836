/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '../../../../app/modules/auth';
import {Languages} from './Languages';
import {toAbsoluteUrl} from '../../../helpers';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../../app/Helpers';
import {toast} from 'react-toastify';

const HeaderUserMenu: FC = () => {
  const {currentUser, setCurrentUser, logout, dashboardModule} = useAuth();

  // const getDashboardData = async () => {
  //   const result = await dashboardModule?.getUserData(currentUser?.token);
  //   if (result?.success) {
  //     if (currentUser?.role === 'ADMIN' || currentUser?.role === 'SUPER_ADMIN') {
  //       setCurrentUser((currentUser) => ({
  //         ...currentUser,
  //         firstName: result?.data?.firstName,
  //         profilePicture: result?.data?.profilePicture,
  //       }));
  //     } else {
  //       setCurrentUser((currentUser) => ({
  //         ...currentUser,
  //         firstName: result?.data?.data?.restaurant?.name,
  //         email: result?.data?.data?.email,
  //         profilePicture: result?.data?.data?.restaurant?.images,
  //       }));
  //     }
  //   } else {
  //     toast.error(result?.message || API_ERROR_MESSAGE, {
  //       toastId: TOAST_GENERAL,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getDashboardData();
  // }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded mt-3 menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                currentUser?.profilePicture
                  ? currentUser?.profilePicture
                  : currentUser?.role === 'ADMIN' || currentUser?.role === 'SUPER_ADMIN'
                  ? toAbsoluteUrl('/media/avatars/blank.png')
                  : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
              }
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName && currentUser?.firstName.length > 10
                ? currentUser?.firstName.slice(0, 10).padEnd(13, '...')
                : currentUser?.firstName}
              {currentUser?.role === 'RESTAURANT' && (
                <div className='d-flex gap-2 ms-2'>
                  <div className='fs-5 fw-bold'>
                    {currentUser?.rating ? currentUser?.rating?.slice(0, 3) : '-'}
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-duotone ki-star fs-5'></i>
                  </div>
                </div>
              )}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <span className='fw-bold text-muted fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <>
        <div className='menu-item px-5'>
          <Link to={'/profile'} className='menu-link px-5'>
            My Profile
          </Link>
        </div>
        <div className='separator my-2'></div>
      </>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            localStorage.removeItem('toastShown');
            logout();
          }}
          className='menu-link px-5'
        >
          Sign Out
        </a>
      </div>
    </div>
  );
};

export {HeaderUserMenu};
