import React from 'react';
import {KTIcon} from '../../../_metronic/helpers';
import {useNavigate} from 'react-router-dom';

const RestaurantTerms = () => {
  const navigate = useNavigate();

  const handleCancelCallback = () => {
    navigate('/');
  };

  return (
    <div className='container py-4 mt-10'>
      {/* Back Button */}
      {/* <button type='button' className='btn btn-primary mb-5' onClick={handleCancelCallback}>
        <KTIcon iconName='arrow-left' className='fs-4 text-white ms-1' />
        Back
      </button> */}

      <div className='d-flex flex-column me-n7 pe-7'>
        <h1 className='fw-bold fs-1 mb-6 text-center'>Restaurant Terms</h1>

        <h2 className='fw-bold fs-2 mt-6'>1. Agreement to Terms</h2>
        <p className='fs-4'>
          By making reservations (“Reservations”) available through the Services, you, the
          Restaurant, agree to be bound by these additional Restaurant Terms. These terms apply to
          all services provided by Diibs and govern your relationship with Users who participate in
          reservation auctions through the Services. These terms supplement any additional
          agreements between Restaurant and Diibs, including the{' '}
          <a href='/terms-and-conditions'>Terms of Use</a> and govern any order form entered into
          between Restaurant and Diibs (the “Order Form”).
        </p>

        <h2 className='fw-bold fs-2 mt-6'>2. Restaurant Responsibilities</h2>
        <p className='fs-4'>
          <strong>Reservation Listing:</strong> Restaurant agrees to accurately list all relevant
          details of each reservation made available for bidding, including but not limited to time,
          date, number of seats, and any specific terms regarding dining that are made available on
          other reservation platforms (e.g., seating location preferences, exclusions, or special
          offers, as applicable) (the “Reservation”). Reservation details may not be modified after
          the listing is live.
        </p>
        <p className='fs-4'>
          <strong>Fulfillment of Reservation:</strong> Upon winning the auction, the User will
          receive a confirmation of the Reservation and their minimum spend obligation (the “Minimum
          Spend”). Restaurant is responsible for honoring this Reservation and ensuring that the
          User’s dining experience is consistent with the Reservation listing's terms. Repeated
          failures to honor Reservations may result in termination of your use of the Services.
          Restaurant agrees that Reservations are fully transferable by the User.
        </p>
        <p className='fs-4'>
          <strong>No Additional Charges Beyond Minimum Spend:</strong> Restaurant agrees not to
          impose any charges on the User beyond the agreed-upon Minimum Spend, except for additional
          expenses incurred by User, and for taxes, gratuities, or other customary fees clearly
          disclosed upfront in the Reservation listing.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>3. Payment Terms</h2>
        <p className='fs-4'>
          <strong>Commission:</strong> For each Reservation, Diibs shall obtain a payment hold from
          Users in the amount of the Minimum Spend, plus a 5% auction fee (the “Auction Fee” and
          together with the Minimum Spend, the “Reservation Payment”). Diibs shall charge User for
          the Reservation Payment at the time of the Reservation. For each Reservation, Diibs shall
          pay Restaurant 92% of the Reservation Payment, after deducting an operations fee of $1.99.
          Restaurant acknowledges that initial payments may be delayed by 1-5 business days
          following the Reservation.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>4. Rescheduling Policy</h2>
        <p className='fs-4'>
          The Cancellation Policy for Users is available here
          <a href='/reservation-terms'> Reservation Terms</a>. Restaurants shall use commercially
          reasonable efforts to accommodate reasonable rescheduling requests by User. If a
          Restaurant grants a User’s rescheduling request, the Minimum Spend shall be applied as a
          credit for the User’s next Reservation at Restaurant.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>5. License to Trademarks</h2>
        <p className='fs-4'>
          Restaurant hereby grants to Diibs a nonexclusive, royalty-free, irrevocable, worldwide,
          perpetual, sublicensable, fully paid license, to use, copy, import, display, reproduce,
          perform, create derivative works, and distribute (i) any information, data, text,
          photographs, videos, audio clips, written posts, comments, software, scripts, or graphics
          provided by Restaurant to Diibs; (ii) Restaurant’s trademarks, trade names, business
          names, logos, descriptions, photographs or other identifiers; and (iii) any other content
          provided by Restaurant to Diibs.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>6. Limitation of Liability</h2>
        <p className='fs-4'>
          <strong>No Liability for User Actions:</strong> Diibs is merely a facilitator of the
          auction process and is not responsible for the behavior, actions, or obligations of Users.
          Diibs does not assume responsibility for any disputes between the Restaurant and User, and
          Restaurant agrees to indemnify and hold Diibs harmless from all claims, liabilities,
          damages, or losses arising out of or relating to the Restaurant’s use of the Services.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>7. End User Data</h2>
        <p className='fs-4'>
          Restaurant will comply with Diibs’s privacy policy and all applicable privacy laws and
          regulations, including those applying to personal information that Restaurant may have
          access to by virtue of the Services or that Diibs may supply to Restaurant.
        </p>

        <h2 className='fw-bold fs-2 mt-6'>8. Modification of Terms</h2>
        <p className='fs-4'>
          Diibs reserves the right to modify these Restaurant Terms at any time. Any changes will be
          communicated to users via email or through the Services, and continued use of the Services
          constitutes acceptance of the updated terms.
        </p>
      </div>
    </div>
  );
};

export default RestaurantTerms;
