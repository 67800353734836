import React, {useState} from 'react';
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core';
import {useNavigate, useParams} from 'react-router-dom';
import AuctionDetail from '../../../../Sections/common/AuctionDetail';
import SectionWrapper from '../../../SectionWrapper';
import BiddingDataTable from '../../Bidding';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../../../Helpers';
import {useAuth} from '../../../../../modules/auth';
import {toast} from 'react-toastify';
import {CustomModal} from '../../../../Modal';

function AuctionDetails() {
  const param = useParams();
  const navigate = useNavigate();
  const {auctionModule} = useAuth();
  const [modalView, setModalView] = useState(false);
  const path: any = localStorage.getItem('path');
  const [breadcrumbsTitle, setBreadcrumbsTitle] = useState('');
  const auctionDetailsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Bidding',
      path: path || '/auction',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  const deleteTAuction = async (auctionIds) => {
    const result = await auctionModule?.deleteAuctions(auctionIds);
    if (result?.success) {
      toast.success(`Auction Deleted succesfully`, {
        toastId: TOAST_GENERAL,
      });
      navigate(path);
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    setModalView(false);
  };

  const addTableBtn = (
    <div className='card-title m-0'>
      <button
        className='btn btn-primary'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setModalView(true);
          return false;
        }}
      >
        Delete Auction
      </button>
    </div>
  );

  return (
    <>
      <PageTitle breadcrumbs={auctionDetailsBreadcrumbs}>
        {breadcrumbsTitle ? breadcrumbsTitle : 'Details'}
      </PageTitle>
      <SectionWrapper id='auction_details' title='Auction Details' subTitle={addTableBtn}>
        <AuctionDetail auction_id={param.auctionId} setBreadcrumbsTitle={setBreadcrumbsTitle} />
      </SectionWrapper>
      <SectionWrapper id='bidding_details' title='All Biddings'>
        <BiddingDataTable />
      </SectionWrapper>

      {modalView && (
        <CustomModal
          title=''
          data=''
          handleCancelCallback={() => setModalView(false)}
          handleSaveCallback={() => {
            deleteTAuction({ids: [param.auctionId]});
            setModalView(false);
          }}
          type={'DELETE'}
        />
      )}
    </>
  );
}

export default AuctionDetails;
