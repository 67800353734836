import {useLocation} from 'react-router-dom';

interface InactiveProps {
  handleCancelCallback: () => void;
  handleSaveCallback: (value: any) => void;
  inActiveTitle?: string;
}

export default function Inactive({
  handleCancelCallback,
  handleSaveCallback,
  inActiveTitle,
}: InactiveProps) {
  const path = useLocation();

  return (
    <div className='text-center'>
      <div className='card-body'>
        <h4 className='card-title fw-bold text-black fs-4'>
          {inActiveTitle
            ? inActiveTitle
            : 'Your account is currently inactive, Please contact support for assistance.'}
        </h4>
        <div className='d-flex my-4 flex-center'>
          <button className='btn btn-sm btn-danger me-3' onClick={handleSaveCallback}>
            Contact
          </button>
          {path.pathname !== '/auth' && (
            <button className='btn btn-sm btn-primary me-3' onClick={handleCancelCallback}>
              Signout
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
