/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl';
import {AsideMenuItem} from './AsideMenuItem';
import {useAuth} from '../../../../app/modules/auth';
import {
  ADMIN_ROUTE,
  AUCTION_ROUTE,
  BIDDING_ROUTE,
  BOOKING_ROUTE,
  DASHBOARD_ROUTE,
  PROFILE_ROUTE,
  RESTAURANTS_ROUTE,
  TABLES_ROUTE,
  USERS_ROUTE,
  CONTACT_ROUTE,
  REVIEWS_ROUTE,
  REPORTS_ROUTE,
  COUPON_LIST,
  AUCTION_TYPE_ROUTE,
  STRIPE_CONNECT,
  MANAGE_HOST,
} from '../../../../app/Helpers';
import {useRef} from 'react';

const adminRoutes = [
  {
    label: 'Dashboard',
    link: DASHBOARD_ROUTE,
    icon: 'element-11',
  },
  {
    label: 'Users',
    link: USERS_ROUTE,
    icon: 'user',
  },
  {
    label: 'Restaurant',
    link: RESTAURANTS_ROUTE,
    icon: 'home-2',
  },
  {
    label: 'Coupons',
    link: COUPON_LIST,
    icon: 'discount',
  },
  {
    label: 'Auctions',
    link: AUCTION_ROUTE,
    icon: 'abstract-37',
  },
  {
    label: 'Auction Type',
    link: AUCTION_TYPE_ROUTE,
    icon: 'abstract-31',
  },
  {
    label: 'Booking',
    link: '/bookings',
    icon: 'subtitle',
  },
  {
    label: 'Bidding',
    link: BIDDING_ROUTE,
    icon: 'notepad-bookmark',
  },
  {
    label: 'Ratings & Reviews',
    link: REVIEWS_ROUTE,
    icon: 'star',
  },
  {
    label: 'Restaurant Reports',
    link: REPORTS_ROUTE,
    icon: 'tablet-delete',
  },
  {
    label: 'Transaction',
    link: '/transactions',
    icon: 'cheque',
  },
  {
    label: 'Contacts',
    link: CONTACT_ROUTE,
    icon: 'notepad-bookmark',
  },
];

const superAdminRoutes = [
  {
    label: 'Admins',
    link: ADMIN_ROUTE,
    icon: 'user',
  },
  {
    label: 'Contacts',
    link: CONTACT_ROUTE,
    icon: 'notepad-bookmark',
  },
];

const restaurantRoutes = [
  {
    label: 'Dashboard',
    link: DASHBOARD_ROUTE,
    icon: 'element-11',
  },
  {
    label: 'Auction',
    link: AUCTION_ROUTE,
    icon: 'abstract-37',
  },
  {
    label: 'Auction Type',
    link: AUCTION_TYPE_ROUTE,
    icon: 'abstract-31',
  },
  {
    label: 'Manage Host',
    link: MANAGE_HOST,
    icon: 'profile-user',
  },
  {
    label: 'Tables',
    link: TABLES_ROUTE,
    icon: 'calendar',
  },
  {
    label: 'Bookings',
    link: BOOKING_ROUTE,
    icon: 'notepad-bookmark',
  },
  {
    label: 'Bidding',
    link: BIDDING_ROUTE,
    icon: 'notepad-bookmark',
  },
  {
    label: 'Ratings & Reviews',
    link: REVIEWS_ROUTE,
    icon: 'star',
  },
  {
    label: 'Contact',
    link: CONTACT_ROUTE,
    icon: 'notepad-bookmark',
  },
];

export function AsideMenuMain() {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const gotActiveMenu = useRef(false);
  const isDisabled = currentUser?.isStripeConnect === 0;
  const renderAdminMenu = () => {
    return adminRoutes.map((menu, i) => (
      <AsideMenuItem
        to={menu.link}
        icon={menu?.icon}
        title={menu.label}
        key={i}
        gotActiveMenu={gotActiveMenu}
      />
    ));
  };

  const renderSuperAdminMenu = () => {
    return superAdminRoutes.map((menu, i) => (
      <AsideMenuItem
        to={menu.link}
        icon={menu?.icon}
        title={menu.label}
        key={i}
        gotActiveMenu={gotActiveMenu}
      />
    ));
  };

  const renderRestaurantAdminMenu = () => {
    const routes = [...restaurantRoutes];
    if (isDisabled) {
      const dashboardIndex = routes.findIndex((route) => route.label === 'Dashboard');
      if (dashboardIndex !== -1) {
        routes.splice(dashboardIndex + 1, 0, {
          label: 'Stripe Connect',
          link: STRIPE_CONNECT,
          icon: 'external-drive',
        });
      }
    }

    return routes.map((menu, i) => (
      <AsideMenuItem
        to={menu.link}
        icon={menu?.icon}
        title={menu.label}
        key={i}
        gotActiveMenu={gotActiveMenu}
        disabled={isDisabled && menu.label !== 'Dashboard' && menu.label !== 'Stripe Connect'}
      />
    ));
  };

  return (
    <>
      {currentUser?.role === 'ADMIN' && renderAdminMenu()}
      {currentUser?.role === 'SUPER_ADMIN' && renderSuperAdminMenu()}
      {currentUser?.role === 'RESTAURANT' && renderRestaurantAdminMenu()}

      {/* <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      /> */}
      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub to='/crafted/pages' title='Pages' icon='gift'>
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle'>
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-plus'>
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem to='' icon='shield-tick' title='Restaurants Management' /> */}

      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='document' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  );
}
