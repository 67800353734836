import React, {useState} from 'react';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';
import BiddingDataTable from '../../../../components/common/DataTableViews/Bidding';
import {useLocation, useParams} from 'react-router-dom';

function RestaurantBiddings() {
  const restaurantId = useParams().restaurantId;
  const location = useLocation().pathname.split('/')[1];
  const [userName, setUserName] = useState('');
  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Bidding',
      path: `${location}/${restaurantId}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: userName,
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{userName}</PageTitle>
      <BiddingDataTable
        restautantId={restaurantId}
        showRestaurantFilter={false}
        setUserName={setUserName}
      />
    </>
  );
}

export default RestaurantBiddings;
