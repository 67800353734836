import {useState, useEffect, useRef} from 'react';
import {KTIcon} from '../../../../_metronic/helpers';
import '../../../DataTable/dataTable.css';
import {StepperComponent} from '../../../../_metronic/assets/ts/components';
import UserRegistration from './steps/UserRegistration';
import RestaurantRegistration from './steps/RestaurantRegistration';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';

const personalDetailsValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  description: '',
  location: '',
  city: '',
  restaurantName: '',
  preferences: [],
};

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(5, 'Email should be of minimum 5 chars long')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
    // .max(40, 'Email should be of maximum 40 chars long')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  restaurantName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Restaurant Name is required'),

  description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Description is required'),
  location: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Location is required'),
  city: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('City is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export function Registration() {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [stepNo, setStepNo] = useState<number | undefined>(undefined);

  const formik = useFormik({
    initialValues: personalDetailsValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {},
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    setStepNo(stepper.current?.currentStepIndex);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current.goPrev();
    setStepNo(stepper.current?.currentStepIndex);
  };

  const nextStep = (e) => {
    e.preventDefault();
    if (!stepper.current) {
      return;
    }
    stepper.current.goNext();
    setStepNo(stepper.current?.currentStepIndex);
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  return (
    <div className='card'>
      <div className='card-body'>
        <h1 className='text-center stepper-title'>Sign Up</h1>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Personal Details</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Restaurant Details</h3>
            </div>
          </div>

          <form method='POST' onSubmit={stepNo && stepNo === 2 ? handleSubmit : nextStep}>
            <div className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <UserRegistration formik={formik} />
              </div>

              <div data-kt-stepper-element='content'>
                <RestaurantRegistration formik={formik} />
              </div>

              <div className='d-flex flex-stack pt-15'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepNo && stepNo === 2 ? 'Submit' : 'Continue'}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Already Sign up?{' '}
            <Link to='/auth' className='link-primary'>
              Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
