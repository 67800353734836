/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {KTIcon, toAbsoluteUrl} from '../../../helpers';
import {useLayout} from '../../core';
import {HeaderToolbar} from './HeaderToolbar';

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout();
  const {aside} = config;

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand'>
        {/* begin::Logo */}
        <Link to='/' className='text-light fs-2 fw-bolder'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/DIIBS_logo_white.png')}
            className='h-40px h-lg-40px'
          />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTIcon iconName='exit-left' className='fs-1 me-n1 minimize-default' />
            <KTIcon iconName='entrance-left' className='fs-1 minimize-active' />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      <HeaderToolbar />
    </div>
  );
}
