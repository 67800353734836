import React, {useState} from 'react';
import {KTIconForm} from '../../../_metronic/helpers/components/KTIconForm';
import {TModalProps} from './index.d';
import {UserBody} from './Body/UserBody';
import {TableBody} from './Body/TableBody';
import DeleteBody from './Body/DeleteBody';
import AuctionDetailsBody from './Body/AuctionDetailsBody';
import TransactionDetailsBody from './Body/TransactionDetailsBody';
import ContactBody from './Body/ContactBody';
import ReviewBody from './Body/ReviewBody';
import ReportBody from './Body/ReportBody';
import Inactive from './Body/UserInactiveBody';
import ContactDetalsBody from './Body/ContactDetailsBody';
import RecurringAuctionBody from './Body/RecurringAuctionBody';
import CouponBody from './Body/CouponBody';
import StripeOnBoardBody from './Body/StripeOnBoardBody';
import AuctionBody from './Body/AuctionBody';
import RestaurantBody from './Body/RestaurantBody';
import AuctionTypeBody from './Body/AuctionTypeBody';
import RestaurantMobileVerificationBody from './Body/RestaurantMobileVerificationBody';
import HostBody from './Body/HostBody';
import TermsAndAgreementBody from './Body/TermsAndAgreementBody';

export const CustomModal = ({
  title,
  handleCancelCallback,
  handleSaveCallback,
  data,
  type,
  attendanceType,
  inActiveTitle,
  optionalText,
  isView,
  stripeTitle,
  isEditMode,
}: TModalProps) => {
  const [hideCloseModalBtn, setHideCloseModalBtn] = useState(data === 600);
  const showModalBody = () => {
    if (type === 'RESTAURANT') {
      return (
        <RestaurantBody
          hideCloseModalBtn={hideCloseModalBtn}
          setHideCloseModalBtn={setHideCloseModalBtn}
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          restaurantDetails={data}
        />
      );
    }
    if (type === 'CONTACT') {
      return (
        <ContactBody
          isInActive={data === 600}
          setHideCloseModalBtn={setHideCloseModalBtn}
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
        />
      );
    }
    if (type === 'CONTACTDETAILS') {
      return <ContactDetalsBody contactDetails={data} />;
    }
    if (type === 'USER') {
      return (
        <UserBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          userDetails={data}
        />
      );
    }
    if (type === 'COUPON') {
      return (
        <CouponBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          userDetails={data}
          isView={isView}
        />
      );
    }
    if (type === 'AUCTION_TYPE') {
      return (
        <AuctionTypeBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          auctionData={data}
        />
      );
    }
    if (type === 'TABLE') {
      return (
        <TableBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          tableDetails={data}
        />
      );
    }
    if (type === 'AUCTION') {
      return (
        <AuctionBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          auctionData={data}
        />
      );
    }
    if (type === 'RECURRING_AUCTION') {
      return (
        <RecurringAuctionBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          auctionData={data}
        />
      );
    }
    if (type === 'DELETE' || type === 'ATTENDANCE') {
      return (
        <DeleteBody
          type={type}
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          optionalText={optionalText}
        />
      );
    }
    if (type === 'REVIEW') {
      return (
        <ReviewBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          reviewDetails={data}
        />
      );
    }
    if (type === 'REPORT') {
      return (
        <ReportBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          reportDetails={data}
        />
      );
    }
    if (type === 'AUCTION_DETAILS') {
      return (
        <AuctionDetailsBody handleCancelCallback={handleCancelCallback} auctionDetails={data} />
      );
    }
    if (type === 'TRANSACTION_DETAILS') {
      return (
        <TransactionDetailsBody
          handleCancelCallback={handleCancelCallback}
          transactionDetails={data}
        />
      );
    }

    if (type === 'INACTIVE_MESSAGE') {
      return (
        <Inactive
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          inActiveTitle={inActiveTitle}
        />
      );
    }

    if (type === 'STRIPE_ONBOARD') {
      return (
        <StripeOnBoardBody handleCancelCallback={handleCancelCallback} stripeTitle={stripeTitle} />
      );
    }

    if (type === 'RESTAURANT_MOBILE_VERIFICATION') {
      return <RestaurantMobileVerificationBody handleCancelCallback={handleCancelCallback} />;
    }

    if (type === 'RESTAURANT_MULTIPLE_MOB_NO') {
      return (
        <HostBody
          handleCancelCallback={handleCancelCallback}
          handleSaveCallback={handleSaveCallback}
          data={data}
          isEditMode={isEditMode}
        />
      );
    }

    if (type === 'TERMS') {
      return <TermsAndAgreementBody handleCancelCallback={handleCancelCallback} />;
    }
  };

  return (
    <>
      <div
        className='modal fade show d-block'
        id={`${title}`}
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div
          className={`modal-dialog modal-dialog-centered ${
            type === 'TERMS' ? 'modal-custom' : 'mw-650px'
          }`}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              {/* <h2 className='fw-bolder'>{title}</h2> */}
              {!hideCloseModalBtn && (
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={handleCancelCallback}
                  style={{cursor: 'pointer'}}
                >
                  <KTIconForm iconName='cross' className='fs-1' />
                </div>
              )}
            </div>
            <div className='modal-body mx-5 mx-xl-15 my-7 '>{showModalBody()}</div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};
