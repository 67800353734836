import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { useThemeMode } from '../../_metronic/partials';
import {
  ADMIN_DETAILS_ROUTE,
  ADMIN_ROUTE,
  CONTACT_ROUTE,
  PROFILE_ROUTE
} from '../Helpers';
import Profile from '../components/Sections/common/AdminProfile';
import Contact from '../pages/Admin/Contact';
import UserDetails from '../pages/Admin/User/UserDetails';
import { AdminForSuperAdmin } from '../pages/SuperAdmin';

const SuperAdminRoutes = () => {
  const {mode} = useThemeMode();
  return (
    <div className={`${mode === 'dark' ? 'dark-mode-theme' : 'light-mode-theme'}`}>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to={ADMIN_ROUTE} />} />
          <Route
            path={ADMIN_ROUTE}
            element={
              <SuspendedView>
                <AdminForSuperAdmin />
              </SuspendedView>
            }
          />
          <Route
            path={PROFILE_ROUTE}
            element={
              <SuspendedView>
                <Profile />
              </SuspendedView>
            }
          />
          <Route
            path={ADMIN_DETAILS_ROUTE}
            element={
              <SuspendedView>
                <UserDetails />
              </SuspendedView>
            }
          />{' '}
          <Route
            path={CONTACT_ROUTE}
            element={
              <SuspendedView>
                <Contact />
              </SuspendedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </div>
  );
};

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { SuperAdminRoutes };
