
function Card({name, count, icon}) {
  return (
    <div className='card px-5 py-6'>
      <div className='d-flex flex-column'>
        <div className='fs-2tx fw-bolder'>{count}</div>
      </div>
      <div className='flex-nowrap border-0'>
        <div className='card-title m-0'>
          <span className='fs-4 fw-bold text-gray-600 m-0'>{name}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
