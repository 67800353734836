import {useState} from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core';
import {API_ERROR_MESSAGE, TOAST_GENERAL, TOAST_USER_DETAILS} from '../../../../Helpers';
import {CustomModal} from '../../../../components/Modal';
import CardDetails from '../../../../components/Sections/Admin/CardDetails';
import ProfileDetails from '../../../../components/Sections/Admin/ProfileDetails';
import BiddingDataTable from '../../../../components/common/DataTableViews/Bidding';
import {BookingDataTable} from '../../../../components/common/DataTableViews/Booking';
import SectionWrapper from '../../../../components/common/SectionWrapper';
import {useAuth} from '../../../../modules/auth';
import RestaurantTransactions from '../../Transactions';
import UserProfileDetails from '../../../../components/Sections/Admin/userProfileDetails';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const adminsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Admins',
    path: '/admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const UserDetails = () => {
  const location = useLocation();
  const param = useParams();
  const {currentUser, userModule} = useAuth();
  const [loadDelProfile, setLoadDelProfile] = useState({loading: true, isDeleted: false});
  const user = location.state;
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  let [searchParams, setSearchParams] = useSearchParams();
  let isEditMode = searchParams.get('edit');

  const [modalView, setModalView] = useState(false);

  const editUserBtn = loadDelProfile.isDeleted ? (
    <span className='badge capitalize fs-6 text-danger'>Account Deleted</span>
  ) : (
    <div className='card-title m-0'>
      <button
        className='btn btn-primary mx-3'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setModalView(true);
        }}
      >
        {currentUser?.role === 'SUPER_ADMIN' ? 'Delete Account' : 'Delete Account'}
      </button>
      <button
        className='btn btn-primary'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isEditMode ? setSearchParams({}) : setSearchParams({edit: 'true'});
          return false;
        }}
      >
        {isEditMode ? 'Cancel Edit' : 'Edit User'}
      </button>
    </div>
  );

  const deleteTUser = async (userIds) => {
    const result = await userModule?.deleteUsers(userIds);
    if (result?.success) {
      toast.success(`User Deleted succesfully`, {
        toastId: 'user deleted',
      });
      setModalView(false);

      if (currentUser?.role === 'SUPER_ADMIN') {
        navigate('/admin');
      } else {
        navigate('/users');
      }
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };
  return (
    <>
      <PageTitle breadcrumbs={currentUser?.role === 'ADMIN' ? usersBreadcrumbs : adminsBreadcrumbs}>
        {userName}
      </PageTitle>
      <SectionWrapper
        id='restaurant_profile_details'
        title='Profile Details'
        subTitle={!loadDelProfile.loading ? editUserBtn : ''}
      >
        {currentUser?.role === 'ADMIN' ? (
          <UserProfileDetails
            setLoadDelProfile={setLoadDelProfile}
            setUserName={setUserName}
            userId={param.userId}
            toastId={TOAST_USER_DETAILS}
            loadDelProfile={loadDelProfile}
          />
        ) : (
          <ProfileDetails
            setLoadDelProfile={setLoadDelProfile}
            setUserName={setUserName}
            userId={param.adminId}
            toastId={TOAST_USER_DETAILS}
            loadDelProfile={loadDelProfile}
          />
        )}
      </SectionWrapper>
      {currentUser?.role === 'ADMIN' && (
        <>
          {!loadDelProfile.loading && !loadDelProfile.isDeleted && (
            <SectionWrapper id='restaurant_Card_details' title='Card Details'>
              <CardDetails userId={param.userId} />
            </SectionWrapper>
          )}
          <SectionWrapper id='restaurant_booking_details' title='Bidding Details'>
            {/* <Bidding viewMoreLink={`/users/bidding/${param.userId}`} toastId={TOAST_USER_DETAILS} /> */}
            <BiddingDataTable
              userId={param.userId}
              showUserFilter={false}
              // setUserName={setUserName}
              filterType='bidding'
            />
          </SectionWrapper>
          <SectionWrapper id='restaurant_tables_details' title='Transactions Details'>
            {/* <TransactionDetails
              viewMoreLink={`/users/transactions/${param.userId}`}
              toastId={TOAST_USER_DETAILS}
            /> */}
            <RestaurantTransactions
              showUserFilter={false}
              userId={param.userId}
              // setUserName={setUserName}
              breadcrumb={[]}
              filterType='transactions'
            />
          </SectionWrapper>
          <SectionWrapper id='restaurant_booking_details' title='Booking Details'>
            {/* <Bookings
              viewMoreLink={`/users/bookings/${param.userId}`}
              toastId={TOAST_USER_DETAILS}
            /> */}
            <BookingDataTable
              userId={param.userId}
              showUserFilter={false}
              // setUserName={setUserName}
              showBookingCount={true}
              filterType='booking'
            />
          </SectionWrapper>
        </>
      )}
      {modalView && (
        <CustomModal
          data={''}
          handleCancelCallback={() => setModalView(false)}
          handleSaveCallback={async (value) => {
            if (param?.adminId) {
              deleteTUser({ids: [param?.adminId]});
            } else {
              deleteTUser({ids: [param?.userId]});
            }
            setModalView(false);
          }}
          title={''}
          type={'DELETE'}
        />
      )}
    </>
  );
};

export default UserDetails;
