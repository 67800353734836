import {useEffect, useReducer, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {CommonRowInTable, CommonStatusInTable, CommonViewInHeader} from '..';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import DataTableWrapper from '../../../../DataTable';
import {
  API_ERROR_MESSAGE,
  BOOKING_ATTENDANCE,
  TOAST_GENERAL,
  convertUtcToLocal,
} from '../../../../Helpers';
import {useAuth} from '../../../../modules/auth';
import {CustomModal} from '../../../Modal';
import reducerFunc from './action';
import {TInitialState} from './index.d';

const initialState: TInitialState = {
  loading: true,
  data: [],
  currentPage: 1,
  totalData: -1,
  pageSize: 10,
  totalPages: 1,
  error: null,
  search: '',
  changeAttendanceId: null,
  restaurantFilter: '',
  startDate: '',
  endDate: '',
  capacity: '',
  userFilter: '',
  minPrice: '',
  maxPrice: '',
  isPopupOpen: false,
  selectedRow: undefined,
  attendance: '',
  status: '',
  totalBooking: 0,
  todaysBooking: 0,
  totalBookingAmount: 0,
  todaysBookingAmount: 0,
};

interface IParams {
  userId?: string;
  restaurantId?: string;
  showUserFilter?: boolean;
  showRestaurantFilter?: boolean;
  setUserName?: any;
  showBookingCount?: boolean;
  filterType?: string;
}
const AdminBookingTable = ({
  userId = '',
  restaurantId = '',
  showUserFilter = true,
  showRestaurantFilter = true,
  setUserName,
  showBookingCount,
  filterType,
}: IParams) => {
  const {currentUser, restaurantModule} = useAuth();
  const {state: locationState}: {state: any} = useLocation();
  const restaurantUserLcoationFilter = useRef({
    user: userId,
    restautant: restaurantId,
  });
  const isAdmin = currentUser?.role === 'ADMIN';
  const [state, dispatch] = useReducer(reducerFunc, initialState);

  let columns = [
    {
      name: <CommonViewInHeader name='User Name' />,
      selector: (row, i) => row?.user?.firstName,
      sortable: true,
      // width: '14%',
      width: isAdmin ? '150px' : '170px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.user?.firstName + ' ' + row?.user?.lastName}
            classes={`${isAdmin ? 'text-theme-color' : 'text-dark'} text-capitalize`}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Mobile Number' />,
      selector: (row, i) => row?.user?.mobileNumber,
      minWidth: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
          text={row?.user?.mobileNumber !== null
            ? `${row?.user?.mobileCountryCode.startsWith('+') ? '' : '+'}${row?.user?.mobileCountryCode}${
                row?.user?.mobileNumber
              }`
            : '-'}            classes='text-theme-color text-dark'
          />
        );
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row?.auction?.dineDate,
      width: isAdmin ? '200px' : '220px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={convertUtcToLocal(row?.auction?.dineDate)}
            classes='text-theme-color text-uppercase'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Table' />,
      selector: (row, i) => row?.auction?.table?.capacity,
      width: isAdmin ? '120px' : '140px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row?.auction?.table?.capacity + ' Persons'}
            classes='text-theme-color'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Attendees' />,
      selector: (row, i) => row?.attendee,
      width: isAdmin ? '130px' : '140px',
      format: (row, i) => {
        return (
          <CommonRowInTable text={row?.attendee ? row?.attendee : '-'} classes='text-theme-color' />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Booking Price' />,
      selector: (row, i) => parseFloat(row.amount),
      width: isAdmin ? '180px' : '200px',
      format: (row, i) => {
        return <CommonRowInTable text={'$ ' + row.amount} classes='text-theme-color' />;
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: <CommonViewInHeader name='Status' />,
      selector: (row, i) => row.status,
      // width: '16%',
      width: '150px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.status}
            classes='text-theme-color px-4 py-2 badge-light text-capitalize'
          />
        );
      },
      sortable: true,
    },
  ];

  columns = isAdmin
    ? [
        {
          name: <CommonViewInHeader name='Restaurant' classes='ms-8' />,
          selector: (row, i) => row?.restaurant?.name,
          sortable: true,
          // width: '18%',
          width: '240px',
          format: (row, i) => {
            return (
              <CommonRowInTable
                text={row?.restaurant?.name}
                avatar={
                  row?.restaurant?.images
                    ? row?.restaurant?.images
                    : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
                }
                classes='text-dark'
              />
            );
          },
        },
        ...columns,
      ]
    : columns;

  columns = [
    ...columns,
    {
      name: <CommonViewInHeader name='Attendance' />,
      selector: (row, i) => row.attendance,
      // width: '15%',
      width: '150px',
      format: (row, i) => {
        return (
          <>
            {row.attendance ? (
              <CommonRowInTable
                text={row.attendance}
                classes='text-theme-color px-4 py-2 badge-light text-capitalize'
              />
            ) : (
              // <span className='text-capitalize'>{row.attendance}</span>
              <select
                className={`${
                  row.attendance === null || undefined
                    ? 'text-gray'
                    : row.attendance === 'present'
                    ? 'text-success'
                    : 'text-danger'
                } form-select form-select-solid form-select-sm fw-bold`}
                value={row.attendance}
                disabled={row.attendance === 'absent' || row.attendance === 'present'}
                onChange={(e) => {
                  dispatch({type: 'OPEN_POPUP', payload: true});
                  dispatch({
                    type: 'SELECT_ROW',
                    payload: {...row, selectedAttendance: e.target.value},
                  });
                  e.target.value = '';
                  // dispatch({type: 'SET_BOOKINGS_ATTENDENCE', payload: e.target.value});
                }}
              >
                {BOOKING_ATTENDANCE.map((ele, i) => {
                  return (
                    <option className='fs-4 text-gray-700' value={ele.value} key={i}>
                      {ele.label}
                    </option>
                  );
                })}
              </select>
            )}
          </>
        );
      },
      sortable: false,
    },
  ];

  const location: any = useLocation();
  const restaurantFilterValue = location?.state?.role === 'admin' && location?.state?.value;
  const userFilterValue = location?.state?.role === 'restaurant' && location?.state?.value;

  const getTRestaurantsBookings = async () => {
    dispatch({type: 'LOADING', payload: true});
    const result = await restaurantModule?.getRestaurantsBookings({
      pageNo: state.currentPage,
      pageSize: state.pageSize,
      searchKeyword: state.search,
      restaurantIdFilter:
        restaurantUserLcoationFilter.current.restautant ||
        state.restaurantFilter ||
        restaurantFilterValue,
      capacity: state.capacity,
      userIdFilter:
        restaurantUserLcoationFilter.current.user || state.userFilter || userFilterValue,
      startDate: state.startDate,
      endDate: state.endDate,
      minPrice: state.minPrice,
      maxPrice: state.maxPrice,
      attendance: state.attendance,
      statusFilter: state.status,
    });

    if (result?.success) {
      const user = showUserFilter
        ? result?.data?.rows[0]?.restaurant.name
        : result?.data?.rows[0]?.user.firstName;
      setUserName && setUserName(user);
      dispatch({type: 'GET_DATA', payload: result?.data?.rows});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
      dispatch({
        type: 'BOOKING_COUNT',
        payload: {
          totalBooking: result?.data?.totalBooking,
          totalBookingAmount: result?.data?.totalBooking,
          todaysBooking: result?.data?.totalBooking,
          todaysBookingAmount: result?.data?.totalBooking,
        },
      });
      dispatch({
        type: 'TOTAL_BOOKING_COUNT',
        payload: result?.data?.totalBooking,
      });
      dispatch({
        type: 'TOTAL_BOOKING_AMOUNT',
        payload: result?.data?.totalBookingAmount,
      });
      dispatch({
        type: 'TODAYS_BOOKING_COUNT',
        payload: result?.data?.todayTotalBooking,
      });
      dispatch({
        type: 'TODAYS_BOOKING_AMOUNT',
        payload: result?.data?.todayTotalBookingAmount,
      });
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  useEffect(() => {
    getTRestaurantsBookings();
  }, [
    state.search,
    state.currentPage,
    state.pageSize,
    state.restaurantFilter,
    state.userFilter,
    state.startDate,
    state.endDate,
    state.minPrice,
    state.maxPrice,
    state.capacity,
    state.attendance,
    state.status,
    location.state,
  ]);

  const markAttendance = async function () {
    const result = isAdmin
      ? await restaurantModule?.markBookingAttendance({
          bookingId: state.selectedRow.id,
          attendance: state.selectedRow.selectedAttendance,
          restaurantId: restaurantId,
        })
      : await restaurantModule?.markBookingAttendance({
          bookingId: state.selectedRow.id,
          attendance: state.selectedRow.selectedAttendance,
        });
    if (result?.success) {
      toast.success(result?.message || 'Attendance updated sucessfully.', {
        toastId: TOAST_GENERAL,
      });
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    getTRestaurantsBookings();
    dispatch({type: 'SELECT_ROW', payload: {}});
  };

  return (
    <>
      <DataTableWrapper
        totalData={state.totalData}
        loading={state.loading}
        columns={columns}
        data={state.data}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        totalPages={state.totalPages}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [
            {
              label: 'Attendance',
              options: ['pending', 'present', 'absent'].map((t) => ({
                value: t,
                label: t.toUpperCase(),
              })),
            },
            {
              label: 'Status',
              options: ['confirmed', 'completed'].map((t) => ({
                value: t,
                label: t.toUpperCase(),
              })),
            },
          ],
          date: true,
          price: true,
          userFilter: showUserFilter,
          restaurantFilter: isAdmin && showRestaurantFilter,
          capacity: true,
        }}
        filtersCallback={(value) => {
          dispatch({type: 'SET_RESTAURANT', payload: value.restaurantFilter?.value});
          dispatch({type: 'SET_USER', payload: value.userFilter?.value});
          dispatch({type: 'SET_CAPACITY', payload: value?.capacity});
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: value?.startDate, endDate: value?.endDate},
          });
          dispatch({
            type: 'PRICE_FILTER',
            payload: {minPrice: value?.minPrice, maxPrice: value?.maxPrice},
          });
          dispatch({
            type: 'SET_ATTENDANCE',
            payload: value?.Attendance?.value,
          });
          dispatch({
            type: 'SET_STATUS',
            payload: value?.Status?.value,
          });
        }}
        isFilterApplied={Boolean(
          state.restaurantFilter ||
            state.userFilter ||
            state.capacity ||
            (state.maxPrice && state.minPrice) ||
            (state.startDate && state.endDate) ||
            state.search ||
            state.attendance ||
            state.status
        )}
        totalBooking={state.totalBooking || 0}
        todaysBooking={state.todaysBooking || 0}
        totalBookingAmount={state.totalBookingAmount || 0}
        todaysBookingAmount={state.todaysBookingAmount || 0}
        showBookingCount={showBookingCount}
        filterType={filterType}
      />
      {state.isPopupOpen && (
        <CustomModal
          title={'Attendance'}
          attendanceType={''}
          data={state.data}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL', payload: false})}
          handleSaveCallback={(value) => {
            markAttendance();
            dispatch({type: 'CLOSE_MODAL', payload: false});
          }}
          type={'ATTENDANCE'}
        />
      )}
    </>
  );
};

export {AdminBookingTable};
