import React from 'react';
import clsx from 'clsx';
import '../../../../DataTable/dataTable.css';
import {SelectPreferences} from '../../../../components/common/SelectPreferences';

const RestaurantRegistration = ({formik}) => {
  return (
    <div
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      id='kt_login_signup_form'
    >
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Restaurant Name</label>
        <input
          placeholder='Restaurant Name'
          type='text'
          maxLength={50}
          autoComplete='off'
          {...formik.getFieldProps('restaurantName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.restaurantName && formik.errors.restaurantName,
            },
            {
              'is-valid': formik.touched.restaurantName && !formik.errors.restaurantName,
            }
          )}
        />
        {formik.touched.restaurantName && formik.errors.restaurantName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.restaurantName}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-7 filters-register-select'>
        <label className='required fw-bold fs-6 mb-2'>Preferences</label>
        <SelectPreferences
          onChange={(values) => {
            formik.setFieldValue(
              'preferences',
              values?.map(({label}) => label)
            );
          }}
          value={formik.getFieldProps('preferences').value}
        />
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Description</label>
        <input
          placeholder='Description'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('description')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.description && formik.errors.description,
            },
            {
              'is-valid': formik.touched.description && !formik.errors.description,
            }
          )}
        />
        {formik.touched.description && formik.errors.description && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.description}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Location</label>
        <input
          placeholder='Location'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('location')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.location && formik.errors.location,
            },
            {
              'is-valid': formik.touched.location && !formik.errors.location,
            }
          )}
        />
        {formik.touched.location && formik.errors.location && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.location}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>City</label>
        <input
          placeholder='City'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('city')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.city && formik.errors.city,
            },
            {
              'is-valid': formik.touched.city && !formik.errors.city,
            }
          )}
        />
        {formik.touched.city && formik.errors.city && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.city}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantRegistration;
