import ContentLoader from 'react-content-loader';

const ReservationLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={600}
    viewBox='0 0 400 600'
    backgroundColor='#c4c4c4'
    foregroundColor='#e3e3e3'
    {...props}
  >
    <rect x='7' y='37' rx='22' ry='22' width='331' height='36' />
    <rect x='11' y='119' rx='22' ry='22' width='326' height='36' />
    <rect x='8' y='202' rx='22' ry='22' width='328' height='36' />
    <rect x='10' y='287' rx='22' ry='22' width='325' height='36' />
    <rect x='300' y='99' rx='0' ry='0' width='2' height='2' />
    <rect x='232' y='282' rx='0' ry='0' width='1' height='1' />
    <rect x='198' y='200' rx='0' ry='0' width='0' height='3' />
    <rect x='24' y='176' rx='0' ry='0' width='159' height='13' />
    <rect x='18' y='90' rx='0' ry='0' width='159' height='13' />
    <rect x='27' y='258' rx='0' ry='0' width='159' height='13' />
    <rect x='12' y='373' rx='22' ry='22' width='328' height='36' />
    <rect x='26' y='346' rx='0' ry='0' width='159' height='13' />
    <rect x='26' y='429' rx='0' ry='0' width='159' height='13' />
    <rect x='12' y='458' rx='22' ry='22' width='325' height='36' />
    <rect x='18' y='4' rx='0' ry='0' width='159' height='13' />
  </ContentLoader>
);

export default ReservationLoader;
