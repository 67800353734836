import {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import {toast} from 'react-toastify';
import {TOAST_GENERAL} from '../../../Helpers';

const verifySchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(5, 'Email should be of minimum 5 chars long')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Wrong email format')
    // .max(40, 'Email should be of maximum 40 chars long')
    .required('Email is required'),
  otp: Yup.string().required('OTP is required'),
});

const initialValues = {
  email: '',
  otp: '',
};

const VerifyOtp = () => {
  const [loadError, setLoadError] = useState({
    loading: true,
    error: false,
    msg: '',
  });
  const navigate = useNavigate();
  const {authModule} = useAuth();
  const allowWithoutParams = useRef(0);
  const formik = useFormik({
    initialValues,
    validationSchema: verifySchema,
    onSubmit: async (values, {setSubmitting}) => {
      // setLoading(true);
      // try {
      //   const result = await authModule?.verifyOtp(values.email, values.otp);
      //   if (result?.success) {
      //     toast.success(`Email Verification Succeded, Please login`, {
      //       toastId: TOAST_GENERAL,
      //     });
      //     navigate('/auth/login');
      //   } else {
      //     toast(result?.message);
      //   }
      // } catch (error: any) {
      //   console.log(error.response);
      // }
      // setLoading(false);
    },
  });
  const [searchParams, setSearchParams]: any = useSearchParams();

  const verifyUser = async (email, otp) => {
    try {
      const result = await authModule?.verifyOtp(email, otp);
      if (result?.success) {
        // toast.success(`Email Verification Succeded, Please login`, {
        //   toastId: TOAST_GENERAL,
        // });
        // navigate('/auth/login');
        setLoadError({loading: false, error: false, msg: result?.message});
      } else {
        setLoadError({loading: false, error: true, msg: result?.message});
      }
    } catch (error: any) {
      setLoadError({loading: false, error: true, msg: 'Some problem occured, Please try again'});
    }
  };

  useEffect(() => {
    const email = searchParams.get('email');
    const otp = searchParams.get('otp');
    if (!searchParams.get('email') || !searchParams.get('otp')) {
      navigate('/auth');
      // toast.error("")
    } else {
      setSearchParams({});
      verifyUser(email, otp);
    }
  }, []);
  return (
    <div className='text-center mb-11'>
      <h1 className='text-dark fw-bolder mb-3 fs-2x'>Account Verification Successful!</h1>
      <h2>
        {
          loadError.loading && (
            <>
              Verifying your account,
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </>
          )
          // : (
          //   loadError.msg
          // )
        }
      </h2>
      {!loadError.loading && !loadError.error && (
        <Link className='btn btn-primary' to='/auth'>
          Sign In
        </Link>
      )}
    </div>
  );
};

export default VerifyOtp;
